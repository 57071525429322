import React from "react";
import { styled } from '@mui/material/styles';
import { FiSearch } from "react-icons/fi";
import { InputBase } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '100px',
    border: `1px solid ${cssProperties?.bordercolor?.secondary3}`,
    backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
    // '&:hover': {
    //   backgroundColor: alpha(theme.palette.common.white, 0.25),
    // },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


export default function Searchbar({handleSearch,value,height}) {

    return (
        <Search style={{ width: '100%' }}>
            <SearchIconWrapper>
                <FiSearch size={24} style={{ color: `${cssProperties?.bordercolor?.primary}` }} />
            </SearchIconWrapper>
            <StyledInputBase
                sx={{height: height ? height :'40px', width: '100%', 
                    '& .MuiInputBase-input': {
                        width: '100%'
                    }
                }}
                // sx={{height: height ? height :'40px', width: '323px'}}
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleSearch}
                value={value}
            />
        </Search>
    )
}