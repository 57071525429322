import React, { useEffect, useState } from "react";
import DataTable from "../../common/dataTable";
import { Grid, Radio } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import dayjs from "dayjs";
import { itemsPerPage } from "../../utils/constants";
import TablePagination from "../../common/tablePagination";

export default function CourseTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { backofficeTokenDetails } = useSelector(state => state.authReducer);

  const { courseList } = useSelector(state => state.masterReducer)
  const [tableData, setTableData] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    createTabledata()
  }, [courseList])
  const handleActive = (active, type, courseid) => {
    if (type !== active) {
      let data = {
        userid: backofficeTokenDetails?.id,
        courseid: courseid,
        isactive: type
      }
      dispatch({
        type: masterActions.SET_ACTIVE_COURSE,
        payload: { data: data },
      });
    }
  }
  const createTabledata = () => {
    let data = courseList.all.map((obj) => {
      return { ...obj, coursecategory: obj.coursecategory, coursename: obj.coursename, regstartdate: dayjs(obj.regstartdate).format("DD-MM-YYYY"), regenddate: dayjs(obj.regenddate).format("DD-MM-YYYY"), isActive: obj.isActive, courseid: obj.courseid, enable: <Radio checked={obj.isactive === '1'} onClick={() => handleActive(obj.isactive, '1', obj.courseid)} />, disable: <Radio checked={obj.isactive === '0'} onClick={() => handleActive(obj.isactive, '0', obj.courseid)} /> }
    })
    setTableData(data)
  }



  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST })
  }, [])
  const tableKeys = [
    {
      name: "S.No",
      accessor: "id",
      sortOption: false,
    },
    {
      name: "Course Category",
      accessor: "coursecategory",
      sortOption: false,
    },
    {
      name: "Course Name",
      accessor: "coursename",
      sortOption: false,
    },
    {
      name: "Registration Start Date",
      accessor: "regstartdate",
      sortOption: false,
    },
    {
      name: "Registration End Date",
      accessor: "regenddate",
      sortOption: false,
    },

    {
      name: "Enable",
      accessor: "enable",
      sortOption: false,

    },
    {
      name: "Disable",
      accessor: "disable",
      sortOption: false,
    },
  ];

  const defaultValues = [
    {
      coursecategory: "Science",
      coursename: "Physics 101",
      startdate: "2023-01-01",
      enddate: "2023-05-01",
      manual: "Manual A",
      enable: false,
      disable: false,
    },
    {
      coursecategory: "Arts",
      coursename: "History 101",
      startdate: "2023-02-01",
      enddate: "2023-06-01",
      manual: "Manual B",
      enable: false,
      disable: false,
    },
  ];

  const handleCourseUpdate = (value) => {
    navigate(`/${Cookies.get("mhet_mst_project")}/course-management?page=updatecourse`, { state: { id: value.courseid } });
  }

  const handlCourseCreate = (value) => {
    navigate(`/${Cookies.get("mhet_mst_project")}/course-management?page=createcourse`, { state: { id: value.courseid, coursename: value?.coursename } });
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = tableData?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(tableData?.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

  return (
    <Grid>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={"courseList"}
        handleCourseUpdate={handleCourseUpdate}
        handlCourseCreate={handlCourseCreate}
      />
      <TablePagination
        pageCount={pageCount}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
    </Grid>
  );
}
