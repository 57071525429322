import React, { useEffect } from "react";
// import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import Layout from "../../layouts/layout";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import PageHeading from "../../layouts/pageHeading";

export default function RoleSelection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (
      backofficeTokenDetails &&
      backofficeTokenDetails?.role?.role?.length === 1
    ) {
      dispatch({
        type: authActions.ROLE_TOKEN_GENERATION,
        payload: {
          role: backofficeTokenDetails?.role?.role[0],
          navigate: navigate,
        },
      });
    }
    //eslint-disable-next-line
  }, [backofficeTokenDetails]);

  const handleRoleSelection = (role) => {
    dispatch({
      type: authActions.ROLE_TOKEN_GENERATION,
      payload: { role: role, navigate: navigate },
    });
  };
  console.log("backofficeTokenDetails?.name", backofficeTokenDetails?.name)

  return (
    <>
      <Sidebar />
      <Header />
      <Layout>
      <PageHeading title={`Welcome ${backofficeTokenDetails?.name}`} />
        {backofficeTokenDetails?.role?.role?.length > 1 && (
          <Grid
            container
            spacing={3}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3rem",
            }}
          >
            {backofficeTokenDetails?.role?.role?.map((val, i) => (
              <Grid
                item
                sx={{ textAlign: "center", cursor: "pointer" }}
                key={i}
                xs={12}
                md={7}
                onClick={() => handleRoleSelection(val)}
              >
                <Box
                  sx={{
                    backgroundColor: cssProperties?.backgroundcolor?.secondary,
                    padding: "16px 24px",
                    borderRadius: "8px",
                    fontWeight: 500,
                    lineHeight: "15.4px",
                    textTransform: "uppercase",
                  }}
                >
                  {val}
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Layout>
    </>
  );
}
