import authActions from "./actions";

const initialState = {
    backofficeTokenDetails: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActions.SET_BACKOFFICE_TOKEN_DETAILS:
            return {
                ...state,
                backofficeTokenDetails: action.payload
            }
        default:
            return state;
    }
};

export default authReducer;

