import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function GreenButton({ title, type, handleSubmit,icon }) {

    return (
        <Button
            type={type}
            disableElevation
            variant="contained"
            sx={{
                borderRadius: '40px',
                padding: '0px 24px',
                height: '40px',
                backgroundColor: `green`,
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '15.4px',
                letterSpacing: '0.32px',
                boxShadow: 'none',
                border: '1px solid transparent',
                
            }}
            onClick={handleSubmit}
            startIcon={icon}
        >
            {title}
        </Button>
    )
}