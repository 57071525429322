import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
// import CreateModal from "./modals/createModal";
import { useForm } from "react-hook-form";
import commonActions from "../../redux/common/actions";
import { Box } from "@mui/material";
import Searchbar from "../../common/searchbar";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import DataTable from "../../common/dataTable";
import TablePagination from "../../common/tablePagination";
import { itemsPerPage } from "../../utils/constants";
import CreateModal from "./modals/editModal";
import EditModal from "./modals/editModal";
import DeleteModal from "./modals/deleteModal";
import { useNavigate } from "react-router-dom";
// import EditModal from "./modals/editModal";
import Cookies from "js-cookie";

const tableKeys = [
  {
    name: "SI.NO",
    accessor: "id",
    sortOption: false,
  },
  {
    name: "Drive Name",
    accessor: "drivename",
    sortOption: false,
  },
  {
    name: "Course Name",
    accessor: "coursename",
    sortOption: false,
  },
  {
    name: "Exam Date",
    accessor: "examdate",
    sortOption: false,
  },
  {
    name: "Batch",
    accessor: "batchname",
    sortOption: false,
  },
  // {
  //     name: "Date",
  //     accessor: 'date',
  //     sortOption: false
  // },
  // {
  //     name: "Batch",
  //     accessor: 'batch',
  //     sortOption: false
  // },
  // {
  //     name: "Time",
  //     accessor: 'time',
  //     sortOption: false
  // },
];

// const projectList = [
//   {
//     id: "01",
//     drivename: "test drive name 01",
//     coursename: "test course 01",
//     examdate: "test date 01",
//     batch: "test batch 01",
//   },
//   {
//     id: "02",
//     drivename: "test drive name 02",
//     coursename: "test course 02",
//     examdate: "test date 02",
//     batch: "test batch 02",
//   },
//   {
//     id: "03",
//     drivename: "test drive name 03",
//     coursename: "test course 03",
//     examdate: "test date 03",
//     batch: "test batch 03",
//   },
//   {
//     id: "04",
//     drivename: "test drive name 04",
//     coursename: "test course 04",
//     examdate: "test date 04",
//     batch: "test batch 04",
//   },
//   {
//     id: "05",
//     drivename: "test drive name 05",
//     coursename: "test course 05",
//     examdate: "test date 05",
//     batch: "test batch 05",
//   },
//   {
//     id: "06",
//     drivename: "test drive name 06",
//     coursename: "test course 06",
//     examdate: "test date 06",
//     batch: "test batch 06",
//   },
//   {
//     id: "07",
//     drivename: "test drive name 07",
//     coursename: "test course 07",
//     examdate: "test date 07",
//     batch: "test batch 07",
//   },
//   {
//     id: "08",
//     drivename: "test drive name 08",
//     coursename: "test course 08",
//     examdate: "test date 08",
//     batch: "test batch 08",
//   },
//   {
//     id: "09",
//     drivename: "test drive name 09",
//     coursename: "test course 09",
//     examdate: "test date 09",
//     batch: "test batch 09",
//   },
// ];

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { projectList } = useSelector(state => state.masterReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);
  const { batchList } = useSelector((state) => state.masterReducer);
  const { driveList } = useSelector((state) => state.masterFormsReducer);

  const { courseListByDrive } = useSelector(
    (state) => state.courseExamAndBatchReducer
  );

  const { examdateByCourse } = useSelector(
    (state) => state.forcePushAllocationReducer
  );

  console.log(batchList, "btl");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
  } = useForm();

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch({ type: masterActions.GET_ALL_BATCH });
  }, []);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = batchList?.all?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(batchList?.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

  // const handleCreateProject = (data) => {
  //     data['userid'] = backofficeTokenDetails?.id;
  //     dispatch({ type: masterActions.CREATE_PROJECT, payload: { data: data, resetForm: resetForm } });
  // };

  const resetForm = () => {
    reset({
      projectname: "",
      projectcode: "",
    });
  };

  const handleProjectUpdate = (value) => {
    console.log(value,'val')
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: {
        show: true,
        type: "Project",
        method: "UP",
        data: value?.projectid,
      },
    });
    reset({ ...value });
  };

  const handleSubmitProject = (data) => {
    data["userid"] = backofficeTokenDetails?.id;
    dispatch({
      type: masterActions.UPDATE_PROJECT,
      payload: { id: data?.projectid, data: data, resetForm: resetForm },
    });
  };

  // const handleSearch = (e) => {
  //     let filtered = [];
  //     setCurrentPage(1);
  //     setItemOffset(0);
  //     if (e.target.value) {
  //         //eslint-disable-next-line
  //         filtered = projectList?.all?.filter(val => {
  //             if (val?.projectname?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
  //                 val?.projectcode?.toLowerCase().includes(e.target.value?.toLowerCase())) {
  //                 return val;
  //             }
  //         });
  //     } else {
  //         filtered = [...projectList?.all];
  //     }
  //     dispatch({ type: masterActions.SET_PROJECT_LIST, payload: { ...projectList, filtered: filtered } })
  // };

  const handleAddMaster = () => {
    navigate(`/${Cookies.get("mhet_mst_project")}/create-master`);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "24px",
          marginBottom: "14px",
        }}
      >
        <Box></Box>
        <Searchbar />
        <MauvelousPrimaryButton
          title={"Add Master"}
          handleClick={handleAddMaster}
        />
      </Box>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={"masterList"}
        handleProjectUpdate={handleProjectUpdate}
      />
      <TablePagination
        pageCount={pageCount}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
      <EditModal
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        reset={reset}
        getValues={getValues}
        watch={watch}
        setValue={setValue}
        courseListByDrive={courseListByDrive}
        examdateByCourse={examdateByCourse}
        driveList={driveList}
        // handleUpdateProject={handleSubmitProject}
      />
      <DeleteModal
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      />
    </>
  );
}
