import React from "react";
import { Button } from "@mui/material";
import ButtonLoader from "../buttonLoader";

export default function ViewButton({ name, handleView }) {
  return (
    <>
      {name === "loader" ? (
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#F0F3F7",
            borderRadius: "40px",
            color: "#3F41D1",
            height:"40px"
          }}
        >
          <ButtonLoader />
        </Button>
      ) : (
        <Button
          variant="contained"
          disableElevation
          size="small"
          style={{
            backgroundColor: "#F0F3F7",
            borderRadius: "40px",
            color: "#3F41D1",
            border: "1px solid #3F41D1",
            fontSize: "14px",
            fontWeight: "bold",
            height:"40px"
          }}
          onClick={handleView}
        >
          {name}
        </Button>
      )}
    </>
  );
}
