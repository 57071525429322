import React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import { capitalizeFirstLetter } from "../../utils/validation";

export default function Index() {

    const navigate = useNavigate();

    const items = [
        { name: "gender", details: "Create and update gender master" },
        { name: "mother tongue", details: "Create and update mother tongue master" },
        { name: "religion", details: "Create and update religion master" },
        { name: "region", details: "Create and update region master" },
        { name: "nationality", details: "Create and update nationality master" },
        { name: "annual family income", details: "Create and update annual family income master" },
        { name: "state", details: "Create and update state master" },
        { name: "district", details: "Create and update district master" },
        { name: "taluka", details: "Create and update taluka master" },
        { name: "village", details: "Create and update village master" },
        { name: "category", details: "Create and update category master" },
        { name: "caste", details: "Create and update caste master" },
        { name: "pwd type", details: "Create and update pwd type master" },
        { name: "equivalent board", details: "Create and update equivalent board master" },
        { name: "board", details: "Create and update board master" },
        { name: "language", details: "Create and update language master" },
        { name: "minority", details: "Create and update minority master" },
        { name: "grievance category", details: "Create and update grievance category master" },
        { name: "grievance type", details: "Create and update grievance type master" },
        { name: "document type", details: "Create and update document type master" },
        { name: "course category", details: "Create and update document type master" },
        { name: "building type", details: "Create and update building type master" },
        { name: "venue type", details: "Create and update venue type master" },
        { name: "score card", details: "Create and update score card" },
        // { name: "course tab", details: "Create and update course tab master" },
        // { name: "course fields", details: "Create and update course fields master" },
    ];

    return (
        <Grid container spacing={3} sx={{ marginTop: '12px', justifyContent: 'center' }}>
            {items.map((item, index) => (
                <Grid item lg={3} md={4} sm={6} xs={12} key={index}
                    display="flex" justifyContent="center"
                    onClick={() => navigate(`/${Cookies.get('mhet_mst_project')}/master-forms?form=${item?.name?.replace(/ /g, '')}`)}
                >
                    <Card
                        sx={{
                            width: '100%',
                            height: '227px',
                            borderRadius: "8px",
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: "none"
                        }}
                    >
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    color: `${cssProperties?.fontcolor?.tableblack}`,
                                    textAlign: 'center'
                                }}
                            >
                                {capitalizeFirstLetter(item.name)}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: 12,
                                    fontWeight: 500,
                                    color: `${cssProperties?.color?.textsecondary}`,
                                    textAlign: 'center',
                                }}
                            >
                                {item.details}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
};