import { Box, Card, InputLabel, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { IoCloudUploadOutline } from 'react-icons/io5';
import BluePrimaryButton from '../../common/button/bluePrimaryButton';
import xlsx from "../../assets/images/uploads/xlsx.png";
import { PiX } from 'react-icons/pi';
import * as XLSX from 'xlsx-js-style'
import axios from 'axios';
import { API_URL } from '../../utils/constants';

const BulkUploadCandidates = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [document, setDocument] = useState(null)
    const [documentError, setDocumentError] = useState('')
    const handleDocumentUpload = (event) => {
        const fileName = event.target.files[0].name;
        const file = event.target.files[0];

        setDocument(file);

        event.target.value = null;
    };
    const handleCloseDocument = () => {
        setDocument(null);
    };
    const handleDocumentDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {

            setDocument(file);
            setDocumentError("")
            event.target.value = null;
        }
    };
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleFileRead = async (document) => {
        if (document) {
            setDocumentError("")
            let formData = new FormData()
            const documentFile = new File(
                [document],
                document.name,
                {
                  type: "application/xlsx",
                }
              );
            formData.append("bulkuploadfile", documentFile)
            let response = await axios.post(`${API_URL}/backoffice/bulkuploadcandidate`, formData)
            console.log(response)
        } else {
            setDocumentError("please select a document")
        }



    };
    return (
        <Box sx={{
            padding: "20px 25px 0px 25px",
            minHeight: "60vh",
            backgroundColor: "#fff",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
        }} >
            <InputLabel style={{ fontWeight: "bold" }}>
                Upload Document<span className="error" style={{ color: '#666666' }}>*</span>
            </InputLabel>
            {!document && <Box
                style={{
                    border: "1px dashed gray",
                    maxWidth: "330px",
                    minHeight: "32px",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 2,
                }}
                onDrop={handleDocumentDrop}
                onDragOver={handleDragOver}
            >
                <input
                    type="file"
                    name="documentfilename"
                    id="documentfilename"
                    accept=".xlsx"
                    style={{
                        width: "0.1px",
                        height: "0.1px",
                        opacity: 0,
                        overflow: "hidden",
                        position: "absolute",
                        zIndex: -1,
                    }}
                    onChange={handleDocumentUpload}
                />
                <label
                    htmlFor="documentfilename"
                    style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    &nbsp; <IoCloudUploadOutline size={22} /> &nbsp; Drag or
                    drop your document, or{" "}
                    <span style={{ color: "blue" }}>&nbsp;browse</span>
                </label>
            </Box>
            }
            {document && (
                <Box
                    style={{
                        width: "17.6em",
                        height: "45px",
                        border: "1px solid #D5D5D5",
                        display: "flex",
                        alignItems: "center",
                        gap: "1%",
                        marginTop: "1rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        margin: "6px 0px 0px 8px"
                    }}
                >
                    <img
                        src={xlsx}
                        style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                        alt="xlsx"
                    />
                    <span
                        style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                        }}
                    >
                        {document?.name}
                    </span>
                    <PiX
                        style={{ margin: 0, padding: 0, cursor: "pointer" }}
                        onClick={handleCloseDocument}
                    />
                </Box>
            )}
            {(documentError && documentError !== "") &&



                <Box sx={{ marginTop: 2, }}>
                    <Typography variant='caption' sx={{ textAlign: 'center', color: 'red' }}>{documentError}</Typography>
                </Box>

            }
            <Box sx={{ marginTop: 2, }}>
                <BluePrimaryButton
                    title={"Submit"}
                    handleSubmit={() => handleFileRead(document)}
                // disabled={}
                />
            </Box>
        </Box>
    )
}

export default BulkUploadCandidates
