import { Box, Card, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import DangerButton from '../../common/button/DangerButton';
import MauvelousPrimaryButton from '../../common/button/mauvelousPrimaryButton';
import DataTable from '../../common/dataTable';
import TablePagination from '../../common/tablePagination';
import { useDispatch, useSelector } from 'react-redux';
import masterActions from '../../redux/master/actions';
import forcePushAllocationActions from '../../redux/forcePushAllocation/actions';
import commonActions from '../../redux/common/actions';
import masterFormsActions from '../../redux/masterForms/actions';
import AllocationModal from './allocationModal';
import BluePrimaryButton from '../../common/button/bluePrimaryButton';

const columnData = [
    {
        name: 'select all',
        accessor: 'select checkbox'
    },
    {
        name: 'S No',
        accessor: 'sno'
    },
    {
        name: 'Candidate Name',
        accessor: 'candidatename'
    },
    {
        name: 'Course',
        accessor: 'coursename'
    },
    {
        name: 'Status',
        accessor: 'status'
    },
    {
        name: 'Allocated Venue',
        accessor: 'allocatedvenuename'
    },
    {
        name: 'Allocated District',
        accessor: 'allocateddistrict'
    },
    {
        name: 'Preference 1',
        accessor: 'preference1district'
    },
    {
        name: 'Preference 2',
        accessor: 'preference2district'
    },
    {
        name: 'Preference 3',
        accessor: 'preference3district'
    },
]

const Index = () => {

    const dispatch = useDispatch();

    const { courseList } = useSelector(state => state.masterReducer);

    const { districtList } = useSelector(state => state.masterFormsReducer);

    const { forcePushSelectedValues, candidateListForAllocation, venueListByDistrict } = useSelector(state => state.forcePushAllocationReducer);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemOffset, setItemOffset] = useState(0);
    console.log(venueListByDistrict,'vd')

    useEffect(() => {
        dispatch({ type: masterActions.GET_COURSE_LIST });
        dispatch({ type: masterFormsActions.GET_DISTRICT });
    }, []);

    const handleValueChanges = (e, type) => {
        let selectedData = { ...forcePushSelectedValues, [type]: e.target.value };
        if (type === 'districtid') {
            selectedData = { ...selectedData, venueid: null };
            dispatch({
                type: forcePushAllocationActions.GET_VENUE_LIST_BY_DISTRICT,
                payload: { courseid: forcePushSelectedValues?.courseid, districtid: e.target.value }
            })
        } else if (type === 'courseid') {
            selectedData = { ...selectedData, districtid: null, venueid: null };
            dispatch({ type: forcePushAllocationActions.SET_VENUE_LIST_BY_DISTRICT, payload: [] });
        } else if (type === 'venueid') {
            selectedData = { ...selectedData, selectall: false };
        };
        dispatch({ type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES, payload: selectedData })
    };

    const handleGetCandidateList = (btnType) => {
        if (forcePushSelectedValues?.courseid) {
            let isallocated = "0", isdisability = "0";
            dispatch({
                type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
                payload: { ...forcePushSelectedValues, buttonType: btnType, selectall: false, venueid: null }
            });
            let venuelist = [];
            if (btnType === 'Allocated') {
                isallocated = "1";
                isdisability = "0";
                venuelist = venueListByDistrict?.all?.filter(val => {
                    if (val?.totalseats > val?.allocatedseats) {
                        return val;
                    }
                });
            } else if (btnType === 'Not Allocated') {
                isallocated = "0";
                isdisability = "0";
                venuelist = venueListByDistrict?.all?.filter(val => {
                    if (val?.totalseats > val?.allocatedseats) {
                        return val;
                    }
                });
            } else if (btnType === 'PH Allocated') {
                isallocated = "1";
                isdisability = "1";
                venuelist = venueListByDistrict?.all?.filter(val => {
                    if (val?.totalseats > val?.allocatedseats && val?.ispwdsuitable === '1') {
                        return val;
                    }
                });
            } else if (btnType === 'PH Not Allocated') {
                isallocated = "0";
                isdisability = "1";
                venuelist = venueListByDistrict?.all?.filter(val => {
                    if (val?.totalseats > val?.allocatedseats && val?.ispwdsuitable === '1') {
                        return val;
                    }
                });
            };
            dispatch({
                type: forcePushAllocationActions.SET_VENUE_LIST_BY_DISTRICT,
                payload: { ...venueListByDistrict, filtered: venuelist }
            });
            dispatch({
                type: forcePushAllocationActions.GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                payload: {
                    data: {
                        courseid: forcePushSelectedValues?.courseid,
                        isallocated: isallocated,
                        isdisability: isdisability,
                        offset: 0
                    },
                    type: 'button'
                }
            });
        } else {
            dispatch({
                type: commonActions.SET_ALERT,
                payload: { status: "failed", show: true, message: 'Select any course to get candidates' },
            });
            setTimeout(() => {
                dispatch({
                    type: commonActions.SET_ALERT,
                    payload: { status: null, show: false, message: null },
                });
            }, 2000)
        }
    };

    const endOffset = itemOffset + forcePushSelectedValues?.showperpage;
    const currentItems = candidateListForAllocation?.filtered?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(candidateListForAllocation?.filtered?.length / forcePushSelectedValues?.showperpage);

    const handlePageChange = (event, value) => {
        const newOffset = (value - 1) * forcePushSelectedValues?.showperpage;
        setItemOffset(newOffset);
        setCurrentPage(value);
        if (pageCount < value && forcePushSelectedValues?.totalcount > candidateListForAllocation?.all?.length) {
            let isallocated = "0", isdisability = "0";
            if (forcePushSelectedValues?.buttonType === 'Allocated') {
                isallocated = "1";
                isdisability = "0";
            } else if (forcePushSelectedValues?.buttonType === 'Not Allocated') {
                isallocated = "0";
                isdisability = "0";
            } else if (forcePushSelectedValues?.buttonType === 'PH Allocated') {
                isallocated = "1";
                isdisability = "1";
            } else if (forcePushSelectedValues?.buttonType === 'PH Not Allocated') {
                isallocated = "0";
                isdisability = "1";
            };
            dispatch({
                type: forcePushAllocationActions.GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                payload: {
                    data: {
                        courseid: forcePushSelectedValues?.courseid,
                        isallocated: isallocated,
                        isdisability: isdisability,
                        offset: forcePushSelectedValues?.offset + 1000
                    },
                    type: 'pagination'
                }
            });
        }
    };

    const handleSelectAll = (e) => {
        console.log("currentPage", currentPage)
        let selectallarr = [...forcePushSelectedValues?.selectallpage];
        if ((selectallarr?.length === 0 || !selectallarr?.find(val => val === currentPage)) && e.target.checked) {
            selectallarr = [...selectallarr, currentPage];
        } else {
            let selectInd = selectallarr?.findIndex(val => val === currentPage);
            if (selectInd !== -1) {
                selectallarr.splice(selectInd, 1);
            }
        }
        dispatch({
            type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
            payload: { ...forcePushSelectedValues, selectall: !forcePushSelectedValues?.selectall, selectallpage: selectallarr }
        });
        if (e.target.checked && (forcePushSelectedValues?.buttonType === 'Not Allocated' || forcePushSelectedValues?.buttonType === 'PH Not Allocated')) {
            let selectedvenue = venueListByDistrict?.filtered?.find((val) => parseInt(val?.venueid) === parseInt(forcePushSelectedValues?.venueid));
            let selectedcandidates = candidateListForAllocation?.filtered?.filter(val => {
                if (val?.selected === true) {
                    return val
                }
            });
            let pendingSeats = selectedvenue?.totalseats - selectedvenue?.allocatedseats - (selectedcandidates?.length || 0);
            let candidateList = [...candidateListForAllocation?.filtered];
            if (pendingSeats > 0) {
                let nonselectedcandidates = candidateListForAllocation?.filtered?.filter((val) => {
                    if (val?.selected === false) {
                        return val;
                    }
                })?.map((val) => {
                    return val?.candidateid
                });
                for (let i = 0; i < pendingSeats; i++) {
                    let candidateInd = candidateListForAllocation?.filtered?.findIndex(v => v?.candidateid === nonselectedcandidates[i]);
                    candidateList[candidateInd].selected = true;
                }
                dispatch({
                    type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                    payload: { ...candidateListForAllocation, filtered: candidateList }
                });
                dispatch({
                    type: commonActions.SET_ALERT,
                    payload: { status: "failed", show: true, message: `${pendingSeats} seats available for the selected venue` },
                });
                setTimeout(() => {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { status: null, show: false, message: null },
                    });
                }, 2000)
            } else {
                dispatch({
                    type: commonActions.SET_ALERT,
                    payload: { status: "failed", show: true, message: 'No Seats found for the selected venue' },
                });
                setTimeout(() => {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { status: null, show: false, message: null },
                    });
                }, 2000)
            }
        } else {
            let candidates = candidateListForAllocation?.filtered?.map((val) => {
                if (currentItems?.find((c) => c.candidateid === val?.candidateid)) {
                    return { ...val, selected: !forcePushSelectedValues?.selectall }
                } else {
                    return val;
                }
            });
            dispatch({
                type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                payload: { ...candidateListForAllocation, filtered: candidates }
            });
        }
    };

    const handleSingleSelect = (event, candidateid) => {
        let index = candidateListForAllocation?.filtered?.findIndex(val => val?.candidateid === candidateid);
        let candidates = [...candidateListForAllocation?.filtered];
        candidates[index].selected = event.target.checked;
        if (event.target.checked && (forcePushSelectedValues?.buttonType === 'Not Allocated' || forcePushSelectedValues?.buttonType === 'PH Not Allocated')) {
            let selectedvenue = venueListByDistrict?.filtered?.find((val) => parseInt(val?.venueid) === parseInt(forcePushSelectedValues?.venueid));
            let selectedcandidates = candidateListForAllocation?.filtered?.filter(val => {
                if (val?.selected === true) {
                    return val
                }
            });
            let pendingSeats = selectedvenue?.totalseats - selectedvenue?.allocatedseats - (selectedcandidates?.length || 0);
            if (pendingSeats >= 0) {
                dispatch({
                    type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                    payload: { ...candidateListForAllocation, filtered: candidates }
                });
            } else {
                dispatch({
                    type: commonActions.SET_ALERT,
                    payload: { status: "failed", show: true, message: `No seats available for the selected venue` },
                });
                setTimeout(() => {
                    dispatch({
                        type: commonActions.SET_ALERT,
                        payload: { status: null, show: false, message: null },
                    });
                }, 2000)
            }
        } else {
            dispatch({
                type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                payload: { ...candidateListForAllocation, filtered: candidates }
            });
        }
    };

    const handleAllocationClick = () => {
        if (forcePushSelectedValues?.buttonType === 'Allocated' || forcePushSelectedValues?.buttonType === 'PH Allocated') {
            dispatch({ type: forcePushAllocationActions.FORCE_PUSH_CANCEL_ALLOCATION });
        } else {
            dispatch({
                type: commonActions.SET_SHOW_MODAL,
                payload: { show: true, type: 'FPA', method: null, data: null }
            });
            dispatch({
                type: forcePushAllocationActions.GET_EXAM_DATES_BY_COURSEID,
                payload: { courseid: parseInt(forcePushSelectedValues?.courseid) }
            });
        }
    };

    const handleSelectVenue = (e) => {
        handleValueChanges(e, 'venueid');
        let candidates = candidateListForAllocation?.filtered?.map((val) => {
            return { ...val, selected: false }
        });
        dispatch({
            type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
            payload: { ...candidateListForAllocation, filtered: candidates }
        });
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Select Course<span className="error" style={{ color: "red" }}>*</span></InputLabel>
                        <Select
                            size='small'
                            label="Select Course"
                            onChange={(e) => handleValueChanges(e, 'courseid')}
                            value={forcePushSelectedValues?.courseid ?? ""}
                        >
                            <MenuItem value="">
                                <em>--Select--</em>
                            </MenuItem>
                            {courseList?.all?.map((course, i) =>
                                <MenuItem key={i} value={course.courseid}>{course.coursename}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    {forcePushSelectedValues?.buttonType === 'Allocated' ?
                        <BluePrimaryButton title="Allocated" fontWeight={"600"} handleSubmit={() => handleGetCandidateList('Allocated')} /> :
                        <DangerButton title="Allocated" handleSubmit={() => handleGetCandidateList('Allocated')} />
                    }
                    {forcePushSelectedValues?.buttonType === 'Not Allocated' ?
                        <BluePrimaryButton title="Yet to allocated" fontWeight={"600"} handleSubmit={() => handleGetCandidateList('Not Allocated')} /> :
                        <DangerButton title="Yet to allocated" handleSubmit={() => handleGetCandidateList('Not Allocated')} />
                    }
                    {forcePushSelectedValues?.buttonType === 'PH Allocated' ?
                        <BluePrimaryButton title="PH Allocated Candidates" fontWeight={"600"} handleSubmit={() => handleGetCandidateList('PH Allocated')} /> :
                        <DangerButton title="PH Allocated Candidates" handleSubmit={() => handleGetCandidateList('PH Allocated')} />
                    }
                    {forcePushSelectedValues?.buttonType === 'PH Not Allocated' ?
                        <BluePrimaryButton title="PH Non Allocated Candidates" fontWeight={"600"} handleSubmit={() => handleGetCandidateList('PH Not Allocated')} /> :
                        <DangerButton title="PH Non Allocated Candidates" handleSubmit={() => handleGetCandidateList('PH Not Allocated')} />
                    }
                </Box>
            </Box>
            <Card
                sx={{
                    width: '100%',
                    textAlign: 'start',
                    borderRadius: "8px",
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: "none",
                    padding: 2,
                    background: 'none'
                }}
            >
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
                    <Typography variant='p' color="#212121" fontWeight={600} component="p" >
                        Forced Allocation
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel>Select District<span className="error" style={{ color: "red" }}>*</span></InputLabel>
                            <Select
                                size='small'
                                label="Select Course"
                                onChange={(e) => handleValueChanges(e, 'districtid')}
                                value={forcePushSelectedValues?.districtid ?? ""}
                                disabled={forcePushSelectedValues?.courseid ? false : true}
                            >
                                <MenuItem value="">
                                    <em>--Select--</em>
                                </MenuItem>
                                {districtList?.all?.map((d, i) =>
                                    <MenuItem key={i} value={d.districtid}>{d.district}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel>Select Venue<span className="error" style={{ color: "red" }}>*</span></InputLabel>
                            <Select
                                size='small'
                                label="Select Venue"
                                value={forcePushSelectedValues?.venueid ?? ""}
                                onChange={handleSelectVenue}
                                disabled={(forcePushSelectedValues?.courseid && forcePushSelectedValues?.districtid) ? false : true}
                            >
                                <MenuItem value="">
                                    <em>--Select--</em>
                                </MenuItem>
                                {venueListByDistrict?.all?.map((d, i) =>
                                    <MenuItem key={i} value={d.venueid}>{d.venuename}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography>Show per page</Typography>
                        <Select defaultValue={100} size='small' sx={{ minWidth: 100 }} onChange={(e) => handleValueChanges(e, 'showperpage')}>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </Box>
                    <MauvelousPrimaryButton
                        title={(forcePushSelectedValues?.buttonType === 'Allocated' || forcePushSelectedValues?.buttonType === 'PH Allocated') ? 'Cancel Allocation' : 'Allocate'}
                        handleClick={handleAllocationClick}
                        disabled={((forcePushSelectedValues?.buttonType === 'Allocated' || forcePushSelectedValues?.buttonType === 'PH Allocated') && forcePushSelectedValues?.courseid && candidateListForAllocation?.filtered?.find(val => val?.selected === true)) ? false :
                            ((forcePushSelectedValues?.buttonType === 'Not Allocated' || forcePushSelectedValues?.buttonType === 'PH Not Allocated') && forcePushSelectedValues?.courseid && forcePushSelectedValues?.districtid && forcePushSelectedValues?.venueid && candidateListForAllocation?.filtered?.find(val => val?.selected === true)) ?
                                false : true}
                    />
                </Box>
                <Box sx={{ width: '100%', paddingTop: 2 }}>
                    <DataTable
                        values={currentItems}
                        keys={columnData}
                        page={'force push'}
                        handleSelectAll={handleSelectAll}
                        handleSingleSelect={handleSingleSelect}
                    />
                </Box>
                <Box sx={{ padding: '15px 0', width: '100%', py: 1 }}>
                    <TablePagination
                        pageCount={(forcePushSelectedValues?.totalcount > candidateListForAllocation?.all?.length) ? pageCount + 1 : pageCount}
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                    />
                </Box>
            </Card>
            <AllocationModal />
        </>
    )
}

export default Index
