import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useDispatch } from "react-redux";
import commonActions from "../../redux/common/actions";
import Prerequesite from "../courseManagement/preRequesite";

export default function ScoreCard() {
  const [scoreCardContent, setScoreCardContent] = useState("");
  const [showModel, setShowModel] = useState({
    show: false,
    data: null,
  });
  console.log(scoreCardContent);

  const handleClose = () => {
    setShowModel({ show: false, data: null });
  };

  const handleshowModal = () => {
    setShowModel({ show: true, data: null });
  };

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "24px",
          marginBottom: "14px",
        }}
      >
        <MauvelousPrimaryButton
          title={"Add"}
          icon={<PiPlusBold />}
          handleClick={handleshowModal}
        />
      </Grid>
      <Grid item lg={6} sm={12} xs={12}>
        <div
          style={{
            borderRadius: "8px",
            textWrap: "wrap",
            backgroundColor: "#FCDEDC",
            padding: "30px",
          }}
          dangerouslySetInnerHTML={{ __html: scoreCardContent }}
        />
      </Grid>

      <Prerequesite
        showModal={showModel?.show}
        handleClose={handleClose}
        page={"scorecard"}
        setContent={setScoreCardContent}
          
      />
    </>
  );
}
