import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function Index() {

    const navigate = useNavigate();

    const projects = [
        { projectname: 'mhcet', path: 'mhcet' },
        { projectname: 'school', path: '' },
        { projectname: 'project 3', path: '' },
        { projectname: 'project 4', path: '' },
    ];

    const handleProjectClick = (project) => {
        Cookies.set('mhet_mst_project', project, { expires: 7 });
        navigate(`/${project}/userlogin`);
        window.location.reload();
      };
    
      useEffect(() => {
        // setTimeout(() => {
          if (!Cookies.get('mhet_mst_token') && Cookies.get('mhet_mst_project')) {
            Cookies.remove('mhet_mst_project');
          };
        // }, 2000)
      }, []);

    return (
        <Container>
            <Grid container spacing={3} xl={12} sx={{ display: "flex", justifyContent: "center", alignItems: 'center', minHeight: "100vh" }}>
                {projects?.map((val, i) =>
                    <Grid item sx={{ textAlign: "center", cursor: 'pointer' }} key={i} xs={12} sm={6} md={3} onClick={() => handleProjectClick(val?.path)}>
                        <Box sx={{ backgroundColor: cssProperties?.backgroundcolor?.secondary, padding: '16px 24px', borderRadius: '8px', fontWeight: 500, lineHeight: '15.4px', textTransform: 'uppercase' }}>
                            {val?.projectname}
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}