import React from "react";
import Index from "../components/ObjectionTrackerView/index";
import Sidebar from "../layouts/sidebar";
import Header from "../layouts/header";
import { Link as MuiLink, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";
import Cookies from "js-cookie";

export default function ObjectionTrackerView() {

    const breadcrumbs = [
        <MuiLink underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_mst_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}>
            Dashboard
        </MuiLink>,
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,    
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            View Objections <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];

    return (
        <>
            <Sidebar />
            <Header breadcrumbs={breadcrumbs} />
            <Layout>
                <PageHeading title={'Objections'} />
                <Index />
            </Layout>
        </>
    )
}