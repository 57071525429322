import React from "react";
import Index from "../components/masterDashboard";

export default function MasterDashboard() {

    return(
        <div>
            <Index />
        </div>
    )
}