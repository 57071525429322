import React from "react";
import Index from "../components/mainLandingPage";

export default function MainLandingPage() {

    return(
        <div>
            <Index />
        </div>
    )
}