import { Typography } from "@mui/material";
import React from "react";

export default function NotFound() {

    return(
        <div>
            <Typography variant="h3">Page Not Found</Typography>
        </div>
    )
}