import ticketsActions from "./actions";

const initialState = {
  ticketsList: {
    all: [],
    filtered: [],
  },
  ticketDetails: null,
  setFilters: {
    searchText: null,
    filterBy: "All",
    sort: {
      col: null,
      order: null,
    },
    ticketDetails: null,
    setFilters: {
      searchText: null,
      filterBy: "All",
      sort: {
        col: null,
        order: null
      }
    },
    showResponseEdit: {
      show: false, response: null, type: null, isEditable: true
    },
    showCommentsEdit: {
      show: false, response: null, type: null, isEditable: true
    },
    ticketStatusCount: null,
  },
  candidateDetails: [],
  queries: {
    all: [],
    filtered: [],
  },
  raisedQueries: [],
  TotalTicketCountData: [],
  averageResponseTime: [],
};

const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ticketsActions.SET_TICKETLIST_BY_USER:
      return {
        ...state,
        ticketsList: action.payload
      }
    case ticketsActions.SET_TICKETDETAILS:
      return {
        ...state,
        ticketDetails: action.payload
      }
    case ticketsActions.SET_FILTERS:
      return {
        ...state,
        setFilters: action.payload
      }
    case ticketsActions.SET_TICKET_RESPONSE_EDIT:
      return {
        ...state,
        showResponseEdit: action.payload
      }
    case ticketsActions.SET_TICKET_COMMENTS_EDIT:
      return {
        ...state,
        showCommentsEdit: action.payload
      }
    case ticketsActions.SET_TICKETSTATUSCOUNT:
      return {
        ...state,
        ticketStatusCount: action.payload
      }
    case ticketsActions.SET_CANDIDATE_DETAILS:
      return {
        ...state,
        candidateDetails: action.payload,
      };
    case ticketsActions.SET_ALL_QUERY:
      return {
        ...state,
        queries: action.payload,
      };
    case ticketsActions.SET_RAISED_QUERY:
      return {
        ...state,
        raisedQueries: action.payload
      }
    case ticketsActions.SET_TOTAL_TICKET_COUNT:
        return {
          ...state,
          TotalTicketCountData: action.payload
        }  
    case ticketsActions.SET_AVERAGE_RESPONSETIME_COUNT:
        return {
          ...state,
          averageResponseTime: action.payload
        }  
    default:
      return state;
  };
};

export default ticketsReducer;
