import React from "react";
import Header from "../layouts/header";
import Sidebar from "../layouts/sidebar";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";
import Index from "../components/courseManagement";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link as MuiLink, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Cookies from "js-cookie";

export default function CourseManagement() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const naviagte = useNavigate();

  const handleClick = () => {
    if (page === "addcourse" || page === "updatecourse" || page === "createcourse") {
      naviagte(`/${Cookies.get("mhet_mst_project")}/course-management?page=courselist`);
    }
  };

  const breadcrumbs = [
    <MuiLink
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_mst_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </MuiLink>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: page === "courselist"  ? "bold" : 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
        ":hover":{
          textDecoration: page === "courselist" ? "none" : "underline",
          cursor : page === "courselist" ? "none" : "pointer"
        }
      }}
      onClick={page === "courselist" ? undefined : handleClick}
    >
      Course Management {page === "courselist" && <MdKeyboardArrowDown size={16} /> }
    </Typography>,
  ];

  if (page === "addcourse") {
    breadcrumbs.push(
      <Typography
        key="2"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        }}
      >
        Add Course<MdKeyboardArrowDown size={16} />
      </Typography>
    );
  }else if (page === "updatecourse"){
    breadcrumbs.push(
      <Typography
        key="2"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        }}
      >
        Update Course<MdKeyboardArrowDown size={16} />
      </Typography>
    );
  }else if (page === "createcourse"){
    breadcrumbs.push(
      <Typography
        key="2"
        color="text.primary"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          color: cssProperties?.fontcolor?.primary,
          fontSize: "14px",
          fontWeight: "bold",
          lineHeight: "20px",
          letterSpacing: "0.1px",
        }}
      >
        Create Course<MdKeyboardArrowDown size={16} />
      </Typography>
    );
  }

  return (
    <>
      <Header breadcrumbs={breadcrumbs}/>
      <Sidebar />
      <Layout>
        <PageHeading
          title={
            page === "courselist"
              ? "Course Management"
              : page === "addcourse"
              ? "Add Course"
              : page === "updatecourse"
              ? "Update Course"
              : page === "createcourse"
              ? "Create Course"
              : null
          }
        />
        <Index />
      </Layout>
    </>
  );
}
