import React from "react";
import Index from "../components/forgetPassword";
import { Typography } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";

export default function ForgetPassword() {

  const breadcrumbs = [
    <Typography key="1" color="text.primary"
      sx={{
        display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.1px'
      }}
    >
      Forgot Password <MdKeyboardArrowRight size={16} />
    </Typography>
  ];

  return (
    <>
      <BeforeLoginHeader breadcrumbs={breadcrumbs} />
      <Index />
    </>
  );
};