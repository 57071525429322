import candidateActions from "./actions";

const initialState = {
    fullCandidateDetails: null,
    candidateTicketList: null,
};

const candidateReducer = (state = initialState, action) => {
    switch (action.type) {
        case candidateActions.SET_FULL_CANDIDATE_DETAILS:
            return {
                ...state,
                fullCandidateDetails: action.payload
            }
        case candidateActions.SET_TICKETS_LIST_BY_CANDIDATE:
            return {
                ...state,
                candidateTicketList: action.payload
            }
        default:
            return state
    }
};

export default candidateReducer;