import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // IconButton,
  // Typography,
} from "@mui/material";
// import { MdClose } from "react-icons/md";
import YesNoButton from "../../common/button/yesNoButton";
import { useSelector } from "react-redux";
import ButtonLoader from "../../common/buttonLoader";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";

export default function ConfirmModal({ showModal, handleClose, handleSubmitModal }) {
  // const navigate = useNavigate();

  const { buttonLoader } = useSelector(state => state.commonReducer);

  return (
    <Dialog
      open={showModal}
      //  onClose={handleClose}
      maxWidth="xl">
      {/* <DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle> */}
      <DialogContent>
        <DialogTitle sx={{ padding: 0 }} mt={2}>
          Are you sure you want to confirm the creation of the course?
        </DialogTitle>
        <DialogContentText mt={1}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: "16px" }}>
            <YesNoButton name={buttonLoader ? <ButtonLoader /> : "Yes"} type={"Yes"} handleSubmit={handleSubmitModal} />
            <YesNoButton name={"No"} disabled={buttonLoader ? true : false} type={"No"} handleSubmit={handleClose} />
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
