import React from "react";
import Header from "../layouts/header";
import Index from "../components/ticketRiserDetails";
import Sidebar from "../layouts/sidebar";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";
import { Link, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import { cssProperties } from "../utils/commonCssProperties";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";

export default function TicketRaiserDetails() {

  const { backofficeTokenDetails } = useSelector(state => state.authReducer);

  const managerbreadcrumbs = [
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_mst_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_mst_project")}/ticket-list`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Ticket raised
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Ticket Raiser details <MdKeyboardArrowDown size={16} />
    </Typography>,
  ];

  const resolutionbreadcrumbs = [
    <Link
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_mst_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: "bold",
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Ticket Raiser details <MdKeyboardArrowDown size={16} />
    </Typography>,
  ];


  return (
    <>
      <Header breadcrumbs={(backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator')) ? managerbreadcrumbs :
        backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution') ? resolutionbreadcrumbs : ""
      } />
      <Sidebar />
      <Layout>
        <PageHeading title={'Ticket Raiser details'} />
        <Index />
      </Layout>
    </>
  );
}
