import React from "react";
import { Modal, Box, Typography } from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from "../../common/button/cancelButton";
import { Controller, useForm } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import commonActions from "../../redux/common/actions";
import { useDispatch } from "react-redux";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
  padding: '24px',
  borderRadius: '8px'
};

export default function MasterModel({ modelOpen, handleCreateExamDate }) {

  const dispatch = useDispatch();

  const { handleSubmit, formState: { errors }, reset, control } = useForm();

  const onSubmit = (data) => {
    handleCreateExamDate(data, handleReset);
  };

  const handleReset = () => {
    reset();
  };

  const handleCloseModal = () => {
    handleReset();
    dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } })
  };

  return (
    <Modal
      open={modelOpen?.show}
      aria-labelledby="candidate-details-modal-title"
      aria-describedby="candidate-details-s-description"
    >
      <Box sx={{ ...style }}>
        <Typography variant="h5" sx={{ color: `${cssProperties?.color?.primary}`, fontWeight: '500', marginBottom: '18px' }}>Add {modelOpen.type}</Typography>
        <Box>
          <Controller
            name="examdate"
            control={control}
            rules={{
              required: "Exam date is required",
              validate: {
                validDate: (date) => {
                  const isValid = date && (dayjs(date).isSame(dayjs(), 'day') || dayjs(date).isAfter(dayjs(), 'day'));
                  return isValid || "Date must be a today or later";
                }
              }
            }}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  {...field}
                  label={
                    <span>
                      Exam Date
                      <span className="error" style={{ color: "red" }}> *</span>
                    </span>
                  }
                  format="DD/MM/YYYY"
                  disablePast
                  onChange={(date) => field.onChange(date)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      size: "small",
                      error: !!errors.examdate,
                      helperText: errors.examdate?.message,
                      FormHelperTextProps: {
                        style: { margin: 0 },
                      },
                      style: {
                        width: "100%",
                        borderRadius: "8px",
                      },
                    },
                    layout: {
                      sx: {
                        borderRadius: "58px",
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />
          {/* <TextField
            {...register('examdate', {
              require: "Exam date is required"
            })}
            // value={value}
            // onChange={(e) => setValue(e.target.value)}
            type={modelOpen.type === "Date" ? "date" : "text"}
            InputLabelProps={{ shrink: true }}
            size="small"
            label={modelOpen.type}
          /> */}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
          <CancelButton
            handleBack={handleCloseModal}
          />
          {MauvelousPrimaryButton({ title: "Submit", handleClick: handleSubmit(onSubmit) })}
        </Box>
      </Box>
    </Modal>
  );
}
