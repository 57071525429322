import courseActions from "./actions";

const initialState = {
    courseTabMaster: [],
    courseFieldMaster: [],
    // buttonEnable: {
    //     submitbtnenable: false,
    //     priviewbtnenable: false
    // }
};

const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case courseActions.SET_COURSETAB_MASTER:
            return {
                ...state,
                courseTabMaster: action.payload
            }
        case courseActions.SET_COURSEFIELD_MASTER:
            return {
                ...state,
                courseFieldMaster: action.payload
            }
        // case courseActions.SET_BUTTON_ENABLE:
        //     return {
        //         ...state,
        //         buttonEnable: action.payload
        //     }
        default:
            return state;
    };
};

export default courseReducer;