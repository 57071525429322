import { Box, MenuItem, Radio, Select } from '@mui/material'
import React from 'react'
import PrintDownloadButton from '../../common/button/printDownloadButton'
import { MdDownload, MdVisibility } from 'react-icons/md'
import DataTable from '../../common/dataTable'
import DangerButton from '../../common/button/DangerButton'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

const Index = ({ role }) => {
  const navigate = useNavigate()
  const columnData = role === 'Objection Reviewer' ? [
    {
      name: 'Question No',
      accessor: 'question_no'
    },
    {
      name: 'Question Details',
      accessor: 'question_details'
    },
    {
      name: 'Answer Key Response',
      accessor: 'answer_key'
    },
    {
      name: 'Reference Details',
      accessor: 'reference_details'
    },
    {
      name: 'Action',
      accessor: 'action'
    },

  ] : [
    {
      name: 'Question No',
      accessor: 'question_no'
    },
    {
      name: 'Question Details',
      accessor: 'question_details'
    },
    {
      name: 'Answer Key Response',
      accessor: 'answer_key'
    },
    {
      name: 'Reference Details',
      accessor: 'reference_details'
    },
    {
      name: 'Status',
      accessor: 'status'
    },

  ]
  let tableData = role === 'Objection Reviewer' ? [
    {
      question_no: 1,
      question_details: 'Registration',
      answer_key: 1000,
      reference_details: '12/04/2024',
      action: <DangerButton handleSubmit={() => handleSubmit(1)} title="view" />,
    },
    {
      question_no: 2,
      question_details: 'Registration',
      answer_key: 1000,
      reference_details: '12/04/2024',
      action: <DangerButton handleSubmit={() => handleSubmit(2)} title="view" />,
    },
    {
      question_no: 3,
      question_details: 'Registration',
      answer_key: 1000,
      reference_details: '12/04/2024',
      action: <DangerButton handleSubmit={() => handleSubmit(3)} title="view" />,
    },
  ] : [
    {
      question_no: 1,
      question_details: 'Registration',
      answer_key: 1000,
      reference_details: '12/04/2024',
      status: 'Accepted',
    },
    {
      question_no: 2,
      question_details: 'Registration',
      answer_key: 1000,
      reference_details: '12/04/2024',
      status: 'Rejected',
    },
    {
      question_no: 3,
      question_details: 'Registration',
      answer_key: 1000,
      reference_details: '12/04/2024',
      status: 'Pending',
    },
  ]
  const handleSubmit = (id) => {
    console.log('clicked', id)
    let {action,...data} =tableData[id - 1]
    navigate(`/${Cookies.get('mhet_mst_project')}/view-single-objection`,{state:data});
  }
  return (
    <>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
        {/* <Typography pb={1} variant="h5"  >Dashboard | Objection Tracker</Typography> */}

      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {/* <Select defaultValue={1} size="small">
                        <MenuItem value={1}>Project Name</MenuItem>
                    </Select> */}
          <Select defaultValue={1} size="small">
            <MenuItem value={1}>Course Name</MenuItem>
          </Select>
          <Select defaultValue={1} size="small">
            <MenuItem value={1}>Select Question No</MenuItem>
          </Select>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <PrintDownloadButton name="View Raised Objection" startIcon={<MdVisibility />} />
          <PrintDownloadButton name="Download" startIcon={<MdDownload />} />
        </Box>
      </Box>
      <Box>
        <DataTable keys={columnData} values={tableData} />

      </Box>


    </>
  )
}

export default Index
