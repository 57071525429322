import { call, delay, put, takeEvery } from "redux-saga/effects";
import venueActions from "./actions";
import axios, { all } from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const venueSagas = function* () {
  yield all([
    yield takeEvery(venueActions.GET_CANDIDATE_COUNT, getCandidateCount),
    yield takeEvery(venueActions.UPLOAD_VENUE_DATA, createVenueData),
    yield takeEvery(venueActions.GET_VENUE_DETAILS, getVenueDetails),
    yield takeEvery(venueActions.RUN_SEAT_ALLOCATION, runSeatAllocation),
    yield takeEvery(venueActions.GET_SEAT_ALLOCATION, getSeatAllocation),
    yield takeEvery(
      venueActions.GET_SEAT_ALLOCATION_DETAILS,
      getVenueseatAllocationDetails
    ),
    yield takeEvery(
      venueActions.GET_CANDIDATE_ALLOCATION_DEAILS,
      getCandidateAllocationDetails
    ),
    yield takeEvery(
      venueActions.RESET_SEAT_ALLOCATION,
      resetSeatAllocationDetails
    ),
    yield takeEvery(
      venueActions.STOP_SEAT_ALLOCATION,
      stopSeatAllocationDetails
    ),
  ]);
};
const createVenueData = function* (data) {
  const { payload } = data;
  payload.setLoading(true);
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    if (payload?.file && payload?.courseid) {
      let formData = new FormData();
      const documentFile = new File([payload?.file], payload?.file.name, {
        type: "application/xlsx",
      });
      formData.append("venueFile", documentFile);
      formData.append("courseid", payload?.courseid);
      let result = yield call(() =>
        axios.post(`${API_URL}/backoffice/venuedata`, formData)
      );
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      if (result?.data?.statusCode === 200) {
        payload.setOpen(false);
        payload.setLoading(false);
        yield put({
          type: venueActions.SET_VENUE_DETAILS,
          payload: result?.data?.data,
        });
      }

      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getCandidateCount = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getcandidatecount/${payload?.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_CANDIDATE_COUNT,
      payload: result?.data?.statusCode === 200 ? result?.data?.data : null,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getVenueDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getvenuedetails/${payload?.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_VENUE_DETAILS,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const runSeatAllocation = function* (data) {
  const { payload } = data;
  // yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    // let result = yield call(() => axios.post(`http://localhost:5000/seatallocation/runseatallocation`, { courseid: payload?.courseid, preferenceid: payload?.preferenceid, adminuserid: payload?.adminuserid, status: 1 }))
    let result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/runseatallocation`, {
        courseid: payload?.courseid,
        preferenceid: payload?.preferenceid,
        adminuserid: payload?.adminuserid,
        status: 1,
      })
    );
    // yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      let intervalId = payload.getDataInterval(
        payload?.courseid,
        result?.data?.data
      );
      payload.setIntervalId(intervalId);
    }

    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getVenueseatAllocationDetails = function* (data) {
  // const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/venuedata/getseatallocationdetails`)
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_SEAT_ALLOCATION_DETAILS,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};
const getSeatAllocation = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getseatallocation/${payload.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_SEAT_ALLOCATION,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const getCandidateAllocationDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/venuedata/getcandidateallocationdetails/${payload.courseid}`
      )
    );
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield put({
      type: venueActions.SET_CANDIDATE_ALLOCATION_DEAILS,
      payload: result?.data?.data,
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const resetSeatAllocationDetails = function* (data) {
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/resetseatallocation`, payload)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      yield put({
        type: venueActions.GET_SEAT_ALLOCATION,
        payload: { courseid: payload?.courseid },
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const stopSeatAllocationDetails = function*(data){
  const { payload } = data;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/venuedata/stopseatallocation`, payload)
    );
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if(result?.data?.statusCode === 200){
      yield put({type:venueActions.GET_SEAT_ALLOCATION_DETAILS})
    }
 
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
}
export default venueSagas;
