import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Modal,
} from "@mui/material";
import commonActions from "../../../redux/common/actions";
import { cssProperties } from "../../../utils/commonCssProperties";
import ButtonLoader from "../../../common/buttonLoader";
import CancelButton from "../../../common/button/cancelButton";
import BluePrimaryButton from "../../../common/button/bluePrimaryButton";
import BrowseUploadButton from "../../../common/button/browseUploadButton";
import PrintDownloadButton from "../../../common/button/printDownloadButton";
// import * as XLSX from "xlsx";
import * as XLSX from "xlsx-js-style";
import masterActions from "../../../redux/master/actions";

export default function BulkUploadModal() {
  const dispatch = useDispatch();

  const { showUploadModal, buttonLoader } = useSelector(
    (state) => state.commonReducer
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: "24px",
    borderRadius: "8px",
  };

  const handleClose = () => {
    dispatch({
      type: commonActions.SET_SHOW_BULK_UPLOAD_MODAL,
      payload: { show: false, type: null, method: null, data: null },
    });
    setConvertedData(null);
    setDuplicateCount(null);
  };

  const [excelData, setExcelData] = useState(null);
  const [convertedData, setConvertedData] = useState([]);
  const [duplicateCount, setDuplicateCount] = useState(null);

  const handleFileRead = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      jsonData.shift();

      const uniqueValues = new Set();
      const duplicates = [];
      const duplicatelessData = [];

      for (const row of jsonData) {
        const value = row[0];

        if (uniqueValues.has(value)) {
          duplicates.push(value);
        } else {
          uniqueValues.add(value);
          duplicatelessData.push(row);
        }
      }
      const count = duplicates.length;
      let convertedArray = duplicatelessData.map((item) => {
        return { value: [item[0]] };
      });
      setDuplicateCount(count);
      setConvertedData(convertedArray);
      setExcelData(duplicatelessData);
    };

    reader.readAsArrayBuffer(file);
    e.target.value = null;
  };

  const handleSubmit = () => {
    // const genderfile = e.target.files[0];
    const data = {
      genderfile: excelData,
      status: 1,
      createdby: 1,
      updateby: 1,
    };
    dispatch({
      type: masterActions.BULK_UPLOAD_GENDER_MASTER,
      payload: { data: data },
    });
  };

  const handleDownloadTemplate = (name) => {
    const data = [[`${name}`]];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const headerStyle = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "FFFF00" },
      },
      font: {
        bold: true,
      },
    };
    ws["A1"].s = headerStyle;
    ws["!cols"] = [{ wch: 20 }];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "template.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Modal
        open={showUploadModal?.show && showUploadModal?.method === "U"}
        // onClose={handleClose}
        size="lg"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            variant="h5"
            sx={{
              color: `${cssProperties?.color?.primary}`,
              fontWeight: "500",
              marginBottom: "18px",
            }}
          >
            {showUploadModal?.type}
          </Typography>
          <Grid container spacing={2}>
            {showUploadModal?.type === "Gender Bulk Upload" && (
              <Grid item md={12}>
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <BrowseUploadButton
                    id="genderfile"
                    name="genderfile"
                    onChange={(e) => handleFileRead(e)}
                    title={"Upload Document"}
                  />
                  <PrintDownloadButton
                    handleSubmit={(name) => handleDownloadTemplate("Gender")}
                    name={"Download Template"}
                  />
                </Box>
                <Typography
                  mt={1}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {duplicateCount ? `Data with Duplicates : ${duplicateCount}` : null }
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    marginTop: "16px",
                    maxHeight: "10rem",
                    overflowY: "auto",
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.No</TableCell>
                        <TableCell>Gender</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {convertedData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row?.value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
              marginTop: "32px",
            }}
          >
            <CancelButton handleBack={handleClose} />
            <BluePrimaryButton
              title={buttonLoader ? <ButtonLoader /> : "Submit"}
              handleSubmit={handleSubmit}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
