import forcePushAllocationActions from "./actions";

const initialState = {
    candidateListForAllocation: {
        all: [],
        filtered: []
    },
    forcePushSelectedValues: {
        courseid: null,
        buttonType: null,
        offset: 0,
        totalcount: 0,
        districtid: null,
        venueid: null,
        showperpage: 100,
        selectall: false,
        courseexamid: null,
        exambatchid: null,
        batchno: null,
        selectallpage: []
    },
    venueListByDistrict: {
        all: [],
        filtered: []
    },
    examdateByCourse: [],
    exambatchByDate: [],
};

const forcePushAllocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION:
            return {
                ...state,
                candidateListForAllocation: action.payload
            }
        case forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES:
            return {
                ...state,
                forcePushSelectedValues: action.payload
            }
        case forcePushAllocationActions.SET_VENUE_LIST_BY_DISTRICT:
            return {
                ...state,
                venueListByDistrict: action.payload
            }
        case forcePushAllocationActions.SET_EXAM_DATES_BY_COURSEID:
            return {
                ...state,
                examdateByCourse: action.payload
            }
        case forcePushAllocationActions.SET_EXAM_BATCH__BY_EXAMDATE:
            return {
                ...state,
                exambatchByDate: action.payload
            }

        default:
            return state;
    }
};

export default forcePushAllocationReducer;