import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { cssProperties } from '../utils/commonCssProperties';
import { Typography } from '@mui/material';
import Sidebar from '../layouts/sidebar';
import Header from '../layouts/header';
import Layout from '../layouts/layout';
import PageHeading from '../layouts/pageHeading';
import Index from '../components/BackOfficeDashboard';

const BackOfficeDashboard = () => {
    const breadcrumbs = [
        
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
           Dashboard <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];
  return (
    <>
    <Sidebar />
    <Header breadcrumbs={breadcrumbs} />
    <Layout>
        <PageHeading title={'Dashboard'} />
        <Index/>
    </Layout>
</>
  )
}

export default BackOfficeDashboard
