const rollNumberActions = {
  GET_EXAM_DETAILS_WITH_COURSEID: "GET_EXAM_DETAILS_WITH_COURSEID",
  SET_EXAM_DETAILS_WITH_COURSEID: "SET_EXAM_DETAILS_WITH_COURSEID",

  GET_BATCH_CANDIDATES: "GET_BATCH_CANDIDATES",
  SET_BATCH_CANDIDATES: "SET_BATCH_CANDIDATES",

  GET_DOWNLOAD_ROLL_NO_DATA: "GET_DOWNLOAD_ROLL_NO_DATA",
  SET_DOWNLOAD_ROLL_NO_DATA: "SET_DOWNLOAD_ROLL_NO_DATA",

  GENERATE_ROLL_NUMBER: "GENERATE_ROLL_NUMBER",
  RESET_ROLL_NUMBER_GENERATION: "RESET_ROLL_NUMBER_GENERATION",
};
export default rollNumberActions;
