import React from "react";
import Index from "../components/ticketList";
import Header from "../layouts/header";
import Sidebar from "../layouts/sidebar";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";
import { Link, Typography } from "@mui/material";
import Cookies from 'js-cookie';
import { NavLink } from "react-router-dom";
import { cssProperties } from "../utils/commonCssProperties";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function TicketList() {
  
  const breadcrumbs = [
    <Link underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_mst_project')}/home`} sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.1px'
    }}>
        Dashboard
    </Link>,
    <Typography key="3" color="text.primary"
        sx={{
            display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: "bold",
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}
    >
        Ticket raised <MdKeyboardArrowDown size={16} />
    </Typography>,
];
  return (
    <>
      <Header breadcrumbs={breadcrumbs} />
      <Sidebar />
      <Layout>
        <PageHeading title={"Ticket raised"} />
        <Index /> 
      </Layout>
    </>
  );
}
