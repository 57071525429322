import courseExamAndBatchActions from './actions';

const initialState = {
    courseListByDrive: [],
};

const courseExamAndBatchReducer = (state = initialState, action) => {

    switch (action.type) {
        case courseExamAndBatchActions.SET_COURSE_BY_DRIVE:
            return {
                ...state,
                courseListByDrive: action.payload
            }

        default:
            return state
    };
};

export default courseExamAndBatchReducer;