import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Step,
  Stepper,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { MdClose } from "react-icons/md";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { useSelector } from "react-redux";

const selectTypeFields = [
  "Marital Status", "Gender", "Religion", "Region", "Mother Tongue", "Annual Family Income", "Nationality", "Select State for Examination Center",
  "Permanent State", "Permanent District", "Permanent Taluka", "Permanent Village", "Communication State",
  "Communication District", "Communication Taluka", "Communication Village", "Do you have Domicile Certificate?",
  "Do you belong to Minority Category?", "Linguistic Minority", "Religious Minority", "Are you Person with Disability (PWD)?",
  "Type of Disability", "Reserved Category",
  "Select Caste to Which you Belong", "Do you possess Caste Certificate?", "Do you possess Caste Validity?",
  "Do you have Non Creamy Layer Certificate valid up to << Due Date >>", "EWS (Applicable only for Open Category Candidates)",
  "Are you Orphan?", "Select SSC School State", "Select SSC/Equivalent Passing Year", "Select SSC/Equivalent Board",
  "Are you Appearing/Appeared for 12th HSC/Diploma exam in 2024", "Select HSC/Diploma Passing Year",
  "Select HSC/Diploma/Equivalent Board", "Marks Type", "Select Subject Group for <<Exam/Course Name>>",
  "Select Language / Medium of the Question Paper", "Confirm Language / Medium of the Question Paper", "Select Exam Center District at preference Number 1",
  "Select Exam Center District at preference Number 2", "Select Exam Center District at preference Number 3", "Select Exam Center District at preference Number 4"];

const dateTypeFields = ["DOB (DD/MM/YYYY)", "Enter DOB", "Caste Validity"];

const checkbocTypeFields = ["Same as Permanent Address"];

export default function PreviewModal({
  showModal,
  handleClose,
  coursename
}) {

  const { courseTabMaster, courseFieldMaster } = useSelector(state => state.courseReducer);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  return (
    <Dialog
      open={showModal}
      // onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle>
        {coursename}
        <br />
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      </DialogTitle>
      <DialogContent
      // sx={{
      //   height: "70vh",
      //   overflowY: "auto",
      //   "&::-webkit-scrollbar": {
      //     width: "4px",
      //   },
      //   "&::-webkit-scrollbar-thumb": {
      //     backgroundColor: "rgba(0, 0, 0, 0.2)",
      //   },
      // }}

      >
        <Tabs
          courseTabMaster={courseTabMaster?.filter((val) => { if (val?.isActive) { return val; } })}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />
        {courseTabMaster?.filter((val) => {
          if (val?.isActive) {
            return val;
          }
        })?.map((val, index) =>
          <Box key={index} sx={{ marginTop: '1rem' }}>
            {" "}
            {/* <DialogTitle sx={{ padding: 0 }}>
              Tab Name -{" "}
              <span style={{ color: "#3F41D1" }}>
                {val?.tabname}
              </span>
            </DialogTitle> */}
            {/* <Typography className="error">*Marked fields are required</Typography> */}
            <DialogContentText mt={1}>
              <Grid container spacing={2}>
                {courseFieldMaster?.filter((field) => {
                  if (field?.isSelected && field?.coursetabid === val?.coursetabid && activeTab === index) {
                    return field;
                  }
                })?.map((field, i) => (
                  <Grid item md={checkbocTypeFields?.find(cf => cf === field?.fieldname) ? 12 : 6} xs={12} key={i}>
                    {/* {field?.tabname === 'Address' ?
                      <AddressTab field={field} /> :
                      <> */}
                        {selectTypeFields?.find(sf => sf === field?.fieldname) ?
                          <FormControl fullWidth size="small">
                            <InputLabel htmlFor={field.fieldlabel}>
                              {field.fieldlabel}
                              {field?.ismandatory &&
                                <span className="error" style={{ color: "red" }}>
                                  {" "}
                                  *
                                </span>
                              }
                            </InputLabel>
                            <Select
                              id={field.fieldlabel}
                              label={
                                <span>
                                  {field.fieldlabel}
                                  {field?.ismandatory &&
                                    <span className="error" style={{ color: "red" }}>
                                      {" "}
                                      *
                                    </span>
                                  }
                                </span>
                              }
                              disabled={field?.isreadonly}
                            >
                              <MenuItem value={0}>--SELECT--</MenuItem>
                            </Select>
                          </FormControl> :
                          dateTypeFields?.find(df => df === field?.fieldname) ?
                            <TextField
                              fullWidth
                              label={
                                <span>
                                  {field.fieldlabel}
                                  {field?.ismandatory &&
                                    <span className="error" style={{ color: "red" }}>
                                      {" "}
                                      *
                                    </span>
                                  }
                                </span>
                              }
                              disabled={field?.isreadonly}
                              InputLabelProps={{
                                shrink: true
                              }}
                              size="small"
                              placeholder="Click to set date"
                              type="date"
                            /> :
                            checkbocTypeFields?.find(cf => cf === field?.fieldname) ?
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    style={{ fontSize: 18 }}
                                  />
                                }
                                label={
                                  <span className="error">
                                    Click here if the permanent and correspondence address are same
                                  </span>
                                }
                              /> :
                              <TextField
                                size="small"
                                fullWidth
                                label={
                                  <span>
                                    {field.fieldlabel}
                                    {field?.ismandatory &&
                                      <span className="error" style={{ color: "red" }}>
                                        {" "}
                                        *
                                      </span>
                                    }
                                  </span>
                                }
                                disabled={field?.isreadonly}
                              // label={field.fieldlabel}
                              // type={field.type === "dob" ? "date" : "text"}
                              // InputLabelProps={field.type === "dob" ? { shrink: true } : {}}
                              />
                        }
                      {/* </>
                    } */}
                  </Grid>
                ))}
              </Grid>
            </DialogContentText>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <BluePrimaryButton title={"Done"} handleSubmit={handleClose} />
      </DialogActions>
    </Dialog>
  );
};

function Tabs(props) {
  const { activeTab, courseTabMaster, handleTabClick } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledStepLabel = styled(StepLabel)({
    "&.MuiStepLabel-root": {
      cursor: "pointer"
      // profileRegistrationActiveTab?.cu3_status === 2 ?
      //   "pointer"
      //   : "none",
    },
  });

  return (
    <Grid
      sx={{
        height: "auto",
        backgroundColor: "#FFFFFF",
        margin: "10px 0px",
        padding: "15px 15px",
        borderRadius: "8px",
      }}
    >
      <Stepper
        activeStep={activeTab}
        orientation={isMobile ? "vertical" : "horizontal"}
      >
        {courseTabMaster.map((item, index) => (
          <Step key={index}>
            <StyledStepLabel
              sx={{ cursor: "pointer" }}
              onClick={() => handleTabClick(index)}
            // onClick={() => (item.to ? navigate(item.to) : null)}
            >
              {item.tabname}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
}
