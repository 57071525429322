import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function BluePrimaryButton({ title, type, handleSubmit,icon, disabled, fontWeight }) {

    return (
        <Button
            type={type}
            disableElevation
            variant="contained"
            sx={{
                borderRadius: '40px',
                padding: '0px 24px',
                height: '40px',
                backgroundColor: `${cssProperties?.color?.primary}`,
                fontSize: '14px',
                fontWeight: `${fontWeight ? fontWeight : '500'}`,
                lineHeight: '15.4px',
                letterSpacing: '0.32px',
                boxShadow: 'none',
                border: '1px solid transparent',
                cursor: `${disabled ? 'not-allowed' : 'pointer'}`
            }}
            onClick={handleSubmit}
            startIcon={icon}
            disabled={disabled}
        >
            {title}
        </Button>
    )
}