import { put, call, all, takeEvery, delay, select } from "redux-saga/effects";
import authActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";
import Cookies from 'js-cookie';
import setAuthToken from "../../utils/setAuthToken";

const authSagas = function* () {
    yield all([
        yield takeEvery(authActions.AUTH_LOGIN, authLogin),
        yield takeEvery(authActions.BACKOFFICE_TOKEN_VERIFY, backofficeVerifyToken),
        yield takeEvery(authActions.BACKOFFICE_FORGET_PASSWORD, backofficeForgetPassword),
        yield takeEvery(authActions.ROLE_TOKEN_GENERATION, roleTokenGeneration),
    ])
};

const authLogin = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/login`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate(`/${Cookies.get('mhet_mst_project')}/role-selection`);
            Cookies.set('mhet_mst_temptoken', result?.data?.token, { expires: 7 })
            // navigate(`/${Cookies.get('mhet_mst_project')}/home`);
            // Cookies.set('mhet_mst_token', result?.data?.token, { expires: 7 });
            // setAuthToken(result?.data?.token);
            yield put({ type: authActions.BACKOFFICE_TOKEN_VERIFY });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
        Cookies.remove('mhet_mst_token');
        window.location.href = `/${Cookies.get('mhet_mst_project')}/userlogin`;
    };
};
const backofficeForgetPassword = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/reset-password`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate(`/${Cookies.get('mhet_mst_project')}/home`);
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
        Cookies.remove('mhet_mst_token');
        window.location.href = `/${Cookies.get('mhet_mst_project')}/userlogin`;
    };
};

const backofficeVerifyToken = function* () {

    try {
        const { backofficeTokenDetails } = yield select(state => state.authReducer);
        if (!backofficeTokenDetails) {
            const result = yield call(() =>
                axios.get(`${API_URL}/backoffice/verify-token`, {
                    headers: {
                        authorization: `Bearer ${Cookies.get('mhet_mst_temptoken') || Cookies.get('mhet_mst_token')}`
                    }
                })
            );
            if (result?.data?.statusCode === 200) {
                yield put({ type: authActions.SET_BACKOFFICE_TOKEN_DETAILS, payload: result?.data?.result });
            } else {
                Cookies.remove('mhet_mst_token');
                window.location.href = `/${Cookies.get('mhet_mst_project')}/userlogin`;
            };
        }
    } catch (err) {
        console.log(err);
        Cookies.remove('mhet_mst_token');
        window.location.href = `/${Cookies.get('mhet_mst_project')}/userlogin`;
    };
}

const roleTokenGeneration = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        let data = {
            role: payload?.role,
            token: Cookies.get('mhet_mst_temptoken')
        };
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/role-token`, data)
        );
        if(result?.data?.statusCode === 200){
            Cookies.remove('mhet_mst_temptoken');
            navigate(`/${Cookies.get('mhet_mst_project')}/home`);
            Cookies.set('mhet_mst_token', result?.data?.token, { expires: 7 });
            setAuthToken(result?.data?.token);
            window.location.reload();
        };
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

export default authSagas;