export const cssProperties = {
    drawerWidth: 85,
    headerHight: 80,
    fontcolor: {
        primary: 'rgba(33, 33, 33, 1)',
        tableblack: 'rgba(26, 26, 26, 1)'
    },
    backgroundcolor: {
        primary: 'rgba(247, 247, 247, 1)',
        secondary: 'rgba(255, 255, 255, 1)' //#fff
    },
    bordercolor: {
        primary: 'rgba(163, 163, 163, 1)', //#A3A3A3
        secondary: 'rgba(218, 218, 218, 1)',
        secondary3: 'rgba(189, 189, 189, 1)',
        primary2blue: 'rgba(75, 77, 212, 1)', //#4b4dd4
        primary4blue: 'rgba(207, 213, 241, 1)',
        primary3blue: 'rgba(123, 127, 222, 1)', //#7b7fde
    },
    color: {
        primary: 'rgba(63, 65, 209, 1)',
        gray: 'rgba(163, 163, 163, 1)',
        neutralgrey: 'rgba(51, 65, 85, 1)',
        textsecondary: 'rgba(102, 102, 102, 1)', //#666666
        failure: 'rgba(230, 57, 63, 1)', //#e6393f
        success: 'rgba(6, 194, 113, 1)' //#06c271
    },
    hoverfontcolor: {
        sidebar: 'rgba(63, 65, 209, 1)',
    },
    hoverbgcolor: {
        sidebar: 'rgba(225, 232, 245, 1)',
    },
    buttonbgcolor: {
        primary: 'rgba(230, 151, 171, 1)',
        back: 'rgba(224, 224, 224, 1)' //#e0e0e0
    },
    buttonfontcolor: {
        black: 'rgba(49, 0, 0, 1)'
    },
    surface: {
        primary: 'rgba(240, 242, 244, 1)' //#f0f2f4
    }
};