import React from "react";
import { Button } from "@mui/material";

export default function YesNoButton({ name, type, disabled, handleSubmit }) {
  return (
    <Button
      variant="contained"
      sx={{
        boxShadow: "none",
        whiteSpace: "nowrap",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "15.4px",
        borderRadius: "40px",
        padding: "10px 24px",
        boxShadow: "none",
        backgroundColor: type === "Yes" ? "#E697AB" : "#F0F3F7",
        color: type === "Yes" ? "black" : "#3F41D1",
        minWidth: "3rem",
        height: "40px",
        textAlign: "center",
        border: type === "No" ? "1px solid #3F41D1" : "none",
        "&:hover": {
          backgroundColor: name === "Yes" ? "#E697AB" : "#F0F3F7",
          boxShadow: "none",
        },
      }}
      onClick={handleSubmit}
      disabled={disabled || false}
    >
      {name}
    </Button>
  );
}
