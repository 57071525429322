import { AppBar, Box, Toolbar } from "@mui/material";
import React from "react";
import { cssProperties } from "../utils/commonCssProperties";
// import { MdNavigateNext } from "react-icons/md";
import Logo2 from '../assets/images/navbar/image 9.png';

export default function BeforeLoginHeader() {

    // const { breadcrumbs } = props;

    return (
        <AppBar
            position="fixed"
            sx={{
                border: 'none',
                boxShadow: 'none',
                backgroundColor: "#F7F7F7",
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <Box sx={{ width: `${cssProperties?.drawerWidth}px` }}>
                        <img style={{ width: "40px", height: "40px" }} src={Logo2} alt="logo" />
                    </Box>
                    {/* <Stack spacing={2}>
                        <Breadcrumbs
                            separator={<MdNavigateNext size={16} />}
                            aria-label="breadcrumb"
                        >
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack> */}
                </Box>
            </Toolbar>
        </AppBar>
    )
}