import React from "react";
import Index from "../components/superAdminLogin/index";
import BeforeLoginHeader from "../layouts/beforeLoginHeader";
import { Typography } from "@mui/material";
import { MdKeyboardArrowRight } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";

export default function SuperAdminLogin() {

    const breadcrumbs = [
        <Typography key="1" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Super Admin Login <MdKeyboardArrowRight size={16} />
        </Typography>
    ];

    return (
        <>
            <BeforeLoginHeader breadcrumbs={breadcrumbs} />
            <Index />
        </>
    )
}