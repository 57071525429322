import React, { useEffect, useRef } from "react";
import Sidebar from "../../layouts/sidebar";
import Header from "../../layouts/header";
import Layout from "../../layouts/layout";
import {
  Link as MuiLink,
  Grid,
  MenuItem,
  Select,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { MdKeyboardArrowDown } from "react-icons/md";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import CommonTable from "../../common/CommonTable";
import DangerButton from "../../common/button/DangerButton";
import { PiEye, PiPencilSimpleBold, PiStop } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import venueActions from "../../redux/venue/actions";
import { io } from "socket.io-client";
import { API_URL } from "../../utils/constants";

const AllocateVenueDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  // const socket = io(`http://localhost:3001`);
  const getData = () => {
    socketRef?.current?.emit("getprogress", { courseid: 0 });
  };
  const { seatAllocationDetails } = useSelector((state) => state.venueReducer);
  // console.log(seatAllocationDetails, 'sad')
  const breadcrumbs = [
    <MuiLink
      underline="hover"
      key="1"
      component={NavLink}
      to={`/${Cookies.get("mhet_mst_project")}/home`}
      sx={{
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Dashboard
    </MuiLink>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "12px",
        color: cssProperties?.fontcolor?.primary,
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.1px",
      }}
    >
      Allocate Venue <MdKeyboardArrowDown size={16} />
    </Typography>,
  ];
  const columnData = [
    {
      name: "Course Name",
      dataIndex: "coursename",
    },
    {
      name: "Preference",
      dataIndex: "preference",
    },
    {
      name: "Venue Capacity",
      dataIndex: "venuecapacity",
    },
    {
      name: "Total Candidates",
      dataIndex: "totalcandidate",
    },
    {
      name: "Allocated Candidates",
      dataIndex: "allocatedcandidates",
    },
    {
      name: "Pending Candidates",
      dataIndex: "nonallocatedcandidates",
      render: (val, row) => row.totalcandidate - row.allocatedcandidates,
    },

    {
      name: "Status",
      dataIndex: "statustext",
    },
    {
      name: "Action",
      dataIndex: "statustext",
      render: (val, row) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            borderColor: `${cssProperties?.bordercolor?.primary}`,
            borderWidth: "1px",
            // borderRadius: 2,

            // backgroundColor: 'rgba(243, 243, 243, 1)',
            borderRadius: "6px 0",
            padding: "4px 7px",
            "& svg": {
              m: 1,
            },
          }}
        >
          {val?.toLowerCase() === "processing" && (
            <>
              <PiStop
                size={24}
                style={{
                  marginRight: "7px",
                  color: `${cssProperties?.bordercolor?.primary}`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch({
                    type: venueActions.STOP_SEAT_ALLOCATION,
                    payload: {
                      venueseatallocationid: row?.venueseatallocationid,
                    },
                  });
                }}
              />
              <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ mt: "0", mb: "0" }}
              />
            </>
          )}
          {val?.toLowerCase() !== "cancelled" && (
            <PiEye
              size={24}
              style={{
                marginRight: "7px",
                color: `${cssProperties?.bordercolor?.primary}`,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/${Cookies.get("mhet_mst_project")}/allocate-venue`, {
                  state: { id: row?.courseid },
                });
              }}
            />
          )}
        </Box>
      ),
    },
  ];
  // const tableData = [
  //     {
  //         courseid: 1,
  //         coursename: 'MHCET 2024',
  //         totalcandidates: 3000,
  //         allocatedcandidates: 1246,
  //         nonallocatedcandidates: 1754,
  //         seats: 1000,
  //         status: 'In Progress',
  //         preference: 'Overall',

  //     },
  //     {
  //         courseid: 2,
  //         coursename: 'MHCET 2022',
  //         totalcandidates: 3000,
  //         allocatedcandidates: 1754,
  //         nonallocatedcandidates: 1246,
  //         seats: 1000,
  //         status: 'In Progress',
  //         preference: 'Prference 1',

  //     },
  //     {
  //         courseid: 30,
  //         coursename: 'MHCET 2023',
  //         totalcandidates: 3000,
  //         allocatedcandidates: 3000,
  //         nonallocatedcandidates: 0,
  //         seats: 1000,
  //         status: 'Completed',
  //         preference: 'Prference 1',

  //     },
  // ]
  useEffect(() => {
    socketRef.current = io(API_URL);
    const getDataInterval = setInterval(() => {
      getData();
    }, 30000);
    socketRef?.current?.on("message", (message) => {
      // console.log(message, 'mess')
      dispatch({
        type: venueActions.SET_SEAT_ALLOCATION_DETAILS,
        payload: message.data,
      });
    });
    dispatch({ type: venueActions.GET_SEAT_ALLOCATION_DETAILS });
    return () => {
      clearInterval(getDataInterval);
    };
  }, []);
  return (
    <>
      <Sidebar />
      <Header breadcrumbs={breadcrumbs} />
      <Layout>
        <Box display="flex" justifyContent="flex-end">
          <BluePrimaryButton
            title="Allocate Venue"
            handleSubmit={() =>
              navigate(`/${Cookies.get("mhet_mst_project")}/allocate-venue`)
            }
          />
        </Box>
        <Box mt={2}>
          <CommonTable
            columnData={columnData}
            tableData={seatAllocationDetails}
          />
        </Box>
      </Layout>
    </>
  );
};

export default AllocateVenueDashboard;
