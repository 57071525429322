import { put, call, all, takeEvery, delay, select } from "redux-saga/effects";
import usersActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";
import Cookies from "js-cookie";

const usersSagas = function* () {
    yield all([
        yield takeEvery(usersActions.GET_ADMINUSER_LIST, getAdminUserList),
        yield takeEvery(usersActions.GET_ADMINUSER_DETAILS, getAdminUserDetails),
        yield takeEvery(usersActions.GET_ROLE_LIST, getRoleList),
        yield takeEvery(usersActions.CREATE_ADMINUSER, createAdminUser),
        yield takeEvery(usersActions.UPDATE_ADMINUSER, updateAdminUser),
        yield takeEvery(usersActions.DELETE_ADMINUSER, deleteAdminUser),
    ])
};

const getAdminUserList = function* () {

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/adminuser`)
        );
        const { backofficeTokenDetails } = yield select(state => state.authReducer);
        if (backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator')) {
            //eslint-disable-next-line
            const listByRole = result?.data?.result?.filter(val => {
                let role = val?.rolenames?.split(",");
                if (role?.find(r => r === 'Grievance Resolution')) {
                    return val
                }
            })
            yield put({
                type: usersActions.SET_ADMINUSER_BASEDON_ROLE,
                payload: { all: listByRole, filtered: listByRole }
            });
        } else {
            //eslint-disable-next-line
            const listByRole = result?.data?.result?.filter(val => {
                let role = val?.rolenames?.split(",");
                if (role?.find(r => r === 'Grievance Resolution') && val?.userid !== backofficeTokenDetails?.id) {
                    return val
                }
            })
            yield put({
                type: usersActions.SET_ADMINUSER_BASEDON_ROLE,
                payload: { all: listByRole, filtered: listByRole }
            });
        }
        let finalResult = result?.data?.result?.map((val, i) => {
            return { ...val, id: i + 1 }
        })
        yield put({
            type: usersActions.SET_ADMINUSER_LIST,
            payload: { all: (result?.data?.statusCode === 200) ? finalResult : [], filtered: (result?.data?.statusCode === 200) ? finalResult : [] }
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getAdminUserDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/adminuser/${payload}`)
        );
        yield put({
            type: usersActions.SET_ADMINUSER_DETAILS,
            payload: (result?.data?.statusCode === 200) ? result?.data?.result : null
        });
    } catch (err) {
        console.log(err);
    };
};

const getRoleList = function* () {

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/role`)
        );
        yield put({
            type: usersActions.SET_ROLE_LIST,
            payload: { all: (result?.data?.statusCode === 200) ? result?.data?.result : [], filtered: (result?.data?.statusCode === 200) ? result?.data?.result : [] }
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const createAdminUser = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/adminuser`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate(`/${Cookies.get('mhet_mst_project')}/users`);
            yield put({ type: usersActions.GET_ADMINUSER_LIST });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
};

const updateAdminUser = function* (data) {
    const { payload } = data;
    const { navigate } = payload;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.put(`${API_URL}/backoffice/adminuser/${payload?.id}`, payload?.data)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            navigate(`/${Cookies.get('mhet_mst_project')}/users`);
            yield put({ type: usersActions.GET_ADMINUSER_LIST });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
};

const deleteAdminUser = function* (data) {
    const { payload } = data;

    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
    try {
        const result = yield call(() =>
            axios.delete(`${API_URL}/backoffice/adminuser/${payload?.userid}/${payload?.updatedby}`)
        );
        yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? 'success' : 'failed',
                show: true,
                message: result?.data?.message
            }
        });
        if (result?.data?.statusCode === 200) {
            yield put({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } });
            yield put({ type: usersActions.GET_ADMINUSER_LIST });
        };
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null }
        });
    } catch (err) {
        console.log(err);
    };
};

export default usersSagas;