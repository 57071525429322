import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useDispatch, useSelector } from "react-redux";
import ticketsActions from "../../redux/tickets/actions";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

Chart.register(ChartDataLabels);

export default function Index() {

  const dispatch = useDispatch();

  const [monthly, setMonthly] = useState(0);
  const [weekly, setWeekly] = useState(0);

  let [totalTicketsDate, setTotalTicketsDate] = useState({
    date: null,
    count: null,
  });

  const { ticketStatusCount, TotalTicketCountData, averageResponseTime } =
    useSelector((state) => state.ticketsReducer);

  const handleDateChange = (event) => {
    const selectedDate = TotalTicketCountData.dayTotal.find(
      (val) => val.date === event.target.value
    );
    setTotalTicketsDate(selectedDate || {});
  };

  useEffect(() => {
    dispatch({ type: ticketsActions.GET_TICKETSTATUSCOUNT });
    dispatch({ type: ticketsActions.GET_TOTAL_TICKET_COUNT });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setMonthly(30);
    dispatch({ type: ticketsActions.GET_TOTAL_TICKET_COUNT, payload: 30 });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      type: ticketsActions.GET_AVERAGE_RESPONSETIME_COUNT,
      payload: 0,
    });
    //eslint-disable-next-line
  }, []);

  const handleCheckMonthly = (event) => {
    const isChecked = event.target.checked;
    const newMonthlyValue = isChecked ? 30 : 0;
    setTotalTicketsDate({ date: null, count: null });
    setMonthly(newMonthlyValue);
    dispatch({
      type: ticketsActions.GET_TOTAL_TICKET_COUNT,
      payload: newMonthlyValue,
    });
    if (isChecked) {
      setWeekly(false);
    }
  };

  const handleCheckWeekly = (event) => {
    const isChecked = event.target.checked;
    const newWeekValue = isChecked ? 7 : 0;
    setTotalTicketsDate({ date: null, count: null });
    setWeekly(newWeekValue);
    dispatch({
      type: ticketsActions.GET_TOTAL_TICKET_COUNT,
      payload: newWeekValue,
    });
    if (isChecked) {
      setMonthly(false);
    }
  };
  const [selectedValue, setSelectedValue] = useState(0);

  const handleItemClick = (value) => {
    setSelectedValue(value);
    dispatch({
      type: ticketsActions.GET_AVERAGE_RESPONSETIME_COUNT,
      payload: value,
    });
  };

  const dropdownItems = [
    { label: "Today", value: 0 },
    { label: "Yesterday", value: -1 },
    { label: "Last 7 days", value: -7 },
    { label: "Last 30 days", value: -30 },
  ];

  const ticketLabels = ticketStatusCount?.ticketStatus?.map(
    (data) => data.label
  );
  const ticketValues = ticketStatusCount?.ticketStatus?.map(
    (data) => data.value
  );

  const categoryLabels = ticketStatusCount?.categoryWiseTicketStatus
  ?.filter(data => Object.entries(data).some(([key, value]) => key !== 'label' && value !== "0"))
  .map(data => data.label);



console.log('categoryLabels',categoryLabels);
console.log('eeeeeeeeeeeeee',ticketStatusCount?.categoryWiseTicketStatus);
  
const categoryValues1 = ticketStatusCount?.categoryWiseTicketStatus
?.map(data => data.value1)
.filter(value => value !== "0");

const categoryValues2 = ticketStatusCount?.categoryWiseTicketStatus
  ?.map(data => data.value2)
  .filter(value => value !== "0");

const categoryValues3 = ticketStatusCount?.categoryWiseTicketStatus
  ?.map(data => data.value3)
  .filter(value => value !== "0");

const categoryValues4 = ticketStatusCount?.categoryWiseTicketStatus
  ?.map(data => data.value4)
  .filter(value => value !== "0");

console.log(categoryValues1);
console.log(categoryValues2);
console.log(categoryValues3);
console.log(categoryValues4);


  const ticketData = {
    labels: ticketLabels,
    datasets: [
      {
        backgroundColor: "#00777D",
        borderColor: "rgb(255, 99, 132)",
        data: ticketValues ? ticketValues : [],
        barThickness: 35,
        pointStyle: "circle",
        borderRadius: 10,

      },
    ],
  };

  const categoryData = {
    labels: categoryLabels,
    datasets: [
      {
        label: "Total tickets",
        backgroundColor: "#D73E34",
        borderColor: "#fff",
        data: categoryValues1,
        barThickness: 40,
        borderWidth: 4,
        pointStyle: "circle",
        borderRadius: 10,
      },
      {
        label: "Total resolved",
        backgroundColor: "#3461D7",
        borderColor: "#fff",
        data: categoryValues2,
        barThickness: 40,
        borderWidth: 4,
        pointStyle: "circle",
        borderRadius: 10,
      },
      {
        label: "Tickets yet to resolve",
        backgroundColor: "#34D7CD",
        borderColor: "#fff",
        data: categoryValues3,
        barThickness: 40,
        borderWidth: 4,
        pointStyle: "circle",
        borderRadius: 10,
      },
      {
        label: "Tickets yet to assign",
        backgroundColor: "#34B0D7",
        borderColor: "#fff",
        data: categoryValues4,
        barThickness: 40,
        borderWidth: 4,
        pointStyle: "circle",
        borderRadius: 10,
      },
    ],
  };

  const ticketOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: Math.max(...(ticketValues ? ticketValues : [1])) + 2,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        offset: 1,
      },
    },
  };

  const getMaxValue = () => {
    const values = ticketStatusCount?.categoryWiseTicketStatus?.flatMap(
      (item) => [
        Number(item.value1),
        Number(item.value2),
        Number(item.value3),
        Number(item.value4),
      ]
    ) || [1];
    return Math.max(...values) + 2;
  };

  const CategoryOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 0,
        max: getMaxValue(),
        ticks: {
          callback: function (value) {
            return value;
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        color: "#000",
        anchor: "end",
        align: "top",
        offset: 1,
      },
    },
  };

  let allData = [];

  if (
    TotalTicketCountData &&
    TotalTicketCountData.result &&
    TotalTicketCountData.result.datasets
  ) {
    allData = TotalTicketCountData.result.datasets.flatMap(
      (dataset) => dataset.data
    );
  }

  const min = allData.length > 0 ? Math.min(...allData) : 0;
  const max = allData.length > 0 ? Math.max(...allData) : 10;

  const padding = 2;
  const minValue = min - padding;
  const maxValue = max + padding;

  const options = {
    scales: {
      y: {
        min: minValue,
        max: maxValue,
        ticks: {
          stepSize: 2,
          display: true,
        },
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        offset: true,
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 2,
    width: 800,
  };

  return (
    <Grid>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12}>
          <Box
            p={2}
            style={{
              minHeight: "52vh",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Ticket Status
            </Typography>
            <Box pt={1} style={{ height: "40vh" }}>
              <Bar data={ticketData} options={ticketOptions} />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <Box
            p={2}
            style={{
              minHeight: "52vh",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Category Wise
            </Typography>
            <Box
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "8px",
              }}
              pt={1}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: "#D73E34",
                    borderRadius: "50%",
                  }}
                ></Box>
                <Box>
                  <Typography
                    variant="caption"
                    style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                  >
                    Tickets
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: "#3461D7",
                    borderRadius: "50%",
                  }}
                ></Box>
                <Box>
                  <Typography
                    variant="caption"
                    style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                  >
                    Resolved
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: "#34D7CD",
                    borderRadius: "50%",
                  }}
                ></Box>
                <Box>
                  <Typography
                    variant="caption"
                    style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                  >
                    Yet to resolve
                  </Typography>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box
                  style={{
                    height: "12px",
                    width: "12px",
                    backgroundColor: "#00b8f0",
                    // backgroundColor: "#34D7CD",
                    borderRadius: "50%",
                  }}
                ></Box>
                <Box>
                  <Typography
                    variant="caption"
                    style={{ margin: 0, fontSize: 13, wordWrap: "break-word" }}
                  >
                    Yet to assign
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={{ height: "35vh" }}>
              <Bar data={categoryData} options={CategoryOptions} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container pt={2} spacing={2}>
        <Grid item lg={8} md={8} sm={12}>
          <Box
            p={2}
            style={{
              minHeight: "50vh",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Total Tickets
              </Typography>
              <Box
                style={{ display: "flex", gap: "12px", alignItems: "center" }}
              >
                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <Checkbox
                    checked={monthly === 30}
                    value={monthly}
                    size="small"
                    sx={{ padding: 0, margin: 0 }}
                    onChange={handleCheckMonthly}
                  />
                  <Typography variant="subtitle1">Monthly</Typography>
                </Box>

                <Box sx={{ display: "flex", alignContent: "center" }}>
                  <Checkbox
                    checked={weekly === 7}
                    value={weekly}
                    size="small"
                    sx={{ margin: 0, padding: 0 }}
                    onChange={handleCheckWeekly}
                  />
                  <Typography variant="subtitle1">Weekly</Typography>
                </Box>
                {TotalTicketCountData?.dayTotal?.length > 0 && (
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      sx={{ height: "35px" }}
                      value={totalTicketsDate?.date ? totalTicketsDate?.date : TotalTicketCountData?.dayTotal[0]?.date ? TotalTicketCountData?.dayTotal[0]?.date : ''}
                      onChange={handleDateChange}
                      displayEmpty
                    >
                      {/* <MenuItem value="" disabled>
                        {"Select a date"}
                      </MenuItem> */}
                      {TotalTicketCountData.dayTotal.map((val, i) => (
                        <MenuItem key={i} value={val.date}>
                          {val.date}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            </Box>
            <Box className="canvas-container-curve-chart">
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="h4"
                    style={{ margin: 0 }}
                    className="error"
                  >
                    {totalTicketsDate?.count ||
                      (TotalTicketCountData?.dayTotal?.length > 0 &&
                        TotalTicketCountData?.dayTotal[0]?.count)}{" "}
                    <span style={{ fontSize: 18 }}>
                      (
                      {totalTicketsDate?.date ||
                        (TotalTicketCountData?.dayTotal?.length > 0 &&
                          TotalTicketCountData?.dayTotal[0]?.date)}
                      )
                    </span>
                  </Typography>
                </Box>
                <Box
                  style={{ display: "flex", alignItems: "center", gap: "15px" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Box
                      style={{
                        height: "12px",
                        width: "12px",
                        backgroundColor: "#D73E34",
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box>
                      <Typography variant="caption" style={{ margin: 0 }}>
                        By self
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Box
                      style={{
                        height: "12px",
                        width: "12px",
                        backgroundColor: "#3461D7",
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box>
                      <Typography variant="caption" style={{ margin: 0 }}>
                        By call
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Box
                      style={{
                        height: "12px",
                        width: "12px",
                        backgroundColor: "#34D7CD",
                        borderRadius: "50%",
                      }}
                    ></Box>
                    <Box>
                      <Typography variant="caption" style={{ margin: 0 }}>
                        By mail
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {ticketStatusCount &&
                TotalTicketCountData &&
                Array.isArray(TotalTicketCountData.datasets) &&
                TotalTicketCountData.datasets.length > 0 ? (
                <Line data={TotalTicketCountData} options={options} />
              ) : (
                <Box>Loading...</Box>
              )}{" "}
            </Box>
          </Box>
        </Grid>
        <Grid item lg={4} md={8} sm={12}>
          <Box
            p={2}
            style={{
              minHeight: "50vh",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontWeight: "bold" }} variant="h6">
                Average Tickets
                <br /> Reply Time
              </Typography>
              <Box>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    sx={{ height: "35px" }}
                    value={selectedValue}
                    onChange={(e) => handleItemClick(e.target.value)}
                    displayEmpty
                  >
                    {/* <MenuItem value="" disabled>
                      {"Select"}
                    </MenuItem> */}
                    {dropdownItems.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            <Box>
              {averageResponseTime.map((item, index) => (
                <Box key={index} display="flex" flexDirection="column" mb={2}>
                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                      <span style={{ fontSize: "14px" }}>{item.label}</span>
                    </Box>
                    <Box ml={1} mr={1}>
                      <span style={{ fontSize: "14px" }}>{item.value}</span>
                    </Box>
                  </Box>
                  <Box minWidth={200} mt={1}>
                    <LinearProgress
                      sx={{ height: 18 }}
                      variant="determinate"
                      value={item.minutes}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
