import moment from "moment-timezone";
import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function TicketComments({ comments, handleCommentsChange }) {
  const scrollRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      scrollRef.current.scroll({
        top: 0,
        behavior: "smooth"
      });
      // if (scrollRef.current) {
      //   scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      // }
    };
    scrollToBottom();
  }, [comments]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        // p={1}
        ref={scrollRef}
        style={{
          height: "8rem",
          flexGrow: 1,
          overflowY: "auto",
          paddingBottom: 2,
          border: '1px solid #bdbdbd',
          borderRadius: '4px',
          padding: '8px'
        }}
      >
        {comments?.map((val, i) => (
          <Box key={i} sx={{ backgroundColor: "grey.200", padding: 2, borderRadius: 2, mb: 2 }}>
            <Box >
              {val?.response}
            </Box>
            <Box sx={{ fontSize: 12, color: "text.secondary", lineHeight: "1.5rem" }}>
              Posted by: {val?.responseusername}
            </Box>
            <Box sx={{ fontSize: 12, color: "text.secondary" }}>
              Posted on: {moment(val?.createddate).format("DD-MM-YYYY")}
            </Box>
          </Box>
        ))}
      </div>
      {/* <TextField
        multiline
        rows={2}

        placeholder="Enter the comments"
        onChange={handleCommentsChange}
        sx={{ marginTop: 2 }}
      /> */}
    </Box>
  );
}