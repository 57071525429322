import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Card,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Modal,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DangerButton from "../../common/button/DangerButton";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import ViewButton from "../../common/button/viewButton";
import { useForm } from "react-hook-form";
import { cssProperties } from "../../utils/commonCssProperties";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import commonActions from "../../redux/common/actions";
import rollNumberActions from "../../redux/rollNumber/actions";
import { NumbersValidation } from "../../utils/validation";
import { io } from "socket.io-client";
import { API_URL } from "../../utils/constants";
import xlsx from "json-as-xlsx";

let settings = {
  fileName: "Candidate Roll Number Data",
  extraLength: 3,
  writeMode: "writeFile",
  writeOptions: {},
  RTL: false,
};

let callback = function (sheet) {
  // console.log("Download complete:", sheet);
};

const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const socketRef = useRef(null);
  const [courseid, setCourseId] = useState(0);
  const [batchid, setBatchId] = useState(0);
  const [isContiniousNumber, setIsContiniousNumber] = useState(0);

  const [courseExamId, setCourseExamId] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.masterReducer);
  const { examDetails, batchCandidates, rollNoDownloadData } = useSelector(
    (state) => state.rollNumberReducer
  );
  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);
  const [statusModalopen, setStatusModalOpen] = useState({
    open: false,
    statustext: "",
  });
  let getDataInterval = (courseid, currentRollNoGenerationId) => {
    let intervalId = setInterval(() => {
      socketRef?.current?.emit("listenrollnostatus", {
        courseid,
        currentRollNoGenerationId,
      });
    }, 5000);
    return intervalId;
  };

  const handleDownload = async (rollnodata) => {
    console.log(rollnodata, "data for download");
    if (rollnodata && rollnodata.length > 0) {
      let columns = [];

      for (let i = 0; i < Object.keys(rollnodata[0]).length; i++) {
        let obj = {};
        obj.label = Object.keys(rollnodata[0])[i];
        obj.value = Object.keys(rollnodata[0])[i];
        columns.push(obj);
      }

      let data = [
        {
          sheet: "Candidate Roll Number Details",
          columns: columns,
          content: rollnodata,
        },
      ];
      console.log(columns, data);
      xlsx(data, settings, callback);
    } else {
      console.log("No data available to download");
    }
  };

  useEffect(() => {
    socketRef.current = io(`${API_URL}`);
    socketRef?.current?.on("listenrollnomessage", (message) => {
      //   console.log(message.data, 'mess', courseid)
      // let data = message?.data
      if (message?.statusCode === 200) {
        let statustext = message?.data?.trim();
        // console.log(statustext, 'stt',statustext?.toLowerCase())
        if (statustext) {
          if (statustext?.toLowerCase() !== "completed") {
            //   console.log("not completed");
            setStatusModalOpen({
              open: true,
              statustext:
                statustext?.trim() !== "" && statustext !== null
                  ? statustext
                  : statusModalopen.statustext,
            });
          } else {
            //   console.log("completed");
            setStatusModalOpen({
              open: false,
              statustext:
                statustext?.toLowerCase() !== "completed"
                  ? ""
                  : statusModalopen.statustext,
            });
            clearInterval(intervalId);
            dispatch({
              type: rollNumberActions.GET_DOWNLOAD_ROLL_NO_DATA,
              payload: {
                courseid,
                batchid,
              },
            });
            dispatch({
              type: commonActions.SET_ALERT,
              payload: {
                status: "success",
                show: true,
                message: "roll number generated successfully",
              },
            });

            setTimeout(function () {
              dispatch({
                type: commonActions.SET_ALERT,
                payload: {
                  status: "success",
                  show: false,
                  message: null,
                },
              });
            }, 2000);
            // statustext?.toLowerCase() !== "completed" &&
            //   dispatch({
            //     type: venueActions.GET_SEAT_ALLOCATION,
            //     payload: { courseid },
            //   });
          }
        }
        //  else {
        //   setStatusModalOpen({
        //     open: false,
        //     statustext: "",
        //   });
        // }
      }
    });
    return () => (socketRef.current = null);
  }, [courseid, batchid, intervalId, dispatch, socketRef]);

  const onSubmit = (data) => {
    if (courseid && batchid && courseExamId) {
      dispatch({
        type: rollNumberActions.GENERATE_ROLL_NUMBER,
        payload: {
          courseid: courseid,
          batchid: batchid,
          sequencelength: data.nolength,
          iscontiniousnumber: isContiniousNumber,
          examid: courseExamId,
          userid: backofficeTokenDetails?.id,
          getDataInterval: getDataInterval,
          setIntervalId: setIntervalId,
        },
      });
      setStatusModalOpen({
        open: true,
        statustext: "Processing...",
      });
    }
  };
  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, [dispatch]);

  const onChangeCourse = async (e) => {
    // console.log("change course", e);
    setCourseId(e.target.value);
    dispatch({
      type: rollNumberActions.GET_EXAM_DETAILS_WITH_COURSEID,
      payload: { courseid: e.target.value },
    });
  };
  const onSelect = () => {
    if (courseid && batchid && courseExamId) {
      dispatch({
        type: rollNumberActions.GET_BATCH_CANDIDATES,
        payload: { courseid: courseid, batchid: batchid },
      });
    }
  };
  const onReset = () => {
    courseid &&
      dispatch({
        type: rollNumberActions.RESET_ROLL_NUMBER_GENERATION,
        payload: { courseid, batchid, userid: backofficeTokenDetails?.id },
      });
  };
  console.log(rollNoDownloadData, "rnd");
  return (
    <>
      <form>
        <Grid container paddingBottom={2}>
          <Grid item md={7} sx={{ display: "flex" }}>
            <Box
              sx={{ display: "flex", gap: 1, width: "100%", flexWrap: "wrap" }}
            >
              <Select
                size="small"
                onChange={onChangeCourse}
                value={courseid}
                defaultValue={0}
                sx={{ width: "100%", maxWidth: "200px" }}
              >
                <MenuItem value={0} selected>
                  Courses
                </MenuItem>
                {courseList?.all?.map((course, i) => (
                  <MenuItem key={i} value={course.courseid}>
                    {course.coursename}
                  </MenuItem>
                ))}
              </Select>
              <Select
                size="small"
                onChange={(e) => setCourseExamId(e.target.value)}
                value={courseExamId}
                defaultValue={0}
                sx={{ width: "100%", maxWidth: "200px" }}
              >
                <MenuItem value={0} selected>
                  Select Date
                </MenuItem>
                {examDetails?.dates?.map((date, i) => (
                  <MenuItem key={i} value={date.courseexamid}>
                    {date.examdate}
                  </MenuItem>
                ))}
              </Select>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer sx={{ padding: 0 }} components={["DatePicker"]}>
                <DatePicker
                  format="DD MMMM YYYY"
                  defaultValue={dayjs("2001-04-12")}
                  slotProps={{ textField: { size: "small" } }}
                />
              </DemoContainer>
            </LocalizationProvider> */}
              <Select
                size="small"
                onChange={(e) => setBatchId(e.target.value)}
                value={batchid}
                defaultValue={0}
                sx={{ width: "100%", maxWidth: 150 }}
              >
                <MenuItem value={0}>Select Batch</MenuItem>
                {examDetails?.batches?.map((batch, i) => (
                  <MenuItem key={i} value={batch.exambatchid}>
                    {batch.batchname}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item md={5} textAlign="end">
            <DangerButton title="Select" handleSubmit={onSelect} />
          </Grid>
        </Grid>

        <Card
          sx={{
            width: "100%",
            textAlign: "start",
            borderRadius: "8px",
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            boxShadow: "none",
            padding: 2,
            background: "#fff",
          }}
        >
          <Box sx={{ textAlign: "start" }}>
            <Grid container mb={1}>
              <Grid item md={2} sm={4}>
                <Card
                  sx={{
                    height: "100%",
                    width: "100%",
                    textAlign: "center",
                    borderRadius: "8px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "none",
                    padding: 2,
                    border: `1px solid ${cssProperties.bordercolor.secondary}`,
                  }}
                >
                  <Typography
                    variant="p"
                    color={cssProperties.color.failure}
                    fontWeight={600}
                    sx={{ paddingBottom: 2 }}
                    component="p"
                  >
                    {batchCandidates?.length || 0}
                  </Typography>
                  <Typography
                    variant="p"
                    color="#212121"
                    fontWeight={600}
                    sx={{ paddingBottom: 2 }}
                    component="p"
                  >
                    {"Candidates"}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
            {/* <Box>
            <Typography variant="body1" fontWeight={600} paddingBottom={1}>
              Roll No Length
            </Typography>
            <TextField
              {...register("rollnolength", {
                required: "This is a required Field *",
              })}
              defaultValue={10}
              inputProps={{ style: { fontWeight: 600, textAlign: "center" } }}
              sx={{ width: 200, paddingBottom: 4 }}
            />
            <Typography color={cssProperties.color.failure} variant="caption">
              {errors?.rollnolength?.message}
            </Typography>
          </Box> */}
            {/* <Typography variant="body1" fontWeight={600} paddingBottom={1}>
            Allocate Venue
          </Typography> */}
            {/* <Box
            sx={{ display: "flex", flexWrap: "wrap", gap: 4, paddingBottom: 4 }}
          >
            <Box sx={{ width: 200 }}>
              <Select
                size="small"
                defaultValue="be"
                sx={{ width: 200 }}
                fullWidth
                {...register("coursecode", {
                  required: "This is a required Field *",
                })}
              >
                <MenuItem value={"be"}>BE</MenuItem>
                <MenuItem value={"ba"}>BA</MenuItem>
                <MenuItem value={"ma"}>MA</MenuItem>
              </Select>
              <Typography color={cssProperties.color.failure} variant="caption">
                {errors?.coursecode?.message}
              </Typography>
            </Box>
            <Box sx={{ width: 200 }}>
              <Select
                size="small"
                defaultValue="mu"
                fullWidth
                {...register("districtcode", {
                  required: "This is a required Field *",
                })}
              >
                <MenuItem value="pn">PN</MenuItem>
                <MenuItem value="mu">MU</MenuItem>
                <MenuItem value="te">TE</MenuItem>
              </Select>
              <Typography color={cssProperties.color.failure} variant="caption">
                {errors?.districtcode?.message}
              </Typography>
            </Box>
            <Box sx={{ width: 200 }}>
              <Select
                size="small"
                defaultValue="ot"
                fullWidth
                {...register("venuecode", {
                  required: "This is a required Field *",
                })}
              >
                <MenuItem value="ot">OT</MenuItem>
                <MenuItem value="ad">AD</MenuItem>
              </Select>
              <Typography color={cssProperties.color.failure} variant="caption">
                {errors?.venuecode?.message}
              </Typography>
            </Box>
          </Box> */}
            <Typography variant="body1" fontWeight={600} paddingBottom={1}>
              Sequence No Length
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 4,
                paddingBottom: 4,
              }}
            >
              <Box sx={{ width: 200 }}>
                <TextField
                  {...register("nolength", {
                    required: "This is a required Field *",
                  })}
                  onInput={NumbersValidation}
                  defaultValue={`03`}
                  inputProps={{
                    style: { fontWeight: 600, textAlign: "center" },
                  }}
                />
                <Typography
                  color={cssProperties.color.failure}
                  variant="caption"
                >
                  {errors?.nolength?.message}
                </Typography>
              </Box>
              <RadioGroup
                onChange={(e) => setIsContiniousNumber(e.target.value)}
                defaultValue={0}
                row
                sx={{ gap: 4 }}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Reset after every venue"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Continious Number"
                />
              </RadioGroup>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <MauvelousPrimaryButton
                title="Generate Roll No"
                handleClick={handleSubmit(onSubmit)}
              />
              <ViewButton name="Reset" handleView={onReset} />
              <BluePrimaryButton
                title="Download Roll No Data"
                handleSubmit={() => handleDownload(rollNoDownloadData)}
                disabled={rollNoDownloadData?.length < 1}
              />
            </Box>
          </Box>
        </Card>
      </form>
      <Modal
        open={statusModalopen.open}
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 5,
            // border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box my={2}>
            <CircularProgress
              sx={{ color: `${cssProperties?.color?.primary}` }}
              size={50}
              thickness={2}
            />
          </Box>
          {statusModalopen.statustext}
        </Box>
      </Modal>
    </>
  );
};

export default Index;
