import usersActions from "./actions";

const initialState = {
    adminusersList: {
        all: [],
        filtered: []
    },
    adminuserDetails: null,
    roleList: {
        all: [],
        filtered: []
    },
    adminusersListByRole: {
        all: [],
        filtered: []
    },
};

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case usersActions.SET_ADMINUSER_LIST:
            return {
                ...state,
                adminusersList: action.payload
            }
        case usersActions.SET_ADMINUSER_DETAILS:
            return {
                ...state,
                adminuserDetails: action.payload
            }
        case usersActions.SET_ROLE_LIST:
            return {
                ...state,
                roleList: action.payload
            }
        case usersActions.SET_ADMINUSER_BASEDON_ROLE:
            return {
                ...state,
                adminusersListByRole: action.payload
            }
        default:
            return state;
    }
};

export default usersReducer;