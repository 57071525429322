import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
// import { IconButton } from "@mui/material";
// import { MdClose } from 'react-icons/md';
import b64toBlob from "../../services/base64ToBlob";
import CloseButton from "../../common/button/closeButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 2,
  width: "60vw",
  maxHeight: "90vh",
  overflow: "auto",
};

export default function ViewImageModal(props) {
  const { showModal, handleClose, document, documentname } = props;

  const [fileUrl, setFileUrl] = useState();

  useEffect(() => {
    if ((/(?:\.([^.]+))?$/).exec(documentname)[0] === ".pdf" && document) {
      const blob = b64toBlob(document, "application/pdf");
      const url = URL.createObjectURL(blob);
      setFileUrl(url);
    }
  }, [document, documentname]);

  return (
    <Modal
      open={showModal}
      // onClose={handleClose}

    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <h2 style={{color:"#3F41D1"}} id="view-image-modal">View</h2>
          {/* <IconButton  onClick={handleClose} aria-label="Close">
            <MdClose />
          </IconButton> */}
        </Box>
        <Box className="query_image_div">
          {(/(?:\.([^.]+))?$/).exec(documentname)[0] === ".pdf" ? (
            <iframe
              src={fileUrl}
              width="100%"
              height="400px"
              title="PDF Viewer"
              style={{ border: "1px solid rgba(0, 0, 0, 0.3)" }}
            />
          ) : (
            <img src={document} alt="" style={{ maxWidth: "100%" }} />
          )}
        </Box>
        <Box sx={{display:"flex",justifyContent:"flex-end",mt:2}}>
          <CloseButton name={"Close"} handleClose={handleClose}/>
        </Box>
      </Box>
    </Modal>
  );
}