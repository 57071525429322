import React from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { cssProperties } from '../utils/commonCssProperties';

import Sidebar from '../layouts/sidebar';
import Header from '../layouts/header';
import Layout from '../layouts/layout';
import PageHeading from '../layouts/pageHeading';
import { Link as MuiLink, Grid, MenuItem, Select, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import Index from '../components/AllocateVenue';
import { NavLink } from 'react-router-dom';

const AllocateVenue = () => {
    const breadcrumbs = [
        <MuiLink underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_mst_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}>
            Dashboard
        </MuiLink>,
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Allocate Venue <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];
    return (
        <>
            <Sidebar />
            <Header breadcrumbs={breadcrumbs} />
            <Layout>
                <Grid container paddingBottom={2}>
                    <Grid item md={6} >
                        <PageHeading title={'Allocate Venue'} />
                    </Grid>
                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                       
                    </Grid>
                </Grid>

                <Index />
            </Layout>
        </>
    )
}

export default AllocateVenue
