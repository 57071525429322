import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function PublicRoute(props) {

    return (
        <>
            {
                    (Cookies.get('mhet_mst_token')) ? (
                        <Navigate to={`/${Cookies.get('mhet_mst_project')}/home`} />
                    )
                        :
                        (
                            props.children
                        )
            }
        </>
    )
};