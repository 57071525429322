const venueActions = {
    GET_CANDIDATE_COUNT: 'GET_CANDIDATE_COUNT',
    SET_CANDIDATE_COUNT: 'SET_CANDIDATE_COUNT',
    GET_VENUE_DETAILS: 'GET_VENUE_DETAILS',
    SET_VENUE_DETAILS: 'SET_VENUE_DETAILS',
    SET_SEAT_ALLOCATION: 'SET_SEAT_ALLOCATION',
    GET_SEAT_ALLOCATION: 'GET_SEAT_ALLOCATION',
    GET_SEAT_ALLOCATION_DETAILS: 'GET_SEAT_ALLOCATION_DETAILS',
    SET_SEAT_ALLOCATION_DETAILS: 'SET_SEAT_ALLOCATION_DETAILS',
    GET_CANDIDATE_ALLOCATION_DEAILS: 'GET_CANDIDATE_ALLOCATION_DEAILS',
    SET_CANDIDATE_ALLOCATION_DEAILS: 'SET_CANDIDATE_ALLOCATION_DEAILS',


    UPLOAD_VENUE_DATA: 'UPLOAD_VENUE_DATA',
    RUN_SEAT_ALLOCATION: 'RUN_SEAT_ALLOCATION',
    RESET_SEAT_ALLOCATION: 'RESET_SEAT_ALLOCATION',
    STOP_SEAT_ALLOCATION: 'STOP_SEAT_ALLOCATION',
}

export default venueActions