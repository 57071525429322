import React, { useEffect, useState } from "react";
import Searchbar from "../../common/searchbar";
import { Box, Grid, Modal } from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import DataTable from "../../common/dataTable";
import { useDispatch, useSelector } from "react-redux";
import usersActions from "../../redux/users/actions";
import commonActions from "../../redux/common/actions";
import DeleteModal from "../modals/deleteModal";
import TablePagination from "../../common/tablePagination";
import { itemsPerPage } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { PiUserPlus } from "react-icons/pi";
import CreateUser from "../../pages/createUser";

const tableKeys = [
    {
        name: "SI.NO",
        accessor: 'id',
        sortOption: false
    },
    {
        name: "Name",
        accessor: 'fullname',
        sortOption: false
    },
    {
        name: "Role Name",
        accessor: 'rolenames',
        sortOption: false
    },
    {
        name: "Email ID",
        accessor: 'emailid',
        sortOption: false
    },
    {
        name: "Mobile No",
        accessor: 'mobileno',
        sortOption: false
    },

];

export default function Index() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const handleClose = () => {
        setShowModal(false)
    }
    const { adminusersList } = useSelector(state => state.usersReducer);

    const { backofficeTokenDetails } = useSelector(state => state.authReducer);

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        dispatch({ type: usersActions.GET_ADMINUSER_LIST });
        //eslint-disable-next-line
    }, []);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = adminusersList?.filtered?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(adminusersList?.filtered?.length / itemsPerPage);

    const handlePageChange = (event, value) => {
        const newOffset = (value - 1) * itemsPerPage;
        setItemOffset(newOffset);
        setCurrentPage(value);
    };

    const handleSearch = (e) => {
        let filtered = [];
        setCurrentPage(1);
        setItemOffset(0);
        if (e.target.value) {
            //eslint-disable-next-line
            filtered = adminusersList?.all?.filter(val => {
                if (val?.fullname?.toLowerCase().includes(e.target.value?.toLowerCase()) ||
                    val?.emailid?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
                    val?.mobileno?.includes(e.target.value)) {
                    return val;
                }
            });
        } else {
            filtered = [...adminusersList?.all];
        }
        dispatch({ type: usersActions.SET_ADMINUSER_LIST, payload: { ...adminusersList, filtered: filtered } })
    };

    const handleUserDelete = (id) => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: true, type: 'User', method: 'D', data: id } });
    };

    const handleDelete = (id) => {
        dispatch({
            type: usersActions.DELETE_ADMINUSER,
            payload: { userid: id, updatedby: backofficeTokenDetails?.id }
        });
        setTimeout(() => {
            setCurrentPage(1);
            setItemOffset(0);
        }, 1000);
    };

    const handleAddUser = () => {
        setShowModal(true)
        // navigate(`/${Cookies.get('mhet_mst_project')}/create-user`)
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '24px', marginBottom: '14px' }}>
                {/* <Box></Box> */}
                <Searchbar height="48px" handleSearch={handleSearch} />
                <MauvelousPrimaryButton icon={<PiUserPlus />} title={'Add User'} handleClick={handleAddUser} />
            </Box>
            <DataTable
                keys={tableKeys}
                values={currentItems}
                page={'userList'}
                handleUserDelete={handleUserDelete}
            />
            <TablePagination
                pageCount={pageCount}
                handlePageChange={handlePageChange}
                currentPage={currentPage}
            />
            <DeleteModal
                handleDelete={handleDelete}
            />
            <Modal
                open={showModal}
                // onClose={handleClose}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grid sx={{maxWidth:'80vw',width:'100%'}}>
                    <Grid container p={2} mt={2} >
                        <Grid item sm={12} md={12} lg={12} bgcolor={"#ffffff"} padding={"24px"} boxShadow={1} borderRadius={'4px'} minHeight={'500px'}>
                            <CreateUser handleClose={handleClose} />
                        </Grid>
                    </Grid>
                </Grid>

            </Modal>
        </>
    )
}