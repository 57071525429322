import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, Icon, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, css } from '@mui/material'
import React from 'react'
import { cssProperties } from '../../utils/commonCssProperties'
import BluePrimaryButton from '../../common/button/bluePrimaryButton'
import { TbBrowser } from "react-icons/tb";
import BackButton from '../../common/button/backButton';
import MauvelousPrimaryButton from '../../common/button/mauvelousPrimaryButton';
import { useNavigate } from 'react-router-dom';


export default function Index() {

  const navigate = useNavigate();

  return (
    <Grid
      style={{
        padding: "24px",
        // minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        marginTop: '12px'
      }}
    >

      <Grid container gap={3}>
      {/* <Grid container gap={3} sx={{ display: "felx", flexDirection: "column", border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`, padding: "24px 16px 24px 16px", borderRadius: "4px" }}> */}

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="text" inputProps={{ maxLength: 20 }} size='small' label="Project name" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="text" inputProps={{ maxLength: 30 }} size='small' label="Customer name" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="text" inputProps={{ maxLength: 40 }} size='small' label="Customer SPOC" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="text" inputProps={{ maxLength: 40 }} size='small' label="Customer contact details" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="text" size='small' label="Project description" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl fullWidth size='small' sx={{}}>
            <InputLabel id="Exam mode label">Exam mode</InputLabel>
            <Select label="Exam mode" labelId={"Exam mode label"} >
              <MenuItem value={"test"}>Test</MenuItem>
              <MenuItem value={"test"}>Test</MenuItem>
              <MenuItem value={"test"}>Test</MenuItem>
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="text" inputProps={{ maxLength: 30 }} size='small' label="Email id" />


        </Grid><Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField fullWidth type="password" inputProps={{ maxLength: 30 }} size='small' label="Password" />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormControl>
            <FormLabel id="Active-label">Active</FormLabel>
            <RadioGroup row
              aria-labelledby="Active-label"
              defaultValue="female"
              name="Active-group"
            >
              <FormControlLabel value="enable" control={<Radio />} label="Enable" />
              <FormControlLabel value="disable" control={<Radio />} label="Disable" />
            </RadioGroup>
          </FormControl>

        </Grid>



        <Grid item xs={12} sm={12} md={6} lg={6}>
          <InputLabel htmlFor='file-upload' sx={{ width: "fit-content" }}>
            <TextField id='file-upload' type="file" name='file' sx={{ display: "none" }} />
            {BluePrimaryButton({ title: "Upload", icon: <TbBrowser /> })}
          </InputLabel>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} display={'flex'} gap={2} mt={5}>
          {BackButton({handleBack: () => navigate('/admin/home')})} {MauvelousPrimaryButton({ title: "Submit" })}
        </Grid>


      </Grid>
    </Grid>
  )
}