import { takeEvery, put, call, all, select, delay } from "redux-saga/effects";
import ticketsActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { calculateDaysDifference, formatDate } from "../../utils/validation";
import commonActions from "../common/actions";
import Cookies from "js-cookie";
import candidateActions from "../candidate/actions";
import { DateConvertion } from "../../utils/commonFunctions";

const ticketsSagas = function* () {
  yield all([
    yield takeEvery(
      ticketsActions.GET_TICKETLIST_BY_USER,
      getTicketsListByUser
    ),
    yield takeEvery(ticketsActions.GET_TICKETDETAILS, getTicketDetails),
    yield takeEvery(ticketsActions.UPDATE_TICKET_STATUS, updateTicketStatus),
    yield takeEvery(ticketsActions.HANDLE_TICKETS_FILTER, handleTicketsFilter),
    yield takeEvery(
      ticketsActions.UPDATE_TICKET_RESPONSE_COMMENTS,
      updateTicketResponseComments
    ),
    yield takeEvery(ticketsActions.GET_TICKETSTATUSCOUNT, getTicketStatusCount),
    yield takeEvery(ticketsActions.GET_CANDIDATE_DETAILS, getCandidateDetails),
    yield takeEvery(ticketsActions.GRIEVANCE_QUERY_CREATION, grievanceQueryCreation),
    yield takeEvery(ticketsActions.GET_ALL_QUERY, getAllQueries),
    yield takeEvery(ticketsActions.GET_RAISED_QUERY, getRaisedQueries),
    yield takeEvery(ticketsActions.GET_TOTAL_TICKET_COUNT, getTotalTicketCount),
    yield takeEvery(
      ticketsActions.GET_AVERAGE_RESPONSETIME_COUNT,
      getAverageResponseTimeCount
    ),
  ]);
};

const getTicketsListByUser = function* (data) {
  try {
    const { backofficeTokenDetails } = yield select(
      (state) => state.authReducer
    );
    const { setFilters } = yield select((state) => state.ticketsReducer);
    if (backofficeTokenDetails) {
      yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
      let api;
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Coordinator"
      )
        ? (api = "ticket")
        : (api = `ticket/user/${backofficeTokenDetails?.id}`);
      const result = yield call(() =>
        axios.get(`${API_URL}/backoffice/grievance/${api}`)
      );
      if (result?.data?.statusCode === 200) {
        let res = result?.data?.result?.map((val, i) => {
          return {
            ...val,
            id: i + 1,
            noofdays: calculateDaysDifference(
              new Date(val?.createddate),
              new Date()
            ),
            ticketraisedon: formatDate(val?.createddate),
            status:
              val?.status === 1
                ? "Open"
                : val?.status === 2
                  ? "Assigned"
                  : val?.status === 3
                    ? "Reassigned"
                    : val?.status === 4
                      ? "Hold"
                      : "Closed",
          };
        });
        yield put({
          type: ticketsActions.SET_TICKETLIST_BY_USER,
          payload: { all: res, filtered: res },
        });
        if (setFilters?.searchText || setFilters?.filterBy) {
          yield put({
            type: ticketsActions.HANDLE_TICKETS_FILTER,
            payload: setFilters,
          });
        }
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
      }
    }
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getTicketDetails = function* (data) {
  const { payload } = data;
  try {
    yield put({ type: ticketsActions.SET_TICKETDETAILS, payload: null });
    yield put({
      type: ticketsActions.SET_TICKET_RESPONSE_EDIT,
      payload: {
        show: false,
        response: null,
        type: null,
        isEditable: true,
      },
    });
    yield put({
      type: ticketsActions.SET_TICKET_COMMENTS_EDIT,
      payload: {
        show: false,
        response: null,
        type: null,
        isEditable: true,
      },
    });
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/grievance/ticket/${payload}`)
    );
    yield put({
      type: ticketsActions.SET_TICKETDETAILS,
      payload: result?.data?.statusCode === 200 ? result?.data?.result : null,
    });
  } catch (err) {
    console.log(err);
  }
};

const getCandidateDetails = function* (data) {
  const { payload } = data;
  const { setCandidateInfo } = payload;
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
  yield put({ type: ticketsActions.SET_CANDIDATE_DETAILS, payload: [] })
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/grievance/candidate-details`, {
        params: {
          emailid: payload.emailid,
          mobileno: payload.mobileno,
        },
      })
    );
    if (result?.data?.message === "No records found") {
      setCandidateInfo(false);
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: "failed",
          show: true,
          message: "No candidate found",
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }

    if (result?.data?.statusCode === 200) {
      if (result?.data?.message === "No records found") {
        setCandidateInfo(false);
      }
      setCandidateInfo(true);
      yield put({
        type: ticketsActions.SET_CANDIDATE_DETAILS,
        payload:
          result?.data?.statusCode === 200 ? result?.data?.result[0] : null,
      });
      yield put({
        type: candidateActions.GET_TICKETS_LIST_BY_CANDIDATE,
        payload: { id: result?.data?.result[0]?.candidateid },
      });
    }
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const updateTicketStatus = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });
  try {
    const result = yield call(() =>
      axios.put(
        `${API_URL}/backoffice/grievance/ticket-update/${payload?.id}`,
        payload?.data
      )
    );
    const { backofficeTokenDetails } = yield select(state => state.authReducer);
    if (
      result?.data?.statusCode === 200 &&
      (payload?.response?.response || payload?.response?.comments)
    ) {
      yield put({
        type: ticketsActions.UPDATE_TICKET_RESPONSE_COMMENTS,
        payload: { data: payload?.response, navigate: navigate },
      });
    } else {
      let path;
      backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Resolution") ? path = 'home' : path = 'ticket-list';
      navigate(`/${Cookies.get("mhet_mst_project")}/${path}`);
      yield put({ type: ticketsActions.GET_TICKETLIST_BY_USER });
      yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
      yield put({
        type: commonActions.SET_ALERT,
        payload: {
          status: result?.data?.statusCode === 200 ? "success" : "failed",
          show: true,
          message: result?.data?.message,
        },
      });
      yield delay(2000);
      yield put({
        type: commonActions.SET_ALERT,
        payload: { status: null, show: false, message: null },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const updateTicketResponseComments = function* (data) {
  const { payload } = data;
  const { navigate } = payload;

  try {
    const result = yield call(() =>
      axios.post(
        `${API_URL}/backoffice/grievance/ticket/response`,
        payload?.data
      )
    );
    yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_mst_project")}/ticket-list`);
      yield put({ type: ticketsActions.GET_TICKETLIST_BY_USER });
    }
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
};

const handleTicketsFilter = function* (data) {
  const { payload } = data;

  try {
    const { ticketsList } = yield select((state) => state.ticketsReducer);
    let filtered = [...ticketsList?.filtered];
    if (payload?.searchText && ticketsList?.all?.length > 0) {
      //eslint-disable-next-line
      filtered = ticketsList?.all?.filter((val) => {
        if (
          val?.ticketcode?.toLowerCase()?.includes(payload?.searchText?.toLowerCase()) ||
          val?.ticketraisedon?.includes(payload?.searchText) ||
          val?.grievance
            ?.toLowerCase()
            ?.includes(payload?.searchText?.toLowerCase()) ||
          val?.category
            ?.toLowerCase()
            ?.includes(payload?.searchText?.toLowerCase()) ||
          val?.createdbyname
            ?.toLowerCase()
            ?.includes(payload?.searchText?.toLowerCase()) ||
          val?.grievance
            ?.toLowerCase()
            ?.includes(payload?.searchText?.toLowerCase())
        ) {
          return val;
        }
      });
    } else {
      filtered = [...ticketsList?.all];
    }
    if (
      payload?.filterBy &&
      payload?.filterBy !== "All" &&
      filtered?.length > 0
    ) {
      //eslint-disable-next-line
      let dropdownfiler = filtered?.filter((val) => {
        if (
          val?.status?.toLowerCase()?.includes(payload?.filterBy?.toLowerCase())
        ) {
          return val;
        }
      });
      filtered = [...dropdownfiler];
    }
    if (payload?.sort?.col && filtered?.length > 0) {
      let sortFilter = [];
      if (payload?.sort?.col === 'ticketraisedon') {
        switch (payload?.sort?.order) {
          case "D":
            sortFilter = [...filtered].sort((a, b) => {
              if (DateConvertion(a[payload?.sort?.col]) < DateConvertion(b[payload?.sort?.col])) return 1;
              if (DateConvertion(a[payload?.sort?.col]) > DateConvertion(b[payload?.sort?.col])) return -1;
              return 0;
            });
            break;
          default:
            sortFilter = [...filtered].sort((a, b) => {
              if (DateConvertion(a[payload?.sort?.col]) < DateConvertion(b[payload?.sort?.col])) return -1;
              if (DateConvertion(a[payload?.sort?.col]) > DateConvertion(b[payload?.sort?.col])) return 1;
              return 0;
            });
            break;
        }
      } else {
        switch (payload?.sort?.order) {
          case "D":
            sortFilter = [...filtered].sort((a, b) => {
              if (a[payload?.sort?.col] < b[payload?.sort?.col]) return 1;
              if (a[payload?.sort?.col] > b[payload?.sort?.col]) return -1;
              return 0;
            });
            break;
          default:
            sortFilter = [...filtered].sort((a, b) => {
              if (a[payload?.sort?.col] < b[payload?.sort?.col]) return -1;
              if (a[payload?.sort?.col] > b[payload?.sort?.col]) return 1;
              return 0;
            });
            break;
        }
      }
      filtered = [...sortFilter];
    }
    yield put({
      type: ticketsActions.SET_TICKETLIST_BY_USER,
      payload: { ...ticketsList, filtered: filtered },
    });
  } catch (err) {
    console.log(err);
  }
};

const getTicketStatusCount = function* (data) {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/grievance/ticketcount`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: ticketsActions.SET_TICKETSTATUSCOUNT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getTotalTicketCount = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/grievance/total-tickets/${payload}`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: ticketsActions.SET_TOTAL_TICKET_COUNT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const grievanceQueryCreation = function* (data) {
  const { payload, navigate, handleReset } = data;
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: true });

  try {
    const result = yield call(() =>
      axios.post(`${API_URL}/backoffice/grievance/ticket`, payload.data)
    );
    if (result?.data?.statusCode === 200) {
      navigate(`/${Cookies.get("mhet_mst_project")}/helpdesk-ticket-list`)
      yield put({ type: ticketsActions?.SET_CANDIDATE_DETAILS, payload: [] });
      handleReset();
    }
    yield put({
      type: commonActions.SET_ALERT,
      payload: {
        status: result?.data?.statusCode === 200 ? "success" : "failed",
        show: true,
        message: result?.data?.message,
      },
    });
    yield delay(2000);
    yield put({
      type: commonActions.SET_ALERT,
      payload: { status: null, show: false, message: null },
    });
  } catch (err) {
    console.log(err);
  }
  yield put({ type: commonActions.SET_BUTTON_LOADER, payload: false });
};

const getAllQueries = function* () {
  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/grievance/ticket`)
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: ticketsActions.SET_ALL_QUERY,
        payload: { all: result?.data?.result, filtered: result?.data?.result },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getRaisedQueries = function* (data) {
  const id = yield select(
    (state) => state.authReducer.backofficeTokenDetails.id
  );

  try {
    const result = yield call(() =>
      axios.get(`${API_URL}/backoffice/grievance/raised-grievance/${id}`)
    );
    if (result?.data?.statusCode === 200) {
      let finalResult = result?.data?.result?.sort((a, b) => {
        {
          let dateA = new Date(a.createddate);
          let dateB = new Date(b.createddate);
          return dateB - dateA;
        }
      })?.map((val, i) => {
        return {
          ...val,
          id: i + 1,
          noofdays: calculateDaysDifference(
            new Date(val?.createddate),
            new Date()
          ),
          ticketraisedon: formatDate(val?.createddate),
          grievancetype:
            val.tickettype === 2
              ? "By Call"
              : val.tickettype === 3
                ? "By Email"
                : "Open",
          status:
            val?.status === 1
              ? "Open"
              : val?.status === 2
                ? "Assigned"
                : val?.status === 3
                  ? "Reassigned"
                  : val?.status === 4
                    ? "Hold"
                    : "Closed",
        };
      });
      yield put({
        type: ticketsActions.SET_RAISED_QUERY,
        payload: { all: finalResult, filtered: finalResult },
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const getAverageResponseTimeCount = function* (data) {
  const { payload } = data;

  try {
    const result = yield call(() =>
      axios.get(
        `${API_URL}/backoffice/grievance/average-tickets-response/${payload}`
      )
    );
    if (result?.data?.statusCode === 200) {
      yield put({
        type: ticketsActions.SET_AVERAGE_RESPONSETIME_COUNT,
        payload: result?.data?.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export default ticketsSagas;
