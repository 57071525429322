import React from "react";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
// import Sidebar from "../layouts/SideBar";
import PageHeading from "../layouts/pageHeading";
import { Typography } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
// import { NavLink } from 'react-router-dom';
// import Cookies from "js-cookie";
import Index from "../components/projectList";
import Sidebar from "../layouts/sidebar";

export default function ProjectList() {
    const breadcrumbs = [
        // <Link underline="hover" key="1" component={NavLink} to={`/admin/home`} sx={{
        //     color: cssProperties?.fontcolor?.primary,
        //     fontSize: '14px',
        //     fontWeight: 400,
        //     lineHeight: '20px',
        //     letterSpacing: '0.1px',
        //     textDecoration : "none"
        // }}>  
        //     Dashboard
        // </Link>,
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}>Dashboard <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];

    return(
        <Layout>
            <Header breadcrumbs={breadcrumbs} />
            <Sidebar />
            <PageHeading title="View projects" />
            <Index />
        </Layout>
    )
}