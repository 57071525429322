import React from "react";
import { PiUserGearLight, PiUserPlusLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function Index() {

  const navigate = useNavigate();

  const items = [
    { icon: <PiUserGearLight size={30} />, name: "USER MANAGEMENT", details: "(Create all users and map to roles)", path: `/${Cookies.get('mhet_mst_project')}/users` },
    { icon: <PiUserPlusLight size={30} />, name: "MASTER MANAGEMENT", details: "(Create new masters for a project)", path: `/${Cookies.get('mhet_mst_project')}/master-list` },
    { icon: <PiUserPlusLight size={30} />, name: "MASTER FORMS MANAGEMENT", details: "(Create new masters forms)", path: `/${Cookies.get('mhet_mst_project')}/master-forms-list` },
    { icon: <PiUserPlusLight size={30} />, name: "COURSE MASTER MANAGEMENT", details: "(Create new course for a projects)", path: `/${Cookies.get('mhet_mst_project')}/course-management?page=courselist` },
  ]

  return (
    <Grid container spacing={3} sx={{ marginTop: '12px' }}>
      {items.map((item, index) => (
        <Grid item xs={12} sm={6} lg={4} key={index}
          display="flex" justifyContent="center"
          onClick={() => {
            if (item?.path) {
              navigate(item.path);
            }
          }}
        >
          <Card
            sx={{
              width: '100%',
              height: '227px',
              borderRadius: "8px",
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: "none"
            }}
          >
            <Box
              sx={{
                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                color: `${cssProperties?.color?.textsecondary}`,
                border: `1px solid ${cssProperties?.bordercolor?.primary}`,
                borderRadius: '50%',
                width: '62px',
                height: '62px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '12px'
              }}
            >
              {item.icon}
            </Box>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: `${cssProperties?.fontcolor?.tableblack}`,
                  textAlign: 'center'
                }}
              >
                {item.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: `${cssProperties?.color?.textsecondary}`,
                  textAlign: 'center',
                }}
              >
                {item.details}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
