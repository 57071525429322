const ticketsActions = {
    GET_TICKETLIST_BY_USER: 'GET_TICKETLIST_BY_USER',
    SET_TICKETLIST_BY_USER: 'SET_TICKETLIST_BY_USER',
    GET_TICKETDETAILS:'GET_TICKETDETAILS',
    SET_TICKETDETAILS:'SET_TICKETDETAILS',
    GET_TICKETSTATUSCOUNT: 'GET_TICKETSTATUSCOUNT',
    SET_TICKETSTATUSCOUNT: 'SET_TICKETSTATUSCOUNT',
    GET_AVERAGE_RESPONSETIME_COUNT: 'GET_AVERAGE_RESPONSETIME_COUNT',
    SET_AVERAGE_RESPONSETIME_COUNT: 'SET_AVERAGE_RESPONSETIME_COUNT',

    SET_FILTERS: 'SET_FILTERS',
    HANDLE_TICKETS_FILTER: 'HANDLE_TICKETS_FILTER',
    SET_TICKET_RESPONSE_EDIT: 'SET_TICKET_RESPONSE_EDIT',
    SET_TICKET_COMMENTS_EDIT: 'SET_TICKET_COMMENTS_EDIT',

    UPDATE_TICKET_STATUS: 'UPDATE_TICKET_STATUS',
    UPDATE_TICKET_RESPONSE_COMMENTS: 'UPDATE_TICKET_RESPONSE_COMMENTS',
    GET_CANDIDATE_DETAILS : 'GET_CANDIDATE_DETAILS',
    SET_CANDIDATE_DETAILS : 'SET_CANDIDATE_DETAILS',
    GRIEVANCE_QUERY_CREATION : 'GRIEVANCE_QUERY_CREATION',
    SET_ALL_QUERY: 'SET_ALL_QUERY',
    GET_ALL_QUERY: 'GET_ALL_QUERY',
    GET_RAISED_QUERY: 'GET_RAISED_QUERY',
    SET_RAISED_QUERY: 'SET_RAISED_QUERY',
    SET_TOTAL_TICKET_COUNT : 'SET_TOTAL_TICKET_COUNT',
    GET_TOTAL_TICKET_COUNT : 'GET_TOTAL_TICKET_COUNT',
};

export default ticketsActions;