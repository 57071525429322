import React from "react";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../../redux/common/actions";
import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import { cssProperties } from "../../../utils/commonCssProperties";
import { AlphabetsValidation } from "../../../utils/validation";
import CancelButton from "../../../common/button/cancelButton";
import BluePrimaryButton from "../../../common/button/bluePrimaryButton";
import ButtonLoader from "../../../common/buttonLoader";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

export default function DeleteModal({ register, handleSubmit, errors, handleCreateProject, resetForm }) {

    const dispatch = useDispatch();

    const { showModal, buttonLoader } = useSelector(state => state.commonReducer);

    const handleClose = () => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } });
    // resetForm()
    };

    return (
        <Modal
            open={showModal?.show && showModal?.method === 'DMM'}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant="h5" sx={{ color: `${cssProperties?.color?.primary}`, fontWeight: '500', marginBottom: '18px' }}>Delete Master</Typography>
                <Box>Are you sure to delete this master?</Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton handleBack={handleClose} />
                    <BluePrimaryButton title={buttonLoader ? <ButtonLoader /> : "Delete"} handleSubmit={handleSubmit(handleCreateProject)} />
                </Box>
            </Box> 
        </Modal >
    )
}