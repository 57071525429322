const forcePushAllocationActions = {
    GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION: 'GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION',
    SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION: 'SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION',
    GET_VENUE_LIST_BY_DISTRICT: 'GET_VENUE_LIST_BY_DISTRICT',
    SET_VENUE_LIST_BY_DISTRICT: 'SET_VENUE_LIST_BY_DISTRICT',
    GET_EXAM_DATES_BY_COURSEID: 'GET_EXAM_DATES_BY_COURSEID',
    SET_EXAM_DATES_BY_COURSEID: 'SET_EXAM_DATES_BY_COURSEID',
    GET_EXAM_BATCH__BY_EXAMDATE: 'GET_EXAM_BATCH__BY_EXAMDATE',
    SET_EXAM_BATCH__BY_EXAMDATE: 'SET_EXAM_BATCH__BY_EXAMDATE',

    FORCE_PUSH_ALLOCATION: 'FORCE_PUSH_ALLOCATION',
    FORCE_PUSH_CANCEL_ALLOCATION: 'FORCE_PUSH_CANCEL_ALLOCATION',

    FORCEPUSH_SELECTED_VALUES: 'FORCEPUSH_SELECTED_VALUES',
};

export default forcePushAllocationActions;