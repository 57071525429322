const courseActions = {
    GET_COURSETAB_MASTER: 'GET_COURSETAB_MASTER',
    SET_COURSETAB_MASTER: 'SET_COURSETAB_MASTER',
    GET_COURSEFIELD_MASTER: 'GET_COURSEFIELD_MASTER',
    SET_COURSEFIELD_MASTER: 'SET_COURSEFIELD_MASTER',
    // SET_BUTTON_ENABLE: 'SET_BUTTON_ENABLE',

    CREATE_COURSEFORM: 'CREATE_COURSEFORM',
};

export default courseActions;