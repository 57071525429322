import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, Button, Paper } from '@mui/material';
import './admitCard.css';

const QuillAdmitCard = () => {
  const [value, setValue] = useState(`
    <h4>Admit card editor</h4>
  `);

  const handleSave = () => {
    console.log(value);
  };

  const initialContent = `
  <div style="width: 100%; padding: 16px;">
  <div style="display: flex; justify-content: space-between; padding: 16px;">
    <div style="font-weight: 600; color: green;">Venue: venue</div>
    <div style="font-weight: 600; color: green; text-align: center;">Date: date</div>
    <div style="font-weight: 600; color: green; text-align: right;">Time: time</div>
  </div>
  <div style="background-color: #FFFFFF; border: 1px solid rgba(213, 213, 213, 1); border-radius: 8px; min-height: 50vh; width: 100%; word-break: break-word; position: relative;">
    <div style="display: flex; justify-content: space-between; padding: 16px;">
      <div>
        <img src="cetIcon" alt="CET Icon" style="display: block;" />
      </div>
      <div style="text-align: center;">
        <div>Government of Maharashtra</div>
        <div style="margin-top: 8px;">State Common Entrance Test Cell, Mumbai</div>
        <div style="margin-top: 8px;">MAH - LL.B 3yrs CET - 2024 Online Examination</div>
        <div style="margin-top: 8px;">ADMIT CARD</div>
      </div>
      <div>
        <img src="emblem" alt="Emblem" style="display: block;" />
      </div>
    </div>
    <div style="border: 1px solid #BDBDBD; margin-top: 32px; padding: 16px;">
      <div style="display: flex; flex-wrap: wrap;">
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Candidate's Full Name</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 75%; font-weight: 600;">MILIND PATEL</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Roll Number/UserName</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">2487462643</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">DOB/Password</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">03/06/2001</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Date of CET Examination</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">03/06/2024</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Examination Time</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">02:00 PM to 4:00 PM</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Reporting Time at the CET Examination Centre</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">12.30 PM</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Gate Closure Time</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">01.30 PM</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Question Paper Language</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">English/Marathi</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Name of the course</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">MAH - LL.B 3yrs</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Gender</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">Male</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Application Number</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">248600001</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Person with Disability (PWD)</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;">No</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Disability Type</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;"></div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Extra Time Required</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;"></div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Scribe</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%; font-weight: 600;"></div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 25%;">Venue Code</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 75%; font-weight: 600;">NAGP02</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 100%; background-color: #BDBDBD;">CET Exam Centre Address</div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 100%; font-weight: 600;">
          <div>MODERN COLLEGE</div>
          <div>MIDC, T-POINT WAADI, AMRAWATI ROAD, OPP RAHUL HOTEL, WAADI, OPP RAHUL HOTEL, NAGPUR 440023</div>
        </div>
        <div style="display: flex; border: 1px solid #BDBDBD; padding: 8px; width: 100%; font-weight: 600;">
          Please bring this hall ticket with currently valid photo identity proof in original- THIS IS ESSENTIAL. Currently valid Original photo identity proof may be PAN Card/Permanent Driving License/Voter’s Card/Bank Passbook with photograph/photo Identity proof issued by a Gazetted Officer on official letterhead/Photo Identity proof issued by a People’s Representative on official letterhead/Recent Identity Card issued by a recognized College/University/Aadhaar Card and Learner’s Driving License.
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center; padding: 16px; border-top: 1px solid #BDBDBD;">
      <span>Signature of the Candidate</span>
      <img src="signature" alt="Signature" style="display: block;" />
      <span>Authorized Signature</span>
    </div>
  </div>
</div>`;

  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getEditor().clipboard.dangerouslyPasteHTML(initialContent);
    }
  }, []);

  return (
    <>
      <Box>
        <Paper elevation={3} style={{ padding: '10px', minHeight: '200px' }}>
          <ReactQuill
            ref={editorRef}
            value={value}
            onChange={setValue}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'size': [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'align': [] }],
                ['link', 'image'],
                ['clean'],
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'align', 'link', 'image'
            ]}
            theme="snow"
          />
        </Paper>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Content
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default QuillAdmitCard;
