import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "../protectedRoute/publicRoute";
import SuperAdminLogin from "../pages/superAdminLogin";
import PrivateRoute from "../protectedRoute/PrivateRoute";
import ProjectList from "../pages/projectList";
import CreateProject from "../pages/createProject";

const AdminRoutes = () => {

    return (
        <>
            <Routes>
                <Route path="" element={<PublicRoute><SuperAdminLogin /></PublicRoute>} />
                <Route path="login" element={<PublicRoute><SuperAdminLogin /></PublicRoute>} />
                <Route path="home" element={<PublicRoute><ProjectList /></PublicRoute>} />
                <Route path="create-project" element={<PublicRoute><CreateProject /></PublicRoute>} />
            </Routes>
        </>
    )
};

export default AdminRoutes;