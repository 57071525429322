import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DecryptFunction, EncryptFunction } from "../../utils/cryptoFunction";
import ticketsActions from "../../redux/tickets/actions";
import Cookies from "js-cookie";
import { hashEmail, hashMobile } from "../../utils/validation";
import usersActions from "../../redux/users/actions";
import ButtonLoader from "../../common/buttonLoader";
import candidateActions from "../../redux/candidate/actions";
import commonActions from "../../redux/common/actions";
import PreviousTicketListSelectModel from "../modals/previousTicketListSelectModal";
import CandidateDetailsFormModal from "../modals/candidateDetailsFormModal";
import TicketComments from "./ticketComments";
import {
  Box,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Button,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import CloseButton from "../../common/button/closeButton";
import ViewButton from "../../common/button/viewButton";
import BackButton from "../../common/button/backButton";
import { cssProperties } from "../../utils/commonCssProperties";

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ticketid = searchParams.get("ticket");

  const { ticketDetails, showResponseEdit, showCommentsEdit } = useSelector(
    (state) => state.ticketsReducer
  );

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const { adminusersListByRole } = useSelector((state) => state.usersReducer);

  const { buttonLoader } = useSelector((state) => state.commonReducer);

  const { candidateTicketList } = useSelector(
    (state) => state.candidateReducer
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm();

  const [showInfo, setShowInfo] = useState(false);
  // let [showResponseEdit, setShowResponseEdit] = useState({ show: false, response: null, type: null, isEditable: true });
  // let [showCommentsEdit, setShowCommentsEdit] = useState({ show: false, response: null, type: null, isEditable: true });

  useEffect(() => {
    if (DecryptFunction(ticketid)) {
      dispatch({
        type: ticketsActions.GET_TICKETDETAILS,
        payload: DecryptFunction(ticketid),
      });
    } else {
      navigate(`/${Cookies.get("mhet_mst_project")}/ticket-list`);
    }
  }, [ticketid]);

  useEffect(() => {
    if (
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Coordinator"
      ) ||
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Resolution"
      )
    ) {
      dispatch({ type: usersActions.GET_ADMINUSER_LIST });
    }
  }, [backofficeTokenDetails]);

  useEffect(() => {
    if (ticketDetails?.candidateid) {
      dispatch({
        type: candidateActions.GET_TICKETS_LIST_BY_CANDIDATE,
        payload: { id: ticketDetails?.candidateid },
      });
    }
  }, [ticketDetails?.candidateid]);

  useEffect(() => {
    if (ticketDetails) {
      reset({
        response: ticketDetails?.response
          ? ticketDetails?.response?.response
          : "",
        // comments: ticketDetails?.comments,
        status:
          ticketDetails?.status === 1 ||
            ticketDetails?.status === 2 ||
            ticketDetails?.status === 3
            ? 1
            : ticketDetails?.status,
      });
      if (ticketDetails?.response) {
        dispatch({
          type: ticketsActions.SET_TICKET_RESPONSE_EDIT,
          payload: {
            show: true,
            response: ticketDetails?.response?.response,
            type: "edit",
            isEditable: false,
          },
        });
      } else {
        dispatch({
          type: ticketsActions.SET_TICKET_RESPONSE_EDIT,
          payload: {
            show: false,
            response: ticketDetails?.response?.response,
            type: "edit",
            isEditable: true,
          },
        });
      }
      if (ticketDetails?.comments) {
        dispatch({
          type: ticketsActions.SET_TICKET_COMMENTS_EDIT,
          payload: {
            show: true,
            response: ticketDetails?.comments,
            type: "edit",
            isEditable: false,
          },
        });
      } else {
        dispatch({
          type: ticketsActions.SET_TICKET_COMMENTS_EDIT,
          payload: {
            show: false,
            response: ticketDetails?.comments,
            type: "edit",
            isEditable: true,
          },
        });
      }
      if (ticketDetails?.candidateid) {
        dispatch({
          type: candidateActions.GET_FULL_CANDIDATE_DETAILS,
          payload: { id: ticketDetails?.candidateid },
        });
      }
    }
  }, [ticketDetails]);

  const handleInfo = () => {
    if (showInfo && ticketDetails) {
      return `MobileNo - ${ticketDetails?.mobileno} \nEmail - ${ticketDetails?.emailid}`;
    } else if (ticketDetails) {
      return `MobileNo - ${hashMobile(
        ticketDetails?.mobileno
      )} \nEmail - ${hashEmail(ticketDetails?.emailid)}`;
    } else {
      return "";
    }
  };

  const handleAssign = (data) => {
    if (
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Coordinator"
      ) ||
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Resolution"
      )
    ) {
      let response = {};
      response["comments"] = data["comments"];
      // if (showCommentsEdit?.isEditable || showResponseEdit?.isEditable) {
      // if (showCommentsEdit?.isEditable) {
      // };
      if (showResponseEdit?.isEditable) {
        response["response"] = data["response"];
      }
      response["ticketid"] = parseInt(ticketDetails?.ticketid);
      response["responseby"] = 2;
      response["responseuser"] = parseInt(backofficeTokenDetails?.id);
      response["createdby"] = parseInt(backofficeTokenDetails?.id);

      data["assignedto"] = data?.assignedto
        ? parseInt(data?.assignedto)
        : ticketDetails?.assignedto;
      data["status"] = backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Coordinator"
      )
        ? 2
        : data?.status;
      data["updatedby"] = parseInt(backofficeTokenDetails?.id);
      delete data["response"];
      delete data["comments"];
      dispatch({
        type: ticketsActions.UPDATE_TICKET_STATUS,
        payload: {
          id: ticketDetails?.ticketid,
          data: data,
          navigate: navigate,
          response: response,
        },
      });
    }
  };

  const handleResponseBtnClick = () => {
    let type, isEditable;
    showResponseEdit?.type === "edit" ? (type = "reset") : (type = "edit");
    showResponseEdit?.type === "edit"
      ? (isEditable = true)
      : (isEditable = false);
    dispatch({
      type: ticketsActions.SET_TICKET_RESPONSE_EDIT,
      payload: { ...showResponseEdit, type: type, isEditable: isEditable },
    });
  };

  // const handleCommentsBtnClick = () => {
  //   let type, isEditable;
  //   (showCommentsEdit?.type === 'edit') ? type = 'reset' : type = 'edit';
  //   (showCommentsEdit?.type === 'edit') ? isEditable = true : isEditable = false;
  //   dispatch({ type: ticketsActions.SET_TICKET_COMMENTS_EDIT, payload: { ...showCommentsEdit, type: type, isEditable: isEditable } });
  // };

  const handleBack = () => {
    if (
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Coordinator"
      ) ||
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Resolution"
      )
      // &&
      // watch("status")?.toString() === "1"
    ) {
      navigate(`/${Cookies.get("mhet_mst_project")}/ticket-list`);
    } else {
      navigate(`/${Cookies.get("mhet_mst_project")}/raise-ticket`);
    }
  };

  const handlePreviousTicket = () => {
    if (candidateTicketList?.length === 1) {
      navigate(
        `/${Cookies.get(
          "mhet_mst_project"
        )}/ticket-details?ticket=${EncryptFunction(
          candidateTicketList[0]?.ticketid
        )}`
      );
    } else {
      dispatch({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: true, type: null, method: "PTS", data: null },
      });
    }
  };

  const handleShowCandidateDetails = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: true, type: null, method: "CDF", data: null },
    });
    // navigate(`/${Cookies.get('mhet_mst_project')}/candidate-details?id=${EncryptObjectFunction({ candidateid: candidateTicketList[0]?.candidateid, ticketid: candidateTicketList[0]?.ticketid })}`)
  };

  const handleCommentsChange = (e) => {
    setValue("comments", e.target.value || "");
  };

  return (
    <>
      <Grid>
        <Grid
          p={2}
          style={{
            minHeight: "74vh",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          {/* <Typography variant="h4" sx={{ fontSize: "24px" }}>
            Ticket Raiser details
          </Typography> */}
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Ticket Raised For
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                disabled
                fullWidth
                size="small"
                value={ticketDetails?.iscandidate === '1' ? 'Candidate' : 'Non-Candidate'}
                placeholder="Enter the name"
                sx={{ width: "100%" }}
              />
            </Grid>
            {ticketDetails?.iscandidate === '1' &&
              <>
                <Grid item md={3} lg={3} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Name
                  </Typography>
                </Grid>
                <Grid item md={9} lg={9} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    value={ticketDetails?.candidatename}
                    placeholder="Enter the name"
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </>
            }
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Info
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  disabled
                  multiline
                  rows={3}
                  value={handleInfo()}
                  sx={{ width: "100%" }}
                />
                {ticketDetails?.iscandidate === '1' &&
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "10rem",
                      justifyContent: "space-between",
                    }}
                  >
                    {candidateTicketList?.length >= 1 && (
                      <Typography
                        variant="caption"
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        onClick={handlePreviousTicket}
                      >
                        Previous Tickets ({candidateTicketList?.length})
                      </Typography>
                    )}
                    <ViewButton
                      sx={{ mt: 1, backgroundColor: "#22A06B" }}
                      handleView={handleShowCandidateDetails}
                      name={showInfo ? "Hide" : "View"}
                    />
                  </Box>
                }
              </Box>
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Grievances
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                disabled
                multiline
                rows={2}
                value={ticketDetails?.grievancedetails}
                placeholder="Enter the grievances"
                sx={{ width: "100%" }}
              />
              {errors.grievances && (
                <Typography variant="body2" color="error">
                  {errors.grievances.message}
                </Typography>
              )}
            </Grid>
            <Grid item md={3} lg={3} xs={12}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Grievance Category
              </Typography>
            </Grid>
            <Grid item md={9} lg={9} xs={12}>
              <TextField
                disabled
                multiline
                rows={2}
                value={ticketDetails?.grievance}
                placeholder="Enter the grievances"
                sx={{ width: "100%" }}
              />
            </Grid>
            {ticketDetails?.comments?.length > 0 &&
              <>
                <Grid item md={3} lg={3} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Comments
                  </Typography>
                </Grid>
                <Grid className="raise_ticket_input" item md={9} lg={9} xs={12}>
                  <TicketComments
                    comments={ticketDetails?.comments}
                    handleCommentsChange={handleCommentsChange}
                  />
                </Grid>
              </>
            }
            {/* <Grid className="raise_ticket_input" item md={9} lg={9} xs={12}>
              {ticketDetails?.comments?.length > 0 ?
                <TicketComments
                  comments={ticketDetails?.comments}
                  handleCommentsChange={handleCommentsChange}
                />
                :
                <TextField
                  className="form-control-disabled"
                  multiline
                  rows={2}
                  defaultValue={'No Comments'}
                  disabled
                  // placeholder="Enter the comments"
                  // onChange={handleCommentsChange}
                  sx={{ width: "100%" }}
                />
              }
            </Grid> */}
            {((backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Coordinator") && (!ticketDetails?.assignedto)) ||
              (backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Resolution"))) &&
              // (backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Resolution") && watch("status")?.toString() === "3")) &&
              <>
                <Grid item md={3} lg={3} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Ticket response
                  </Typography>
                </Grid>
                <Grid item md={9} lg={9} xs={12}>
                  <TextField
                    multiline
                    rows={2}
                    placeholder="Enter the comments"
                    onChange={handleCommentsChange}
                    sx={{ width: "100%" }}
                    disabled={ticketDetails?.status === 5 ? true : false}
                  />
                  {/* <TextField
                multiline
                rows={2}
                placeholder="Enter the ticket response"
                disabled={!watch("showResponseEdit")?.isEditable}
                {...register("response")}
                sx={{ width: "100%" }}
              /> */}
                </Grid>
              </>
            }
            {ticketDetails?.comments?.length === 0 &&
              <>
                <Grid item md={3} lg={3} xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Comments
                  </Typography>
                </Grid>
                <Grid className="raise_ticket_input" item md={9} lg={9} xs={12}>
                  <TextField
                    className="form-control-disabled"
                    multiline
                    rows={2}
                    defaultValue={'No Comments'}
                    disabled
                    // placeholder="Enter the comments"
                    // onChange={handleCommentsChange}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </>
            }
            {backofficeTokenDetails?.role?.role?.find(
              (val) => val === "Grievance Resolution"
            ) && (
                <>
                  <Grid item md={3} lg={3} xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Ticket status
                    </Typography>
                  </Grid>
                  <Grid item md={9} lg={9} xs={12}>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={1}
                      rules={{
                        required: backofficeTokenDetails?.role?.role?.find(
                          (val) => val === "Grievance Resolution"
                        )
                          ? "Ticket status is required"
                          : false,
                        valueAsNumber: true,
                      }}
                      render={({ field }) => (
                        <Select
                          size="small"
                          {...field}
                          sx={{
                            width: "100%",
                            fontSize: "16px",
                            backgroundColor:
                              watch("status") === 1
                                ? "#e6f9ec"
                                : watch("status") === 3
                                  ? "#ffecea" :
                                  watch("status") === 5 ?
                                    `${cssProperties.bordercolor?.primary4blue}`
                                    : "inherit",
                          }}
                          disabled={ticketDetails?.status === 5 ? true : false}
                        >
                          <MenuItem value={1}>Open</MenuItem>
                          <MenuItem value={3}>Reassign</MenuItem>
                          <MenuItem value={4}>Hold</MenuItem>
                          <MenuItem value={5}>Close</MenuItem>
                        </Select>
                      )}
                    />
                    {errors?.status && (
                      <Typography variant="body2" color="error">
                        {errors.status.message}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            {((backofficeTokenDetails?.role?.role?.find(
              (val) => val === "Grievance Coordinator"
            ) &&
              !ticketDetails?.assignedto) ||
              // ticketDetails?.status === 1) ||
              (backofficeTokenDetails?.role?.role?.find(
                (val) => val === "Grievance Resolution"
              ) &&
                watch("status")?.toString() === "3")) && (
                <>
                  <Grid item md={3} lg={3} xs={12}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Assign To
                    </Typography>
                  </Grid>
                  <Grid item md={9} lg={9} xs={12}>
                    <Controller
                      name="assignedto"
                      control={control}
                      defaultValue={null}
                      rules={{ required: "User is required" }}
                      render={({ field }) => (
                        <FormControl fullWidth error={!!errors?.assignedto}>
                          <Select
                            size="small"
                            {...field}
                            value={field.value || ""}
                            renderValue={(selected) => {
                              const selectedOption =
                                adminusersListByRole?.filtered?.find(
                                  (option) => option.userid === selected
                                );
                              return selectedOption
                                ? selectedOption.fullname
                                : "";
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {adminusersListByRole?.filtered?.map((option) => (
                              <MenuItem key={option.userid} value={option.userid}>
                                {option.fullname}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.assignedto && (
                            <FormHelperText>
                              {errors.assignedto.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </>
              )}
          </Grid>
          {/* {
              (backofficeTokenDetails?.role?.role?.find(
                (val) => val === "Grievance Coordinator"
              ) || backofficeTokenDetails?.role?.role?.find(
                (val) => val === "Grievance Resolution"
              )) && */}
          <Grid
            mt={2}
            item
            sm={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            {((backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Help Desk")) ||
              (backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Coordinator") && ticketDetails?.assignedto)) ? (
              // ((backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Coordinator")) && (ticketDetails?.status !== 1))) ? (
              <BackButton handleBack={handleBack} name="Back" />
            ) : (
              (ticketDetails?.status === 5) ?
                <BackButton handleBack={() => navigate(`/${Cookies.get('mhet_mst_project')}/home`)} name="Back" /> :
                <MauvelousPrimaryButton
                  title={
                    buttonLoader ? (
                      <ButtonLoader />
                    ) : backofficeTokenDetails?.role?.role?.find(
                      (val) => val === "Grievance Coordinator"
                    ) ? (
                      "Assign"
                    ) : (
                      "Save"
                    )
                  }
                  handleClick={
                    backofficeTokenDetails?.role?.role?.find(
                      (val) => val === "Grievance Coordinator"
                    ) ||
                      backofficeTokenDetails?.role?.role?.find(
                        (val) => val === "Grievance Resolution"
                      )
                      ? handleSubmit(handleAssign)
                      : ""
                  }
                />
            )}
            {/* {ticketDetails?.status !== 1 ? (
              <BackButton handleBack={handleBack} name="Back" />) : (
                <MauvelousPrimaryButton
                title={
                  buttonLoader ? (
                    <ButtonLoader />
                  ) : backofficeTokenDetails?.role?.role?.find(
                      (val) => val === "Grievance Coordinator" || val === "Grievance Resolution"
                    ) ? (
                    "Assign"
                  ) : (
                    "Save"
                  )
                }
                handleClick={
                  backofficeTokenDetails?.role?.role?.find(
                    (val) => val === "Grievance Coordinator"
                  ) ||
                  backofficeTokenDetails?.role?.role?.find(
                    (val) => val === "Grievance Resolution"
                  )
                    ? handleSubmit(handleAssign)
                    : ""
                }
              />
              )} */}
          </Grid>
          {/* } */}

        </Grid>

        <PreviousTicketListSelectModel ticketsList={candidateTicketList} />
        <CandidateDetailsFormModal />
      </Grid>
    </>
  );
}
