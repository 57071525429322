import React, { useState } from "react";
import { Box, TextField, IconButton, InputAdornment, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
import ButtonLoader from "../../common/buttonLoader";
import { InputTrimAndLowercaseFunction, InputTrimFunction, handleEmailValidation, passwordValidation } from "../../utils/validation";
import { cssProperties } from "../../utils/commonCssProperties";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import PageHeading from "../../layouts/pageHeading";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import BackgroundImage from '../../assets/images/Background image.png'

export default function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [passState, setPassState] = useState(false);

    const onSubmit = (data) => {
        dispatch({
            type: authActions.AUTH_LOGIN,
            payload: { navigate: navigate, data: data },
        });
    };

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: '26px'
            }}
        >
            <Grid container sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
                <Grid item md={6} sm={11}

                > <Box
                    sx={{
                        borderRadius: "8px",
                        // padding: '16px',
                        backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                        height: '100%',
                        overflow:'hidden'
                    }}
                >
                    <img style={{width:'100%',height:'inherit',objectFit:'cover'}} src={BackgroundImage} alt="background" />
                    </Box></Grid>
                <Grid item md={6} sm={11}

                >
                    <Box
                        sx={{
                            borderRadius: "8px",
                            padding: '24px',
                            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,

                            height: '100%'
                        }}
                    >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{ marginBottom: '24px' }}>
                                <PageHeading title={'User Login'} />
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} 
                                // sm={6} md={6} lg={6}
                                >
                                    <TextField
                                    inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                                    InputLabelProps={{}}
                                        fullWidth
                                        // size="small"
                                        id="emailid"
                                        label={
                                            <span>
                                                Email id
                                                <span className="error" style={{ color: "red" }}>
                                                    {" "}
                                                    *
                                                </span>
                                            </span>
                                        }
                                        type="text"
                                        variant="outlined"
                                        error={!!errors.emailid}
                                        {...register("emailid", {
                                            required: "Email id is required",
                                            validate: handleEmailValidation,
                                        })}
                                        onInput={InputTrimAndLowercaseFunction}
                                        helperText={errors.emailid?.message}
                                        FormHelperTextProps={{
                                            style: { margin: 0 },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} 
                                // sm={6} md={6} lg={6}
                                >
                                    <TextField
                                    
                                        fullWidth
                                        // size="small"
                                        id="temppassword"
                                        type={passState ? "text" : "password"}
                                        label={
                                            <span>
                                                Password
                                                <span className="error" style={{ color: "red" }}>
                                                    {" "}
                                                    *
                                                </span>
                                            </span>
                                        }
                                        InputProps={{
                                            style:{height:'56px',boxSizing:'border-box'},
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setPassState(!passState)} sx={{ paddingRight: '0' }}>
                                                        {passState ? <FiEyeOff size={18} /> : <FiEye size={18} />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={!!errors.password}
                                        {...register("password", {
                                            required: 'Password is required',
                                            minLength: {
                                                value: 8,
                                                message: "Password must have at least 8 characters",
                                            },
                                            validate: passwordValidation
                                        })}
                                        onInput={InputTrimFunction}
                                        helperText={errors.password?.message}
                                        FormHelperTextProps={{
                                            style: { margin: 0 },
                                        }}
                                    />
                                    <Box display="flex" justifyContent="flex-end" sx={{ marginTop: '12px' }}>
                                        <Link
                                            to={`/${Cookies.get("mhet_cnd_project")}/forgot-password`}
                                            style={{
                                                textDecoration: "none",
                                                color: `${cssProperties?.bordercolor?.primary2blue}`,
                                                fontWeight: 500,
                                                fontSize: '14px',
                                                textTransform:'uppercase'
                                            }}
                                        >
                                            Forgot password
                                        </Link>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{ textAlign: 'center', marginTop: '24px' }}>
                                <BluePrimaryButton
                                    type="submit"
                                    title={buttonLoader ? <ButtonLoader /> : "Login"}
                                // handleSubmit={handleSubmit(onSubmit)}
                                />
                            </Box>
                        </form>
                    </Box>
                </Grid>


            </Grid>
        </Box>
    );
}
