import React from "react";
import Header from "../layouts/header";
import PageHeading from "../layouts/pageHeading";
import Index from "../components/raiseTicket";
import Sidebar from "../layouts/sidebar";
import Layout from "../layouts/layout";
import { Link as MuiLink, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";


export default function RaiseTicket() {

    const breadcrumbs = [
        <MuiLink underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_mst_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}>
            Dashboard
        </MuiLink>,
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Master Forms <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];


    return(
        <div>
                    <Sidebar />
            <Header breadcrumbs={breadcrumbs}/>
            <Layout >
            <PageHeading page={'raise a query'} />
            <Index/>
            </Layout>
        </div>
    )
}