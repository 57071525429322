import React from "react";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../redux/common/actions";
import { Box, Modal, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import CancelButton from "../../common/button/cancelButton";
import ButtonLoader from "../../common/buttonLoader";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: '24px',
    borderRadius: '8px'
};

export default function DeleteModal(props) {

    const dispatch = useDispatch();

    const { handleDelete } = props;

    const { buttonLoader, showModal } = useSelector(state => state.commonReducer);

    const handleClose = () => {
        dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: false, type: null, method: null, data: null } });
    };

    return (
        <Modal
            open={showModal?.show && showModal?.method === 'EM'}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {showModal?.type === 'User' &&
                    <Typography id="modal-modal-description"
                        sx={{
                            color: `${cssProperties?.fontcolor?.primary}`,
                            fontSize: '18px',
                            fontWeight: '500',
                            lineHeight: '26px',
                            textAlign: 'center'
                        }}
                    >
                        Are you sure you want to delete the user?
                    </Typography>
                }
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton handleBack={handleClose} />
                    <BluePrimaryButton title={buttonLoader ? <ButtonLoader /> : "Yes"} handleSubmit={() => handleDelete(showModal?.data)} />
                </Box>
            </Box>
        </Modal >
    )
};