import forcePushAllocationActions from "./actions";
import { takeEvery, all, put, call, delay, select } from 'redux-saga/effects';
import { API_URL } from "../../utils/constants";
import axios from "axios";
import commonActions from "../common/actions";

const forcePushAllocationSagas = function* () {
    yield all([
        yield takeEvery(forcePushAllocationActions.GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION, getCourseCandidateListForAllocation),
        yield takeEvery(forcePushAllocationActions.GET_VENUE_LIST_BY_DISTRICT, getVenueListByDistrict),
        yield takeEvery(forcePushAllocationActions.GET_EXAM_DATES_BY_COURSEID, getExamDatesByCourseid),
        yield takeEvery(forcePushAllocationActions.GET_EXAM_BATCH__BY_EXAMDATE, getExamBatchByExamDate),
        yield takeEvery(forcePushAllocationActions.FORCE_PUSH_ALLOCATION, forcePushAllocation),
        yield takeEvery(forcePushAllocationActions.FORCE_PUSH_CANCEL_ALLOCATION, forcePushCancelAllocation),
    ])
};

const getCourseCandidateListForAllocation = function* (data) {
    const { payload } = data;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        if (payload?.type === 'button') {
            yield put({
                type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                payload: { all: [], filtered: [] }
            });
        }
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/forcepush/getcandidates`, payload.data)
        );
        const { forcePushSelectedValues, candidateListForAllocation } = yield select(state => state.forcePushAllocationReducer);
        if (result?.data?.statusCode === 200) {
            yield put({ type: forcePushAllocationActions?.FORCEPUSH_SELECTED_VALUES, payload: { ...forcePushSelectedValues, totalcount: result?.data?.totalcount } })
            let allData = [...candidateListForAllocation?.all, ...result?.data?.result];
            let finalres = allData?.map((val, i) => {
                return { ...val, status: (val?.isallocated === "0") ? 'Not Allocated' : 'Allocated', selected: false, sno: i + 1 }
            });
            yield put({
                type: forcePushAllocationActions.SET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION,
                payload: {
                    all: (finalres?.length > 0) ? finalres : [],
                    filtered: (finalres?.length > 0) ? finalres : []
                }
            });
        }
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getVenueListByDistrict = function* (data) {
    const { payload } = data;

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    yield put({ type: forcePushAllocationActions.SET_VENUE_LIST_BY_DISTRICT, payload: { all: [], filtered: [] } });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/candidate/course/registraton/venue/${payload?.courseid}/${payload?.districtid}`)
        );
        if (result?.data?.statusCode === 200) {
            let filtered = result?.data?.result?.filter((val) => {
                if (val?.totalseats > val?.allocatedseats) {
                    return val;
                }
            })
            yield put({
                type: forcePushAllocationActions.SET_VENUE_LIST_BY_DISTRICT,
                payload: { all: result?.data?.result, filtered: filtered }
            });
        }
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

const getExamDatesByCourseid = function* (data) {
    const { payload } = data;

    yield put({ type: forcePushAllocationActions.SET_EXAM_DATES_BY_COURSEID, payload: [] });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/forcepush/getexamdates/${payload?.courseid}`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({
                type: forcePushAllocationActions.SET_EXAM_DATES_BY_COURSEID,
                payload: result?.data?.result
            });
        }
    } catch (err) {
        console.log(err);
    };
};

const getExamBatchByExamDate = function* (data) {
    const { payload } = data;

    yield put({ type: forcePushAllocationActions.SET_EXAM_BATCH__BY_EXAMDATE, payload: [] });
    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/forcepush/getexambatch/${payload?.courseexamid}`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({
                type: forcePushAllocationActions.SET_EXAM_BATCH__BY_EXAMDATE,
                payload: result?.data?.result
            });
        }
    } catch (err) {
        console.log(err);
    };
};

const forcePushAllocation = function* () {

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const { candidateListForAllocation, forcePushSelectedValues } = yield select(state => state.forcePushAllocationReducer);
        const { backofficeTokenDetails } = yield select((state) => state.authReducer);
        let candidatedata = candidateListForAllocation?.filtered?.filter((val) => {
            if (val?.selected) {
                return val;
            }
        })?.map((val) => {
            return {
                candidateid: parseInt(val?.candidateid),
                venueid: parseInt(forcePushSelectedValues?.venueid),
                courseid: parseInt(forcePushSelectedValues?.courseid),
                batchid: parseInt(forcePushSelectedValues?.exambatchid)
            }
        });
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/forcepush/allocation`, { data: candidatedata, userid: backofficeTokenDetails?.id })
        );
        if (result?.data?.statusCode === 200) {
            let isallocated = "0", isdisability = "0";
            if (forcePushSelectedValues?.buttonType === 'Allocated') {
                isallocated = "1";
                isdisability = "0";
            } else if (forcePushSelectedValues?.buttonType === 'Not Allocated') {
                isallocated = "0";
                isdisability = "0";
            } else if (forcePushSelectedValues?.buttonType === 'PH Allocated') {
                isallocated = "1";
                isdisability = "1";
            } else if (forcePushSelectedValues?.buttonType === 'PH Not Allocated') {
                isallocated = "0";
                isdisability = "1";
            };
            yield put({
                type: forcePushAllocationActions.GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION, payload: {
                    data: {
                        courseid: forcePushSelectedValues?.courseid,
                        isallocated: isallocated,
                        isdisability: isdisability,
                        offset: 0
                    },
                    type: 'button'
                }
            });
            yield put({
                type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
                payload: { ...forcePushSelectedValues, offset: 0, selectall: false, courseexamid: null, exambatchid: null, batchno: null }
            });
            yield put({
                type: commonActions.SET_SHOW_MODAL,
                payload: { show: false, type: null, method: null, data: null }
            });
        }
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
};

const forcePushCancelAllocation = function* () {

    yield put({ type: commonActions.SET_PAGE_LOADER, payload: true });
    try {
        const { candidateListForAllocation, forcePushSelectedValues } = yield select(state => state.forcePushAllocationReducer);
        const { backofficeTokenDetails } = yield select((state) => state.authReducer);
        let candidatedata = candidateListForAllocation?.filtered?.filter((val) => {
            if (val?.selected) {
                return val;
            }
        })?.map((val) => {
            return {
                candidateid: parseInt(val?.candidateid),
                venueid: parseInt(val?.allocatedvenueid),
                courseid: parseInt(val?.courseid),
                batchid: parseInt(0)
            }
        });
        const result = yield call(() =>
            axios.post(`${API_URL}/backoffice/forcepush/cancel`, { data: candidatedata, userid: backofficeTokenDetails?.id })
        );
        if (result?.data?.statusCode === 200) {
            let isallocated = "0", isdisability = "0";
            if (forcePushSelectedValues?.buttonType === 'Allocated') {
                isallocated = "1";
                isdisability = "0";
            } else if (forcePushSelectedValues?.buttonType === 'Not Allocated') {
                isallocated = "0";
                isdisability = "0";
            } else if (forcePushSelectedValues?.buttonType === 'PH Allocated') {
                isallocated = "1";
                isdisability = "1";
            } else if (forcePushSelectedValues?.buttonType === 'PH Not Allocated') {
                isallocated = "0";
                isdisability = "1";
            };
            yield put({
                type: forcePushAllocationActions.GET_COURSE_CANDIDATE_LIST_FOR_ALLOCATION, payload: {
                    data: {
                        courseid: forcePushSelectedValues?.courseid,
                        isallocated: isallocated,
                        isdisability: isdisability,
                        offset: 0
                    },
                    type: 'button'
                }
            });
            yield put({
                type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
                payload: { ...forcePushSelectedValues, offset: 0, selectall: false, courseexamid: null, exambatchid: null, batchno: null }
            });
        }
        yield put({
            type: commonActions.SET_ALERT,
            payload: {
                status: (result?.data?.statusCode === 200) ? "success" : "failed",
                show: true,
                message: result?.data?.message,
            },
        });
        yield delay(2000);
        yield put({
            type: commonActions.SET_ALERT,
            payload: { status: null, show: false, message: null },
        });
        yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
    } catch (err) {
        console.log(err);
    };
    yield put({ type: commonActions.SET_PAGE_LOADER, payload: false });
};

export default forcePushAllocationSagas;