import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import forcePushAllocationActions from "../../redux/forcePushAllocation/actions";
import CancelButton from "../../common/button/cancelButton";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import commonActions from "../../redux/common/actions";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: `${cssProperties?.backgroundcolor?.secondary}`,
    padding: "24px",
    borderRadius: "8px",
};

export default function AllocationModal(props) {

    const dispatch = useDispatch();

    const { showModal } = useSelector(state => state.commonReducer);

    const { examdateByCourse, exambatchByDate, forcePushSelectedValues } = useSelector(state => state.forcePushAllocationReducer);

    const handleChangeExamDate = (e) => {
        dispatch({
            type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
            payload: { ...forcePushSelectedValues, courseexamid: e.target.value }
        });
        dispatch({
            type: forcePushAllocationActions.GET_EXAM_BATCH__BY_EXAMDATE,
            payload: { courseexamid: e.target.value }
        });
    };

    const handleChangeBatch = (e) => {
        let batchno = exambatchByDate?.find(val => parseInt(val?.exambatchid) === parseInt(e.target.value))?.batchno
        dispatch({
            type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
            payload: { ...forcePushSelectedValues, exambatchid: e.target.value, batchno: batchno }
        });
    };

    const handleAllocateVenue = () => {
        dispatch({ type: forcePushAllocationActions.FORCE_PUSH_ALLOCATION });
    };

    const handleClose = () => {
        dispatch({
            type: forcePushAllocationActions.FORCEPUSH_SELECTED_VALUES,
            payload: { ...forcePushSelectedValues, courseexamid: null, exambatchid: null, batchno: null }
        });
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { show: false, type: null, method: null, data: null }
        });
    };

    return (
        <Modal
            open={showModal?.show && showModal?.type === "FPA"}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography
                    variant="h5"
                    sx={{
                        color: `#212121`,
                        fontWeight: 500,
                        fontSize: 20,
                        marginBottom: "18px",
                    }}
                >
                    Allocate Venue
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={12} sx={{ width: '100%' }}>
                        <FormControl fullWidth>
                            <InputLabel id="state-label" shrink>Select Exam Date <span className="error" style={{ color: "red" }}>*</span></InputLabel>
                            <Select
                                label="Select Exam date"
                                defaultValue={""}
                                onChange={handleChangeExamDate}
                            >
                                <MenuItem value="" disabled>--SELECT--</MenuItem>
                                {examdateByCourse?.map((val, i) => (
                                    <MenuItem key={i} value={val?.courseexamid}>{val?.examdate}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} sx={{ width: '100%' }}>
                        <FormControl fullWidth>
                            <InputLabel id="state-label" shrink>Select Exam Batch <span className="error" style={{ color: "red" }}>*</span></InputLabel>
                            <Select
                                label="Select Exam Batch"
                                defaultValue={""}
                                onChange={handleChangeBatch}
                                disabled={forcePushSelectedValues?.courseexamid ? false : true}
                            >
                                <MenuItem value="" disabled>--SELECT--</MenuItem>
                                {exambatchByDate?.map((val, i) => (
                                    <MenuItem key={i} value={val?.exambatchid}>{val?.batchname}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '12px', marginTop: '32px' }}>
                    <CancelButton
                        variant="outlined"
                        handleBack={handleClose}
                    />
                    <BluePrimaryButton
                        title={"Allocate"}
                        handleSubmit={handleAllocateVenue}
                        disabled={(forcePushSelectedValues?.courseexamid && forcePushSelectedValues?.exambatchid) ? false : true}
                    />
                </Box>
            </Box>
        </Modal >
    )
}
