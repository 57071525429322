import React from "react";
// import Sidebar from "../layouts/sidebar";
import Header from "../layouts/header";
import { Link as MuiLink, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown } from "react-icons/md";
import { cssProperties } from "../utils/commonCssProperties";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";
import Cookies from "js-cookie";
import Index from "../components/createProject";
import Sidebar from "../layouts/sidebar";

export default function CreateProject() {

    const breadcrumbs = [
        <MuiLink underline="hover" key="1" component={NavLink} to={`/admin/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}>
            Dashboard
        </MuiLink>,
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            Create project <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];

    return (
        <>
            <Layout>
                <Sidebar />
                <Header breadcrumbs={breadcrumbs} />
                <PageHeading title={'Create project'} />
                <Index />
            </Layout>
        </>
    )
}