import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import CourseTable from "./courseTable";
import AddCourse from "./addCourse";
import CreateCourse from "./createCourse";

export default function Index() {
  const naviagte = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  // const [course, setCourse] = useState("courselist");

  // useEffect(() => {
  //   setCourse(page);
  // }, [page]);

  return (
    <Grid
      p={page === "courselist" ? "none" : 3}
      sx={{
        minHeight: "50vh",
        backgroundColor:
          page === "courselist"  ? "none" : "#fff",
        borderRadius: "8px",
      }}
    >
      {page === "courselist" && (
        <Grid container>
          <Grid
            item
            md={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MauvelousPrimaryButton
              title={"Add Course"}
              handleClick={() =>
                naviagte(
                  `/${Cookies.get(
                    "mhet_mst_project"
                  )}/course-management?page=addcourse`
                )
              }
            />
          </Grid>
          <Grid item md={12}>
            <CourseTable />
          </Grid>
        </Grid>
      )}
      {page === "addcourse" && <AddCourse />}
      {page === "createcourse" && <CreateCourse />}
      {page === "updatecourse" && <AddCourse />}
    </Grid>
  );
}
