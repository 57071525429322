import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import Searchbar from "../../common/searchbar";
import { itemsPerPage } from "../../utils/constants";
import masterFormsActions from "../../redux/masterForms/actions";
import commonActions from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../common/dataTable";
import TablePagination from "../../common/tablePagination";
import CreateMasterModal from "./modals/createMasterModal";
import { useForm } from "react-hook-form";
import EditMasterModal from "./modals/editMasterModal";
import { PiPlusBold } from "react-icons/pi";

export default function CoursefieldMaster() {
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
    trigger
  } = useForm();

  const tableKeys = [
    {
      name: "SI.NO",
      accessor: "id",
      sortOption: false,
    },
    {
      name: "Field Name",
      accessor: "fieldname",
      sortOption: false,
    },
    {
      name: "Tab Name",
      accessor: "tabname",
      sortOption: false,
    },
    {
      name: "Patrent Field",
      accessor: "fieldnameparent",
      sortOption: false,
    },
  ];

  const dispatch = useDispatch();
  const { coursefieldList } = useSelector((state) => state.masterFormsReducer);
  const { coursetabList } = useSelector((state) => state.masterFormsReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const handleCreate = (data) => {
    data["fieldname"] = data.name;
    data["status"] = "1";
    data["parentfieldid"] = data.parentfieldid;
    data["createdby"] = parseInt(backofficeTokenDetails?.id);
    console.log(data);
    dispatch({
      type: masterFormsActions.CREATE_COURSEFIELD,
      payload: { data: data, resetForm: resetForm },
    });
  };

  const handleUpdate = (data) => {
    data["fieldname"] = data.name;
    data["status"] = "1";
    data["updatedby"] = parseInt(backofficeTokenDetails?.id);
    console.log(data);
    dispatch({
      type: masterFormsActions.UPDATE_COURSEFIELD,
      payload: { data: { ...data, id: data.coursefieldid }, resetForm: resetForm },
    });
};

  const resetForm = () => {
    reset({
      tabname: "",
    });
  };

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = coursefieldList?.filtered?.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(coursefieldList?.filtered?.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    let filtered = [];
    setCurrentPage(1);
    setItemOffset(0);
    if (e.target.value) {
      filtered = coursefieldList?.all?.filter((val) => {
        if (
          val?.fieldname
            ?.toLowerCase()
            .includes(e.target.value?.toLowerCase()) 
        ) {
          return val;
        }
      });
    } else {
      filtered = [...coursefieldList?.all];
    }
    dispatch({
      type: masterFormsActions.SET_COURSEFIELD,
      payload: { ...coursefieldList, filtered: filtered },
    });
  };

  const handleshowModal = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: true, type: "coursefield", method: "C", data: null },
    });
  };

  const handleMasteredit = (page, value) => {
    dispatch({ type: commonActions.SET_SHOW_MODAL, payload: { show: true, type: page, method: 'U', data: null } });
    reset({ ...value, name: value?.fieldname });
};

  useEffect(() => {
    dispatch({ type: masterFormsActions.GET_COURSEFIELD });
    dispatch({ type: masterFormsActions.GET_COURSETAB });
  }, []);

  return (
    <>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '24px', marginBottom: '14px' }}>
        {/* <Box></Box> */}
        <Searchbar height={48} handleSearch={handleSearch} />
        <MauvelousPrimaryButton title={"Add"} icon={<PiPlusBold />} handleClick={handleshowModal} />
      </Box>
      <Box sx={{ height: 'calc(100vh - 280px)' }}>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={"coursefields"}
        handleMasteredit={handleMasteredit}
      />
      </Box>
       <Box sx={{ position: 'fixed', bottom: 0, right: '24px',padding:'15px 0', width: '100%', py: 1 }}>
                    <TablePagination

                        pageCount={pageCount}
                        handlePageChange={handlePageChange}
                        currentPage={currentPage}
                    />
                </Box>
      <CreateMasterModal
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        coursetabList={coursetabList}
        coursefieldList={coursefieldList}
        handleCreate={handleCreate}
        resetForm={resetForm}
        control={control}
      />
      <EditMasterModal
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        handleUpdate={handleUpdate}
        resetForm={resetForm}
        control={control}
        coursetabList={coursetabList}
        coursefieldList={coursefieldList}
        setValue={setValue}
        getValues={getValues}
        trigger={trigger}
      />
    </>
  );
}
