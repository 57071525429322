import { put, call, all, takeEvery } from "redux-saga/effects";
import candidateActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";

const candidateSagas = function* () {
    yield all([
        yield takeEvery(candidateActions.GET_FULL_CANDIDATE_DETAILS, getFullCandidateDetails),
        yield takeEvery(candidateActions.GET_TICKETS_LIST_BY_CANDIDATE, getTicketsListByCandidate),
    ])
};

const getFullCandidateDetails = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/candidate/details/${payload?.id}`)
        );
        yield put({
            type: candidateActions.SET_FULL_CANDIDATE_DETAILS,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : []
        });
    } catch (err) {
        console.log(err);
    };
};

const getTicketsListByCandidate = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/grievance/ticket/candidate/${payload?.id}`)
        );
        yield put({
            type: candidateActions.SET_TICKETS_LIST_BY_CANDIDATE,
            payload: result?.data?.statusCode === 200 ? result?.data?.result : []
        });
    } catch (err) {
        console.log(err);
    };
};

export default candidateSagas;