import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import ButtonLoader from "../buttonLoader";

export default function MauvelousPrimaryButton({ title, handleClick,icon, disabled,type }) {

    return (
        <Button
            type={type ? type : 'button'}
            variant="contained"
            disableElevation
            sx={{
                borderRadius: '40px',
                padding: '10px 24px',
                backgroundColor: `${cssProperties?.buttonbgcolor?.primary}`,
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '15.4px',
                color: `${cssProperties?.buttonfontcolor?.black}`,
                border: '1px solid transparent',
                letterSpacing: '0.32px',
                boxShadow: 'none',
                whiteSpace: 'nowrap',
                '&:hover': {
                    backgroundColor: `${cssProperties?.buttonbgcolor?.primary}`,
                    color: `${cssProperties?.buttonfontcolor?.black}`,
                    boxShadow: 'none'
                },
            }}
            onClick={title !== 'loader' && handleClick}
            startIcon={icon}
            disabled={disabled ? disabled : false}
        >
            {title === 'loader' ?
                <ButtonLoader color={cssProperties?.surface?.primary} /> :
                title
            }
        </Button>
    )
}