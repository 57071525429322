import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import {
    ClassicEditor,
    Bold,
    Essentials,
    Heading,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Paragraph,
    Table,
    Undo,
    ImageToolbar,
    Image,
    ImageUpload,
    ImageStyle,
    Base64UploadAdapter,
    FontFamily,
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import './style.css'
import { useRef, useState } from 'react';

export default function Prerequesite({showModal,handleClose,setContent, preRequisiteContent,page}) {
    // const [editorContent, setEditorContent] = useState('');
    const editorRef = useRef(null);

    const handleSubmit = () => {
        if (editorRef.current) {
            const editor = editorRef.current.editor;
            const htmlData = editor.getData();
            console.log(htmlData);
            // setEditorContent(htmlData);
            setContent(htmlData)
            handleClose()
        }
    };
    
    return (
        <Modal
      open={showModal}
      // onClose={handleClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid>
        <Grid container p={2} mt={2} >
          <Grid item sm={12} md={12} lg={12} p={1} bgcolor={"#ffffff"} boxShadow={1} borderRadius={'4px'} minHeight={'500px'}>
            <Typography variant='h4'>{page === "scorecard"? "Score card Editor" : "Pre request Editor"}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '450px' }}>
  
            <CKEditor

                
                editor={ClassicEditor}
                config={{
                    
                    toolbar: [
                        'undo', 'redo', '|',
                        'heading', 'fontFamily','|', 'bold', 'italic', '|',
                        'link', 'imageUpload', 'insertTable', 'imageTextAlternative', '|',
                        'bulletedList', 'numberedList', 'indent', 'outdent'
                    ],
                    plugins: [
                        Bold,
                        Essentials,
                        Heading,
                        Indent,
                        IndentBlock,
                        Italic,
                        Link,
                        List,
                        Paragraph,
                        Table,
                        Undo,
                        Image,
                        ImageUpload,
                        ImageToolbar,
                        ImageStyle,
                        FontFamily,
                    ],
                    initialData: preRequisiteContent ? preRequisiteContent :'',
                    image: {
                        toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
                        styles: [
                            'full',
                            'side'
                        ],
                        resizeUnit: 'px'
                    },
                    extraPlugins: [ Base64UploadAdapter ],
                    
                }}
                onReady={(editor)=>{
                    editorRef.current = { editor };  
                }}
                
            />
      <Box mt={2} display={'flex'} justifyContent={"flex-end"} alignItems={"flex-end"}>
                <Button variant='outlined' onClick={handleSubmit}> Submit</Button>
              </Box>
            </Box>

          </Grid>

        </Grid>
      </Grid>
    </Modal>
    );
}
