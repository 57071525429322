import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { FaDownload } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import ticketsActions from "../../redux/tickets/actions";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { EncryptFunction } from "../../utils/cryptoFunction";
import { ExportToExcel } from "../../services/exportToExcel";
import moment from "moment-timezone";
import { DetailsLimitation } from "../../utils/validation";
import ViewImageModal from "../modals/viewImageModal";
import Searchbar from "../../common/searchbar";
import { Grid } from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { cssProperties } from "../../utils/commonCssProperties";
import { PiArrowDownBold, PiArrowDownThin, PiArrowUpBold, PiArrowUpThin } from "react-icons/pi";
import { itemsPerPage } from "../../utils/constants";
import TablePagination from "../../common/tablePagination";
import commonActions from "../../redux/common/actions";

const tableheadstyle = {
  color: `${cssProperties?.color?.primary}`,
  borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '17.6px',
  letterSpacing: '0.064px',
}

const tablebodystyle = {
  paddingTop: '16px',
  paddingBottom: '20px',
  color: `${cssProperties?.fontcolor?.tableblack}`,
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '17.6px',
  letterSpacing: '0.038px',
  borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
}

const queryList_Closed = {
  color: 'rgba(34, 160, 107, 1)'
}

const queryList_Assigned = {
  color: `${cssProperties?.bordercolor?.primary3blue}`
}
const queryList_Reassigned = {
  color: `${cssProperties?.bordercolor?.primary3blue}`
}

const queryList_Open = {
  backgroundColor: 'rgb(241 91 80)',
  padding: '8px 12px',
  borderRadius: '8px',
  color: 'white',
  width: 'fit-content',
  cursor: 'pointer',
};

const queryListMap = {
  'Closed': queryList_Closed,
  'Open': queryList_Open,
  'Assigned': queryList_Assigned,
  'Reassigned': queryList_Reassigned
};

export default function Index() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tableKeys, setTableKeys] = useState([
    {
      name: "SN",
      accessor: "id",
      sortOption: true,
      defaultSort: "A",
    },
    {
      name: "Ticket Id",
      accessor: "ticketcode",
      sortOption: true,
    },
    {
      name: "Ticket raised date",
      accessor: "ticketraisedon",
      sortOption: true,
      defaultSort: null,
    },
    {
      name: "# of days",
      accessor: "noofdays",
      sortOption: true,
      defaultSort: null,
    },
    {
      name: "Raised by",
      accessor: "createdbyname",
      sortOption: true,
      defaultSort: null,
    },
    {
      name: "Category",
      accessor: "category",
      sortOption: true,
      defaultSort: null,
    },
    {
      name: "Grievance Category",
      accessor: "grievance",
      sortOption: true,
      defaultSort: null,
    },
    {
      name: "Grievance Details",
      accessor: "grievancedetails",
      sortOption: false,
      defaultSort: null,
    },
    {
      name: "File",
      accessor: "documentname",
      sortOption: false,
      defaultSort: null,
    },
    {
      name: "Current status",
      accessor: "status",
      sortOption: false,
      colorChange: "ticketStatus",
      defaultSort: null,
    },
    {
      name: "Assigned to",
      accessor: "assignedtoname",
      sortOption: false,
      defaultSort: null,
    },
  ]);
  const [showModal, setShowModal] = useState({
    show: false,
    document: null,
    documentname: null,
  });
  // const trp = sessionStorage.getItem('trp');
  // const [itemOffset, setItemOffset] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);

  const { ticketsList, setFilters } = useSelector((state) => state.ticketsReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const { paginationValue } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    if (backofficeTokenDetails) {
      dispatch({ type: ticketsActions.GET_TICKETLIST_BY_USER });
    }
    //eslint-disable-next-line
  }, [backofficeTokenDetails]);

  // useEffect(() => {
  //   if (backofficeTokenDetails) {
  //     if((backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution'))){
  //       let keysOfTable = tableKeys?.splice(-1, 1);
  //       console.log(keysOfTable)
  //     }
  //   }
  //   //eslint-disable-next-line
  // }, [backofficeTokenDetails, tableKeys]);

  const endOffset = (paginationValue?.path && paginationValue?.path === 'tickets' ? paginationValue?.itemOffset : 0) + itemsPerPage;
  const currentItems = ticketsList?.filtered?.slice((paginationValue?.path && paginationValue?.path === 'tickets' ? paginationValue?.itemOffset : 0), endOffset);
  const pageCount = Math.ceil(ticketsList?.filtered?.length / itemsPerPage);
  // useEffect(()=>{
  //     const newOffset = (trp - 1) * itemsPerPage;
  //     setItemOffset(newOffset);
  //     setCurrentPage(trp);
  // },[])
  const handlePageChange = (event, value) => {
    // sessionStorage.setItem('trp', value)
    const newOffset = (value - 1) * itemsPerPage;
    // setItemOffset(newOffset);
    dispatch({ type: commonActions.SET_PAGINATION_VALUE, payload: { currentPage: value, itemOffset: newOffset, path: 'tickets' } })
    // setCurrentPage(value);
  };

  const handleSearch = (e) => {
    // setCurrentPage(1);
    dispatch({ type: commonActions.SET_PAGINATION_VALUE, payload: { currentPage: 1, itemOffset: 0, path: 'tickets' } })
    // setItemOffset(0);
    dispatch({
      type: ticketsActions.SET_FILTERS,
      payload: { ...setFilters, searchText: e.target.value || null },
    });
    dispatch({
      type: ticketsActions.HANDLE_TICKETS_FILTER,
      payload: { ...setFilters, searchText: e.target.value || null },
    });
  };

  const handleFilterBy = (e) => {
    // setCurrentPage(1);
    dispatch({ type: commonActions.SET_PAGINATION_VALUE, payload: { currentPage: 1, itemOffset: 0, path: 'tickets' } })
    // setItemOffset(0);
    dispatch({
      type: ticketsActions.SET_FILTERS,
      payload: { ...setFilters, filterBy: e.target.value || "All" },
    });
    dispatch({
      type: ticketsActions.HANDLE_TICKETS_FILTER,
      payload: { ...setFilters, filterBy: e.target.value || "All" },
    });
  };

  const handleSorting = (val, ind) => {
    let array = [...tableKeys];
    let tableArray = array.map((t, i) => {
      if (ind !== i) {
        return {
          ...t,
          defaultSort: null,
        };
      } else {
        return t;
      }
    });
    tableArray[ind]["defaultSort"] === "A"
      ? (tableArray[ind]["defaultSort"] = "D")
      : (tableArray[ind]["defaultSort"] = "A");
    setTableKeys([...tableArray]);
    dispatch({
      type: ticketsActions.SET_FILTERS,
      payload: {
        ...setFilters,
        sort: {
          col: tableArray[ind]["accessor"],
          order: tableArray[ind]["defaultSort"],
        },
      },
    });
    dispatch({
      type: ticketsActions.HANDLE_TICKETS_FILTER,
      payload: {
        ...setFilters,
        sort: {
          col: tableArray[ind]["accessor"],
          order: tableArray[ind]["defaultSort"],
        },
      },
    });
  };

  const handleExport = () => {
    let data = ticketsList?.filtered?.map((val, i) => {
      return {
        "S.No": i + 1,
        "Ticket raised date": val?.ticketraisedon,
        "# of days": val?.noofdays,
        "Raised by": val?.createdbyname,
        "Category": val?.category,
        "Grievance Category": val?.grievance,
        "Details": val?.grievancedetails,
        "Current status": val?.status,
        "Assigned to": val?.assignedtoname
      };
    });
    let fileName = `Ticketlist_${moment().format("DD-MM-YYYY")}`;
    ExportToExcel({ data, fileName });
  };

  const handleViewMore = (ticketid) => {
    navigate(
      `/${Cookies.get(
        "mhet_mst_project"
      )}/ticket-details?ticket=${EncryptFunction(ticketid)}`
    );
  };

  const handleViewImage = (value) => {
    setShowModal({
      show: true,
      document: value?.document,
      documentname: value?.documentname,
    });
  };

  return (
    <>
      <Grid container spacing={7} pb={3} pt={1} sx={{ alignItems: 'center' }}>
        <Grid item md={7}>
          <Searchbar
            handleSearch={handleSearch}
            value={setFilters?.searchText || ""}
          />
        </Grid>
        <Grid item md={2.5}>
          <TextField
            select
            fullWidth
            size="small"
            label="Filter by"
            value={setFilters?.filterBy || "All"}
            onChange={handleFilterBy}
            sx={{ mr: 2 }}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Open">Open</MenuItem>
            <MenuItem value="Assigned">Assigned</MenuItem>
            <MenuItem value="Reassigned">Reassigned</MenuItem>
            <MenuItem value="Hold">Hold</MenuItem>
            <MenuItem value="Closed">Closed</MenuItem>
          </TextField>
        </Grid>
        <Grid item md={2.5}>
          <MauvelousPrimaryButton
            title="Export to Excel"
            icon={<FaDownload />}
            handleClick={handleExport}
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: 'transparent',
          boxShadow: 'none',
          border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`,
          borderRadius: '4px',
          padding: '24px 12px 16px 12px',
        }}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {tableKeys?.filter((val) => {
                if ((backofficeTokenDetails?.role?.role?.find(r => r === 'Grievance Resolution')) && val?.accessor !== 'assignedtoname') {
                  return val;
                } else if (backofficeTokenDetails?.role?.role?.find(r => r === 'Grievance Coordinator')) {
                  return val;
                }
              })?.map((val, i) => (
                <TableCell key={i} sx={{ ...tableheadstyle, whiteSpace: 'normal' }}>
                  {val?.name}
                  {val?.sortOption && (
                    <IconButton
                      size="small"
                      onClick={() => handleSorting(val, i)}
                      aria-label={`Sort by ${val.name}`}
                      sx={{ color: `${cssProperties?.color?.primary}` }}
                    >
                      {(val?.defaultSort && val?.defaultSort === 'A') ?
                        <PiArrowUpBold size={14} style={{ cursor: 'pointer' }} /> :
                        <PiArrowUpThin size={14} style={{ cursor: 'pointer' }} />
                      }
                      {(val?.defaultSort && val?.defaultSort === 'D') ?
                        <PiArrowDownBold size={14} style={{ cursor: 'pointer' }} /> :
                        <PiArrowDownThin size={14} style={{ cursor: 'pointer' }} />
                      }
                    </IconButton>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={tableKeys?.filter((val) => {
                    if ((backofficeTokenDetails?.role?.role?.find(r => r === 'Grievance Resolution')) && val?.accessor !== 'assignedtoname') {
                      return val;
                    } else if (backofficeTokenDetails?.role?.role?.find(r => r === 'Grievance Coordinator')) {
                      return val;
                    }
                  })?.length}
                  sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '600' }}
                >
                  No Records Found
                </TableCell>
              </TableRow>
            ) : (
              currentItems?.map((val, valueInd) => (
                <TableRow
                  key={valueInd}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {tableKeys?.filter((val) => {
                    if ((backofficeTokenDetails?.role?.role?.find(r => r === 'Grievance Resolution')) && val?.accessor !== 'assignedtoname') {
                      return val;
                    } else if (backofficeTokenDetails?.role?.role?.find(r => r === 'Grievance Coordinator')) {
                      return val;
                    }
                  })?.map((k) => (
                    <>
                      {(backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator') && (val[k?.accessor] === 'Open' && val?.assignedtoname)) ?
                        <TableCell sx={{ ...tablebodystyle, ...queryListMap['Assigned'] }}>
                          Assigned
                        </TableCell> :
                        (backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator') && val[k?.accessor] === 'Open') ?
                          <TableCell
                            sx={{ ...tablebodystyle }}
                            onClick={() => {
                              if (k?.accessor === 'status' && val[k?.accessor] === 'Open') {
                                navigate(`/${Cookies.get('mhet_mst_project')}/ticket-details?ticket=${EncryptFunction(val?.ticketid)}`)
                              }
                            }}
                          >
                            <Box sx={{ ...queryList_Open }}>
                              Assign To
                            </Box>
                          </TableCell> :
                          (backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution') && (val[k?.accessor] === 'Assigned' || val[k?.accessor] === 'Reassigned')) ?
                            <TableCell
                              sx={{ ...tablebodystyle }}
                              onClick={() => {
                                if (k?.accessor === 'status' && (val[k?.accessor] === 'Assigned' || val[k?.accessor] === 'Reassigned')) {
                                  navigate(`/${Cookies.get('mhet_mst_project')}/ticket-details?ticket=${EncryptFunction(val?.ticketid)}`)
                                }
                              }}
                            >
                              <Box sx={{ ...queryList_Open }}>
                                Open
                              </Box>
                            </TableCell> :
                            (k?.accessor === 'category' || k?.accessor === 'grievance') ?
                              <TableCell
                                sx={(k?.accessor === 'category') ? { ...tablebodystyle, color: `${cssProperties?.color?.primary}` } : (k?.accessor === 'grievance') ?
                                  { ...tablebodystyle, color: `${cssProperties?.color?.failure}` } : { ...tablebodystyle }}
                              >
                                {val[k?.accessor] ? val[k?.accessor] : "--"}
                              </TableCell> :
                              (k?.accessor === 'documentname') ?
                                <TableCell
                                  sx={val[k?.accessor] ? { ...tablebodystyle, color: `${cssProperties?.color?.primary}`, textDecoration: 'underline', cursor: 'pointer' } : { ...tablebodystyle }}
                                  onClick={() => handleViewImage(val)}
                                >
                                  {val[k?.accessor] ? "View" : "--"}
                                </TableCell> :
                                (k?.accessor === 'grievancedetails') ?
                                  <TableCell
                                    sx={{ ...tablebodystyle }}
                                    onClick={() => navigate(`/${Cookies.get('mhet_mst_project')}/ticket-details?ticket=${EncryptFunction(val?.ticketid)}`)}
                                  >
                                    {val[k?.accessor] ?
                                      <DetailsLimitation
                                        text={val[k?.accessor]}
                                        sx={val[k?.accessor] ? { color: `${cssProperties?.color?.primary}`, textDecoration: 'underline', cursor: 'pointer' } : {}}
                                        // className={val[k?.accessor] ? "ticketlist_view_uploads" : ""}
                                        handleViewMore={() => handleViewMore(val?.ticketid)}
                                      />
                                      : "--"}
                                  </TableCell> :
                                  <TableCell
                                    sx={{ ...tablebodystyle }}
                                    onClick={() => {
                                      if (k?.accessor === 'status' && val[k?.accessor] === 'Open') {
                                        navigate(`/${Cookies.get('mhet_mst_project')}/ticket-details?ticket=${EncryptFunction(val?.ticketid)}`)
                                      }
                                    }}
                                  >
                                    <Box sx={{
                                      ...(k?.colorChange === "ticketStatus" ? queryListMap[val[k?.accessor]] : ''),
                                      cursor:
                                        (backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Coordinator") && val[k?.accessor] === "Open") ||
                                          (backofficeTokenDetails?.role?.role?.find((val) => val === "Grievance Resolution") && val[k?.accessor] === "Assigned")
                                          ? "pointer" : "default",
                                      whiteSpace: (k?.accessor === "ticketraisedon") ? 'nowrap' : 'normal'
                                    }}
                                    >
                                      {val[k?.accessor] ? val[k?.accessor] : "--"}
                                    </Box>
                                  </TableCell>
                      }
                    </>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        pageCount={pageCount}
        handlePageChange={handlePageChange}
        currentPage={(paginationValue?.path && paginationValue?.path === 'tickets') ? paginationValue?.currentPage : 1}
      // currentPage={currentPage}
      />
      <ViewImageModal
        showModal={showModal?.show}
        document={showModal?.document}
        documentname={showModal?.documentname}
        handleClose={() =>
          setShowModal({ show: false, document: null, documentname: null })
        }
      />
    </>
  );
}
