import React, { useEffect, useState } from "react";
import DataTable from "../../common/dataTable";
import ViewImageModal from "../modals/viewImageModal";
import TablePagination from "../../common/tablePagination";
import { Box, Grid } from "@mui/material";
import ProjectEditModel from "./projectEditModel";
import ProjectViewModel from "./ProjectViewModel";
import Searchbar from "../../common/searchbar";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Index() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

const navigate = useNavigate();

  const [showModal, setShowModal] = useState({
    show: false,
    document: null,
    documentname: null,
  });

  const tableKeys = [
    {
      name: "Project Name",
      accessor: "projectname",
      sortOption: false,
    },
    {
      name: "Course Name",
      accessor: "coursename",
      sortOption: false,
    },
    {
      name: "Customer SPOC",
      accessor: "customerspoc",
      sortOption: false,
    },
    {
      name: "Contact No",
      accessor: "contactno",
      sortOption: false
    },
    {
      name: "Project Description",
      accessor: "projectdescription",
      sortOption: false
    },
    {
      name: "Exam Mode",
      accessor: "exammode",
      sortOption: false
    },
    {
      name: "View Logo",
      accessor: "viewlogo",
      sortOption: false
    },
  ];

const values = [
  {projectname:"01",coursename:"Test coursename 01",customerspoc:"test customerspoc 1",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"02",coursename:"Test coursename 02",customerspoc:"test customerspoc 2",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:false},
  {projectname:"03",coursename:"Test coursename 03",customerspoc:"test customerspoc 3",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"04",coursename:"Test coursename 04",customerspoc:"test customerspoc 4",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"05",coursename:"Test coursename 05",customerspoc:"test customerspoc 5",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"06",coursename:"Test coursename 01",customerspoc:"test customerspoc 1",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"07",coursename:"Test coursename 02",customerspoc:"test customerspoc 2",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:false},
  {projectname:"08",coursename:"Test coursename 03",customerspoc:"test customerspoc 3",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"09",coursename:"Test coursename 04",customerspoc:"test customerspoc 4",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
  {projectname:"10",coursename:"Test coursename 05",customerspoc:"test customerspoc 5",contactno:"8547546334",projectdescription:"Some proble test on some field some problem on some",exammode:"Loremipsum",enable:true},
]

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
};
const endOffset = itemOffset + itemsPerPage;
const currentItems = values?.slice(itemOffset,endOffset);
const pageCount = Math.ceil(values?.length / itemsPerPage);

const handleAddProject = ()=>{
  navigate(`/admin/create-project`)
  // navigate(`/${Cookies.get('mhet_mst_project')}/create-project`)
}

  return (
    <Grid>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px', marginBottom: '14px' }}>
                <Box></Box>
                {Searchbar({value:"search projects"})}
              {MauvelousPrimaryButton( {title:'Add Project',handleClick:handleAddProject})}
            </Box>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={"View projects"}
        // handleViewImage={handleViewImage}
        />
      <TablePagination
         pageCount={pageCount}
         handlePageChange={handlePageChange}
         currentPage={currentPage}
      /> 
    <ProjectEditModel/>
    <ProjectViewModel/>
    </Grid>
  );
}
