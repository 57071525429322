import React from "react";
import { AppBar, Box, Breadcrumbs, Stack, Toolbar, Typography } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import { MdNavigateNext, MdPerson } from "react-icons/md";
import { useSelector } from "react-redux";

export default function Header({ breadcrumbs }) {

    const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

    let userName = '';
    if (backofficeTokenDetails && backofficeTokenDetails?.name) {
        const shortendName = backofficeTokenDetails?.name?.trim()?.split(' ');
        if (shortendName?.length > 0) {
            userName = shortendName[0];
        }
    }

    return (
        <>
            <AppBar
                position="fixed"
                // position="auto"
                sx={{
                    width: `calc(100% - ${cssProperties?.drawerWidth}px)`, ml: `${cssProperties?.drawerWidth}px`,
                    height: `${cssProperties?.headerHight}px`,
                    border: 'none',
                    boxShadow: 'none',
                    backgroundColor: `${cssProperties?.backgroundcolor?.primary}`,
                    justifyContent: 'center',
                    zIndex:98
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CommonBreadcrumb breadcrumbs={breadcrumbs} />
                    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        {/* <MdOutlineNotifications size={24} style={{
                            height: "48px",
                            width: "48px",
                            borderRadius: "70px",
                            border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                            padding: "10px",
                            color: `${cssProperties?.color?.gray}`,
                        }} /> */}
                        <Box sx={{ position: 'relative' }}>
                            <MdPerson size={24} style={{
                                height: "48px",
                                width: "48px",
                                borderRadius: "70px",
                                border: `1px solid ${cssProperties?.bordercolor?.secondary}`,
                                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                                padding: "10px",
                                color: `${cssProperties?.color?.primary}`,
                            }} />
                            {/* <MdMenu size={10} style={{
                                position: 'absolute',
                                bottom: 0,
                                color: `${cssProperties?.color?.neutralgrey}`,
                                right: 0,
                                border: `0.5px solid ${cssProperties?.bordercolor?.secondary}`,
                                height: '16px',
                                width: '16px',
                                borderRadius: '14px',
                                padding: '3px',
                                backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
                                boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.21)'

                            }} /> */}
                        </Box>
                        <Typography sx={{ fontSize: 16, fontWeight: 500, color: "black" }}>
                            {userName}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

function CommonBreadcrumb({ breadcrumbs }) {

    return (
        <Stack spacing={2}>
            <Breadcrumbs
                separator={<MdNavigateNext size={16} />}
                aria-label="breadcrumb"
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    )
}