import React from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import authActions from '../../redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import ButtonLoader from '../../common/buttonLoader';
import { InputTrimAndLowercaseFunction, handleEmailValidation } from '../../utils/validation';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { cssProperties } from '../../utils/commonCssProperties';
import PageHeading from '../../layouts/pageHeading';
import { AiOutlineLeft } from 'react-icons/ai';
import BluePrimaryButton from '../../common/button/bluePrimaryButton';
import BackgroundImage from '../../assets/images/Background image.png'

export default function Index() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buttonLoader } = useSelector(state => state.commonReducer);

  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    dispatch({
      type: authActions.BACKOFFICE_FORGET_PASSWORD,
      payload: { navigate: navigate, data: data },
    });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: '26px'
      }}
    >


      <Grid container sx={{ display: 'flex', justifyContent: 'center' }} spacing={2}>
        <Grid item md={6} sm={11}

        > <Box
          sx={{
            borderRadius: "8px",
            // padding: '16px',
            backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
            height: '100%',
            overflow: 'hidden'
          }}
        >
            <img style={{ width: '100%', height: 'inherit', objectFit: 'cover' }} src={BackgroundImage} alt="background" />
          </Box></Grid>
        <Grid item md={6} sm={11}

        >

          <Box
            sx={{
              borderRadius: "8px",
              padding: '24px',
              backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,

              height: '100%'
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
              <Box sx={{ marginBottom: '8px',display: "flex", justifyContent: "flex-start", alignItems: "center", color: `${cssProperties?.color?.primary}`, cursor: 'pointer' }} onClick={() => navigate(`/${Cookies.get('mhet_cnd_project')}/`)}>
                <AiOutlineLeft size={14} />
                {/* <Typography variant='body1'> Back </Typography> */}
              </Box>
            </Box>
            <Box sx={{ marginBottom: '8px', textAlign:'center' }}>
              <PageHeading  title={'Forgot Password'} align="center" />
            </Box>


            <Grid container spacing={2} >
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  variant='outlined'
                  inputProps={{style:{height:'56px',boxSizing:'border-box'},maxLength: 50}}
                  label={
                    <span>
                      Email id
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    </span>
                  }
                  
                  error={!!errors.email}
                  {...register("email", {
                    required: "Email id is required",
                    validate: handleEmailValidation,
                  })}
                  onInput={InputTrimAndLowercaseFunction}
                  helperText={errors.email?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} sx={{textAlign:'center'}}>
                <BluePrimaryButton
                  type="submit"
                  title={buttonLoader ? <ButtonLoader /> : "Submit"}
                  handleSubmit={handleSubmit(onSubmit)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>


    </Box >
  );
}
