import React, { useEffect } from "react";
import Index from "../components/helpdeskLandingPage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function HelpDeskLandingPage() {

  const navigate = useNavigate();

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (
      backofficeTokenDetails?.role?.role?.find(
        (val) => val !== "Grievance Coordinator"
      ) &&
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Resolution"
      )
    ) {
      navigate(`/${Cookies.get("mhet_mst_project")}/ticket-list`);
    } else if (
      backofficeTokenDetails?.role?.role?.find(
        (val) => val !== "Grievance Coordinator"
      ) &&
      backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Help Desk"
      )
    ) {
      navigate(`/${Cookies.get("mhet_mst_project")}/raise-ticket`);
    }
    //eslint-disable-next-line
  }, [backofficeTokenDetails]);

  return (
    <>
      {/* <Sidebar />
      <Header breadcrumbs={breadcrumbs} />
      <Layout>
      <PageHeading title="Help desk" /> */}
      {backofficeTokenDetails?.role?.role?.find(
        (val) => val === "Grievance Coordinator"
      ) && <Index />}
      {/* </Layout> */}
      {/* {(backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Coordinator') ||
        backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution')) &&
        <Index />
      } */}
    </>
  );
}
