import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ticketsActions from "../../redux/tickets/actions";
import { formatDate } from "../../utils/validation";
import { Box, Grid, MenuItem, TextField } from "@mui/material";
import Searchbar from "../../common/searchbar";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import DataTable from "../../common/dataTable";
import TablePagination from "../../common/tablePagination";
import DeleteModal from "../modals/deleteModal";
import { itemsPerPage } from "../../utils/constants";

const tableKeys = [
  {
    name: "SI.NO",
    accessor: "id",
    sortOption: true,
    defaultSort: null,
  },
  {
    name: "Ticket Id",
    accessor: "ticketcode",
    sortOption: false,
  },
  {
    name: "Name",
    accessor: "candidatename",
    sortOption: true,
    defaultSort: null,
  },
  {
    name: "Grievance category",
    accessor: "grievance",
    sortOption: true,
    defaultSort: null,
  },
  {
    name: "Grievance raised on",
    accessor: "ticketraisedon",
    sortOption: true,
    defaultSort: null,
  },
  {
    name: "Grievance mode",
    accessor: "grievancetype",
    sortOption: true,
    defaultSort: null,
  },
  {
    name: "Details",
    accessor: "grievancedetails",
    sortOption: false,
    defaultSort: null,
  },
  {
    name: "Status",
    accessor: "status",
    sortOption: false,
    defaultSort: null,
    colorChange: 'ticketStatus'
  },
];

export default function Index() {

  const dispatch = useDispatch();

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const { raisedQueries } = useSelector((state) => state.ticketsReducer);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [select, setSelect] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (backofficeTokenDetails) {
      dispatch({ type: ticketsActions.GET_RAISED_QUERY });
    }
    //eslint-disable-next-line
  }, [backofficeTokenDetails]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = raisedQueries?.filtered?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(raisedQueries?.filtered?.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    const newOffset = (value - 1) * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(value);
  };

  const handleSearch = (e) => {
    let filtered = [];
    setCurrentPage(1);
    setItemOffset(0);
    setSearchText(e.target.value);
    if (e.target.value) {
      //eslint-disable-next-line
      filtered = raisedQueries?.all?.filter(val => {
        if (select === "mobileno" && val?.mobileno?.toLowerCase().includes(e.target.value?.toLowerCase())) {
          return val;
        } else if (select === "emailid" && val?.emailid?.toLowerCase().includes(e.target.value?.toLowerCase())) {
          return val;
        }else if(select === 'ticketcode' && val?.ticketcode?.toLowerCase().includes(e.target.value?.toLowerCase())){
          return val;
        }
      });
    } else {
      filtered = [...raisedQueries?.all];
    }
    dispatch({ type: ticketsActions.SET_RAISED_QUERY, payload: { ...raisedQueries, filtered: filtered } });
  };

  // const tableValues = raisedQueries?.filtered?.map((val, i) => ({
  //   id: i + 1,
  //   name: val.candidatename,
  //   grievance: val.grievance,
  //   createddate: formatDate(val.createddate),
  //   grievancecategory:
  //     val.tickettype === 2
  //       ? "By Call"
  //       : val.tickettype === 3
  //         ? "By Email"
  //         : "Open",
  //   grievancedetails: val.grievancedetails,
  // }));

  // const handleSearch = (e) => {
  //   setValue(e.target.value);
  //   let filtered = [];
  //   if (e.target.value) {
  // filtered = raisedQueries?.all?.filter(val => {
  //   if (select === "mobileno" && val?.mobileno?.toLowerCase().includes(e.target.value?.toLowerCase())) {
  //     return val;
  //   } else if (select === "emailid" && val?.emailid?.toLowerCase().includes(e.target.value?.toLowerCase())) {
  //     return val;
  //   }
  // });
  //   } else {
  //     filtered = [...raisedQueries?.all];
  //   }
  //   dispatch({ type: ticketsActions.SET_RAISED_QUERY, payload: { ...raisedQueries, filtered: filtered } })
  // };

  // const handleSearchClick = () => {
  //   dispatch({ type: ticketsActions.GET_RAISED_QUERY})
  // };

  return (
    <>
      {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px', marginBottom: '14px' }}>
        <Box></Box>
        <Searchbar handleSearch={handleSearch} />
      </Box> */}
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} sm={12}>
          <TextField
            select
            fullWidth
            size="small"
            label="Search by"
            onChange={(e) => {
              setSelect(e.target.value);
            }}
            value={select}
            sx={{ mr: 2 }}
          >
            <MenuItem value="mobileno">Mobile no</MenuItem>
            <MenuItem value="emailid">Email id</MenuItem>
            <MenuItem value="ticketcode">Ticket Id</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={7} md={7} sm={12}>
          <Searchbar handleSearch={handleSearch} value={searchText} />
        </Grid>
      </Grid>
      <DataTable
        keys={tableKeys}
        values={currentItems}
        page={'grievanceList'}
      // handleUserDelete={handleUserDelete}
      />
      <TablePagination
        pageCount={pageCount}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />
      {/* <DeleteModal
        handleDelete={handleDelete}
      /> */}
    </>
    // <Container fluid>
    //   <div className="section_background_color" style={{ margin: "1rem auto" }}>
    //     <Row className="mb-3">
    //       <Col md={12} sm={12} style={{ lineHeight: "16px" }}>
    //         <div className="table_heading_cls">Raised Grievance</div>
    //       </Col>
    //       <Col
    // lg={10}
    // md={9}
    // sm={12}
    //         className="grievance_on_call_list_search_text_col"
    //       >
    //         <select
    //           className="form-select grievanceoncall_select"
    //           placeholder="Filter by"
    // onChange={(e) => {
    //   setSelect(e.target.value);
    // }}
    //         >
    //           <option value={"mobileno"}>Mobile no</option>
    //           <option value={"emailid"}>Email id</option>
    //         </select>
    //         <div className="grievanceoncall_searchbar_div">
    //           <Searchbar
    //             value={value}
    //             placeholder={"Search"}
    //             handleSearch={(e) => handleSearch(e)}
    //           />
    //         </div>
    //       </Col>
    //       {/* <Col lg={2} md={3} sm={12} className="grievanceoncall_search_btn_col">
    //         <Button
    //           onClick={handleSearchClick}
    //           className="raise_ticket_search_btn"
    //         >
    //           Search
    //         </Button>
    //       </Col> */}
    //     </Row>
    //     <DataTable
    //       keys={tableKeys}
    //       values={tableValues}
    //       page={"grievanceList"}
    //     />
    //   </div>
    // </Container>
  );
}
