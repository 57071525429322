import React from "react";
import { Modal, Box, Grid, TextField, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../redux/common/actions";
import moment from "moment-timezone";
import CloseButton from "../../common/button/closeButton";

export default function DetailsModal({fullCandidateDetails}) {
  const dispatch = useDispatch();

  const { showModal } = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: false, type: null, method: "DM", data: null },
    });
  };

  return (
    <Modal
      open={showModal?.show && showModal?.method === "DM"}
      // onClose={handleClose}
      aria-labelledby="candidate-details-modal-title"
      aria-describedby="candidate-details-modal-description"
    >
      <Box
        p={2}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "80%",
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <h2 style={{ color: "#3F41D1" }} >
          Candidate Details
        </h2>
        <Box
          mt={2}
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Candidate Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.candidatename || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="E-mail ID"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.emailid || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile No"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.mobileno || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="DOB"
                variant="outlined"
                size="small"
                fullWidth
                value={
                  fullCandidateDetails?.dob
                    ? moment(fullCandidateDetails?.dob, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )
                    : ""
                }
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Gender"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.gender || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Father's Name"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.fathername || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mother's Name"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.mothername || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Religion"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.religion || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Region"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.region || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mother Tongue"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.mothertongue || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nationality"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.nationality || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Annual Family Income"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.familyincome || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Marital Status"
                variant="outlined"
                size="small"
                fullWidth
                value={
                  fullCandidateDetails?.maritalstatusid === 1
                    ? "Married"
                    : "Unmarried"
                }
                disabled
              />
            </Grid>
            {fullCandidateDetails?.spousename && (
              <Grid item xs={12} md={6}>
                <TextField
                  label="Spouse Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={fullCandidateDetails?.spousename || ""}
                  disabled
                />
              </Grid>
            )}
          </Grid>

          <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
            mt={2}
          >
            Permanent Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address Line1"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.paddressline1 || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address Line2"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.paddressline2 || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address Line3"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.paddressline3 || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="State"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.pstate || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="District"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.pdistrict || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Taluka"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.ptaluka || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Village"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.pvillage || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pincode"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.ppincode || ""}
                disabled
              />
            </Grid>
          </Grid>

          <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
            mt={2}
          >
            Address for Correspondence
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address Line1"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.caddressline1 || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address Line2"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.caddressline2 || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Address Line3"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.caddressline3 || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="State"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.cstate || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="District"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.cdistrict || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Taluka"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.ctaluka || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Village"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.cvillage || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Pincode"
                variant="outlined"
                size="small"
                fullWidth
                value={fullCandidateDetails?.cpincode || ""}
                disabled
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <CloseButton name={"Close"} handleClose={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
}
