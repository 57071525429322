const usersActions = {
    GET_ADMINUSER_LIST: 'GET_ADMINUSER_LIST',
    SET_ADMINUSER_LIST: 'SET_ADMINUSER_LIST',
    SET_ADMINUSER_BASEDON_ROLE: 'SET_ADMINUSER_BASEDON_ROLE',

    GET_ADMINUSER_DETAILS: 'GET_ADMINUSER_DETAILS',
    SET_ADMINUSER_DETAILS: 'SET_ADMINUSER_DETAILS',

    GET_ROLE_LIST: 'GET_ROLE_LIST',
    SET_ROLE_LIST: 'SET_ROLE_LIST',

    CREATE_ADMINUSER: 'CREATE_ADMINUSER',
    UPDATE_ADMINUSER: 'UPDATE_ADMINUSER',
    DELETE_ADMINUSER: 'DELETE_ADMINUSER',
};

export default usersActions;