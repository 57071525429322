import React from "react";
import { Button } from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";

export default function BrowseUploadButton({
  title,
  onChange,
  icon,
  id,
  name,
  accept,
  type
}) {
  return (
    <label htmlFor={id}>
      <input
        type="file"
        id={id}
        name={name}
        onChange={onChange}
        hidden
        accept={accept && accept}
      />
      <Button
        variant="contained"
        disableElevation
        component="span"
        sx={{
          borderRadius: "40px",
          padding: "10px 24px",
          backgroundColor: type === 'primary' ? `${cssProperties?.color?.primary}` : `${cssProperties?.buttonbgcolor?.primary}`,
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "15.4px",
          color: type === 'primary' ? "#fff" : `${cssProperties?.buttonfontcolor?.black}`,
          border: "1px solid transparent",
          letterSpacing: "0.32px",
          boxShadow: "none",
          whiteSpace: "nowrap",
          "&:hover": {
            backgroundColor: type === 'primary' ? `${cssProperties?.color?.primary}` : `${cssProperties?.buttonbgcolor?.primary}`,
            color: type === 'primary' ? "#fff" : `${cssProperties?.buttonfontcolor?.black}`,
            boxShadow: "none",
          },
        }}
        startIcon={icon}
      >
        {title}
      </Button>
    </label>
  );
}