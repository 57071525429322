import { all } from "redux-saga/effects";

import authSagas from "./auth/sagas";
import masterFormsSagas from "./masterForms/sagas";
import usersSagas from "./users/sagas";
import masterSagas from "./master/sagas";
import ticketsSagas from "./tickets/sagas";
import candidateSagas from "./candidate/sagas";
import courseSagas from "./course/sagas";
import venueSagas from "./venue/sagas";
import rollNumberSagas from "./rollNumber/sagas";
import forcePushAllocationSagas from "./forcePushAllocation/sagas";
import courseExamANdBatchSagas from "./courseExamAndBatch/sagas";
import customerSagas from "./customer/sagas";


export default function* rootSaga() {
    yield all([
        authSagas(),
        masterFormsSagas(),
        usersSagas(),
        masterSagas(),
        ticketsSagas(),
        candidateSagas(),
        courseSagas(),
        venueSagas(),
        rollNumberSagas(),
        forcePushAllocationSagas(),
        courseExamANdBatchSagas(),
        customerSagas(),
    ])
};