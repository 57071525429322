import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "../protectedRoute/publicRoute";
import { store } from '../redux/store';
import MainLandingPage from "../pages/mainLandingPage";
import ProjectRoutes from "./projectRoutes";
import Cookies from "js-cookie";
import NotFound from "../common/notfound";
import setAuthToken from "../utils/setAuthToken";
import authActions from "../redux/auth/actions";
import AdminRoutes from "./adminRoutes";

if (Cookies.get('mhet_mst_token')) {
  setAuthToken(Cookies.get('mhet_mst_token'));
  store.dispatch({ type: authActions.BACKOFFICE_TOKEN_VERIFY });
};

if (Cookies.get('mhet_mst_temptoken')) {
  store.dispatch({ type: authActions.BACKOFFICE_TOKEN_VERIFY });
};

const AppRoutes = () => {

  useEffect(() => {
    if (Cookies.get('mhet_mst_project') && window.location.pathname === '/') {
      if (Cookies.get('mhet_mst_token')) {
        window.location.href = `${`/${Cookies.get('mhet_mst_project')}/home`}`;
      } else {
        window.location.href = `${`/${Cookies.get('mhet_mst_project')}/`}`;
      };
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<PublicRoute><MainLandingPage /></PublicRoute>} />
        <Route path="/*" element={<PublicRoute><NotFound /></PublicRoute>} />
        <Route path="/admin/*" element={<AdminRoutes />} />
        <Route path={Cookies.get('mhet_mst_project') ? `${Cookies.get('mhet_mst_project')}/*` : '/*'} element={<ProjectRoutes />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
