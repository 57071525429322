const masterActions = {
    GET_PROJECT_LIST: 'GET_PROJECT_LIST',
    SET_PROJECT_LIST: 'SET_PROJECT_LIST',
    CREATE_PROJECT: 'CREATE_PROJECT',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    SET_GRIEVANCE : 'SET_GRIEVANCE',
    GET_GRIEVANCE : 'GET_GRIEVANCE',
    BULK_UPLOAD_GENDER_MASTER : 'BULK_UPLOAD_GENDER_MASTER',


    GET_COURSE_LIST: 'GET_COURSE_LIST',
    SET_COURSE_LIST: 'SET_COURSE_LIST',
    CREATE_COURSE: 'CREATE_COURSE',
    UPDATE_COURSE: 'UPDATE_COURSE',
    SET_ACTIVE_COURSE: 'SET_ACTIVE_COURSE',
    GET_ALL_BATCH: 'GET_ALL_BATCH',
    SET_ALL_BATCH: 'SET_ALL_BATCH',

};

export default masterActions;