import React from "react";
import Index from "../components/communicationManagement";
import Header from "../layouts/header";
import Sidebar from "../layouts/sidebar";
import Layout from "../layouts/layout";
import PageHeading from "../layouts/pageHeading";

export default function CommunicationManagement() {
  return (
    <>
      <Header />
      <Sidebar />
      <Layout>
        <PageHeading title={"Communication Management"} />
        <Index />
      </Layout>
    </>
  );
}
