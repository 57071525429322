import customerActions from './actions';

const initialState = {
    reports: {
        applicationstatuswisereports:[],
        candidaturetypewisereport:[],
        categorywisereport:[],
        statewisereport:[],
        religionwisereport:[],
        examcenterwisereport:[],
        regionwisereport:[],
        genderwisereport:[],
        pwdwisereport:[],
        districtwisereport:[],
        stepwisereport:[],
        
    },
};

const customerReducer = (state = initialState, action) => {

    switch (action.type) {
        case customerActions.SET_REPORTS:
            return {
                ...state,
                reports: action.payload
            }

        default:
            return state
    };
};

export default customerReducer;