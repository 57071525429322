import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../redux/common/actions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../utils/validation";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { EncryptFunction } from "../../utils/cryptoFunction";
import CloseButton from "../../common/button/closeButton";
import ViewButton from "../../common/button/viewButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "8px",
  maxHeight: "90vh",
  //   overflowY: "auto",
  //   '&::-webkit-scrollbar': {
  //     width: '8px',
  //   },
  //   '&::-webkit-scrollbar-thumb': {
  //     backgroundColor: 'rgba(0, 0, 0, 0.2)',
  //   },
};

export default function PreviousTicketListSelectModel({ ticketsList }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showModal } = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: false, type: null, method: "PTS", data: null },
    });
  };

  return (
    <Modal
      open={showModal?.show && showModal?.method === "PTS"}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 2,
          }}
        >
          <h2 style={{ color: "#3F41D1" }}>
            Previous Ticket Details
          </h2>
        </Box>
        <TableContainer
          sx={{
            boxShadow: "none",
            border: "1px solid #4B4DD4",
            maxHeight: "60vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
          component={Paper}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "5%", color: "#3F41D1" }}>
                  Ticket ID
                </TableCell>
                <TableCell sx={{ width: "20%", color: "#3F41D1" }} align="left">
                  Created Date
                </TableCell>
                <TableCell sx={{ width: "60%", color: "#3F41D1" }} align="left">
                  Description
                </TableCell>
                <TableCell sx={{ color: "#3F41D1" }} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ticketsList?.map((val, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell sx={{ width: "5%" }} component="th" scope="row">
                    {val?.ticketcode}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="left">
                    {formatDate(val.createddate)}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: "5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    align="left"
                  >
                    {val.grievancedetails}
                  </TableCell>
                  <TableCell align="right">
                    <ViewButton
                      name="view"
                      handleView={() => {
                        dispatch({
                          type: commonActions.SET_SHOW_MODAL,
                          payload: {
                            show: false,
                            type: null,
                            method: null,
                            data: null,
                          },
                        });
                        navigate(
                          `/${Cookies.get(
                            "mhet_mst_project"
                          )}/ticket-details?ticket=${EncryptFunction(
                            val?.ticketid
                          )}`
                        );
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <CloseButton handleClose={handleClose} name="Close" />
        </Box>
      </Box>
    </Modal>
  );
}
