import rollNumberActions from "./actions";

const initialState = {
  examDetails: {
    batches: [],
    dates: [],
  },
  batchCandidates: [],
  rollNoDownloadData:[],
};

const rollNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case rollNumberActions.SET_EXAM_DETAILS_WITH_COURSEID:
      return {
        ...state,
        examDetails: action.payload,
      };
    case rollNumberActions.SET_BATCH_CANDIDATES:
      return {
        ...state,
        batchCandidates: action.payload,
      };
      case rollNumberActions.SET_DOWNLOAD_ROLL_NO_DATA:
        return {
          ...state,
          rollNoDownloadData: action.payload,
        };

    default:
      return state;
  }
};

export default rollNumberReducer;
