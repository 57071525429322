import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DecryptFunction } from '../../utils/cryptoFunction';
import { useDispatch, useSelector } from 'react-redux';
import usersActions from '../../redux/users/actions';
import { AlphabetsValidation, InputTrimAndLowercaseFunction, MobileNumberFirstNumberValidation, MobileNumberValidation, handleEmailValidation } from '../../utils/validation';
import { Autocomplete, FormControl, Grid, TextField } from "@mui/material";
import Cookies from 'js-cookie';
import MauvelousPrimaryButton from '../../common/button/mauvelousPrimaryButton';
import BackButton from "../../common/button/backButton";

export default function Index() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = searchParams.get('user');

  const { roleList, adminuserDetails } = useSelector(state => state.usersReducer);

  const { backofficeTokenDetails } = useSelector(state => state.authReducer);

  const { buttonLoader } = useSelector(state => state.commonReducer);

  const [open, setOpen] = useState(false);

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();

  useEffect(() => {
    if (DecryptFunction(user)) {
      dispatch({ type: usersActions.GET_ADMINUSER_DETAILS, payload: DecryptFunction(user) });
    } else {
      navigate(`/${Cookies.get('mhet_mst_project')}/users`);
    };
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    dispatch({ type: usersActions.GET_ROLE_LIST });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (roleList?.filtered?.length > 0) {
      let roleids = adminuserDetails?.roleids?.split(','), role = [];
      for (let i = 0; i < roleids?.length; i++) {
        role.push(roleList?.filtered?.find(val => val?.roleid === parseInt(roleids[i]))?.rolename);
      };
      reset({
        ...adminuserDetails,
        role: role
      });
    }
    //eslint-disable-next-line
  }, [adminuserDetails, roleList?.filtered]);

  const onSubmit = (data) => {
    let ids = data['role']?.map((val) => {
      return roleList?.filtered?.find((r) => r?.rolename === val)?.roleid
    });
    let oldarr = adminuserDetails?.roleids?.split(',')?.map(role => parseInt(role));
    let deletedids = [], newids = [];
    deletedids = oldarr.filter(item => !ids.includes(item));
    newids = ids.filter(item => !oldarr.includes(item));
    
    data['role'] = newids?.length > 0 ? newids?.join(",") : "";
    data['deletedrole'] = deletedids?.length > 0 ? deletedids?.join(",") : "";
    data['createdby'] = parseInt(backofficeTokenDetails?.id);
    data['status'] = parseInt(data?.status);
    data['userid'] = parseInt(data?.userid);
    delete data['roleids'];
    delete data['rolenames'];
    dispatch({
      type: usersActions.UPDATE_ADMINUSER,
      payload: { id: DecryptFunction(user), data: data, navigate: navigate }
    });
  };

  const handleBack = () => {
    navigate(`/${Cookies.get('mhet_mst_project')}/users`);
  };

  return (
    <Grid
      style={{
        padding: "24px",
        // minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        marginTop: '12px'
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type="text"
            label={
              <span>
                Name
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter name"
            {...register("fullname", {
              required: "Name is required",
              pattern: {
                value: /^[a-zA-Z ]*$/,
                message: "Name should only contain alphabets",
              }
            })}
            onInput={AlphabetsValidation}
            error={!!errors.fullname}
            helperText={errors.fullname?.message}
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type="tel"
            inputProps={{ maxLength: 10 }}
            label={
              <span>
                Mobile No
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter mobile number"
            {...register("mobileno", {
              required: "Mobile no is required",
              minLength: {
                value: 10,
                message: "Enter valid mobile number",
              },
              validate: MobileNumberFirstNumberValidation
            })}
            onInput={MobileNumberValidation}
            error={!!errors.mobileno}
            helperText={errors.mobileno?.message}
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type="text"
            label={
              <span>
                Email ID
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              </span>
            }
            placeholder="Enter email id"
            {...register("emailid", {
              required: "Email id is required",
              validate: handleEmailValidation
            })}
            onInput={InputTrimAndLowercaseFunction}
            error={!!errors.emailid}
            helperText={errors.emailid?.message}
            InputLabelProps={{ shrink: true }}
            FormHelperTextProps={{
              style: { margin: 0 },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <FormControl fullWidth>
            <Controller
              name="role"
              control={control}
              defaultValue={[]}
              rules={{ required: 'Module is required' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  multiple
                  size="small"
                  options={roleList?.filtered?.map(suggestion => suggestion.rolename)}
                  onChange={(_, newValue) => {
                    field.onChange(newValue);
                  }}
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      multiline
                      label={
                        <span>
                          Module
                          <span className="error" style={{ color: "red" }}>
                            {" "}
                            *
                          </span>
                        </span>
                      }
                      sx={{ width: '100%' }}
                      error={!!errors.role}
                      helperText={errors.role?.message}
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                    />
                  )}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '24px' }}>
          <BackButton handleBack={handleBack} />
          <MauvelousPrimaryButton title={buttonLoader ? "loader" : "Submit"} handleClick={handleSubmit(onSubmit)} />
        </Grid>
      </Grid>
    </Grid>
  )
}
