import React from 'react'
import BulkUploadCandidates from '../components/BulkUploadCandidates.js'

const BulkUploadCandidate = () => {
  return (
    <>
      <BulkUploadCandidates/>
    </>
  )
}

export default BulkUploadCandidate
