import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import BackButton from "../../common/button/backButton";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import PreviewModal from "./previewModal";
import Cookies from "js-cookie";
import ConfirmModal from "./confirmModal";
import { useDispatch, useSelector } from "react-redux";
import courseActions from "../../redux/course/actions";
import CourseTabAndFields from "./courseTabAndFields";
import { cssProperties } from "../../utils/commonCssProperties";

export default function CreateCourse() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { courseTabMaster, courseFieldMaster, buttonEnable } = useSelector(state => state.courseReducer);

  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  const [previewModal, setPreviewModal] = useState({ show: false, data: null });
  const [confirmModal, setConfirmModal] = useState({ show: false, data: null });
  const [finalError, setFinalError] = useState("");
  const[enableButton, setEnableButton] = useState(false);

  useEffect(() => {
    dispatch({ type: courseActions.GET_COURSETAB_MASTER });
    dispatch({ type: courseActions.GET_COURSEFIELD_MASTER });
  }, []);

  const handlePreview = () => {
    setPreviewModal({ show: true, data: null });
    setEnableButton(true);
    // dispatch({ type: courseActions.SET_BUTTON_ENABLE, payload: { ...buttonEnable, submitbtnenable: true } });
  };

  const handleConfirm = () => {
    if (courseTabMaster?.find((val) => val?.isActive === true)) {
      if (courseFieldMaster?.find(val => val?.isSelected === true)) {
        setConfirmModal({ show: true, data: null });
        setFinalError("");
      } else {
        setFinalError("No fields are selected");
      }
    } else {
      setFinalError("No tabs are selected");
    };
  }

  const handleClose = () => {
    setPreviewModal({ show: false, data: null });
    setConfirmModal({ show: false, data: null })
  };

  const handleSubmitModal = () => {
    let tabarray = courseTabMaster?.filter((val) => {
      if (val?.isActive === true) {
        return val;
      }
    })?.map((val) => {
      return {
        coursetabid: val?.coursetabid,
        tablabel: val?.tabname
      }
    });
    let fieldarray = courseFieldMaster?.filter((val) => {
      if (val?.isSelected === true) {
        return val;
      }
    })?.map((val) => {
      return {
        coursetabdetailsid: val?.coursetabid,
        masterfieldid: val?.coursefieldid,
        fieldlabel: val?.fieldlabel,
        ismandatory: val?.ismandatory === true ? '1' : '0',
        isreadonly: val?.isreadonly === true ? '1' : '0'
      }
    });
    dispatch({
      type: courseActions.CREATE_COURSEFORM,
      payload: {
        data: {
          tabdetails: tabarray,
          fielddetails: fieldarray,
          userid: parseInt(backofficeTokenDetails?.id),
          courseid: state?.id
        },
        navigate: navigate,
        handleClose: handleClose
      },
    })
  };

  return (
    <Grid container>
      <Grid item md={6}>
        <TextField
          size="small"
          fullWidth
          disabled
          value={state?.coursename}
          label={
            <span>
              Course Name
            </span>
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item md={6}></Grid>
      <Grid md={12} mt={2}>
        <CourseTabAndFields />
      </Grid>
      <Grid item md={12} mt={2}>
        <MauvelousPrimaryButton title={"Preview"} handleClick={handlePreview} />
      </Grid>
      <Grid item md={12} mt={2}>
        <Typography sx={{ color: `${cssProperties?.color?.failure}` }}>{finalError}</Typography>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
      >
        <BackButton
          handleBack={() =>
            navigate(
              `/${Cookies.get(
                "mhet_mst_project"
              )}/course-management?page=courselist`
            )
          }
        />
        <BluePrimaryButton
          handleSubmit={handleConfirm}
          title={"Submit"}
          disabled={!enableButton}
          // disabled={!buttonEnable?.submitbtnenable}
        />
      </Grid>
      <PreviewModal
        showModal={previewModal?.show}
        handleClose={handleClose}
        coursename={state?.coursename}
      />
      <ConfirmModal
        showModal={confirmModal?.show}
        handleClose={handleClose}
        handleSubmitModal={handleSubmitModal}
      />
    </Grid>
  );
}
