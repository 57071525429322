import React from "react";
import { Modal, Box, Grid, TextField, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../../redux/common/actions";
// import moment from "moment-timezone";
import CloseButton from "../../common/button/closeButton";
import logo from '../../assets/images/navbar/cet_img.png';

export default function ProjectViewModel() {
  const dispatch = useDispatch();

  const { showModal } = useSelector((state) => state.commonReducer);

  const handleClose = () => {
    dispatch({
      type: commonActions.SET_SHOW_MODAL,
      payload: { show: false, type: null, method: "PVM", data: null },
    });
  };

  return (
    <Modal
      open={showModal?.show && showModal?.method === "PVM"}
      // onClose={handleClose}
    // aria-labelledby="candidate-details-modal-title"
    // aria-describedby="candidate-details-modal-description"
    >
      <Box
        p={2}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "40vw",
          // minWidth:"80%",
          // maxWidth: "80%",
          maxHeight: "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <h2 style={{ color: "#3F41D1" }} id="view-image-modal">View</h2>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt="" style={{ height: '100px', width: '100px' }} />
        </Box>
        {/* <h2 style={{ color: "#3F41D1" }} >
          View project
        </h2>
        <Box
          mt={2}
          sx={{
            maxHeight: "60vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Project Name
          </Typography>

              <TextField
                label="Project name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Course Name
          </Typography>

              <TextField
                label="Course name"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Customer SPOC
          </Typography>

              <TextField
                label="Customer SPOC"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Contact No
          </Typography>

              <TextField
                label="Contact no"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Project Description
          </Typography>

              <TextField
                label="Project description"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography
            sx={{ fontWeight: "bold" }}
            variant="subtitle1"
            gutterBottom
          >
            Exam Mode
          </Typography>

              <TextField
                label="Exam Mode"
                variant="outlined"
                size="small"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Box> */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <CloseButton name={"Close"} handleClose={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
}
