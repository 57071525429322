import React, { useState } from 'react'
import Sidebar from '../../layouts/sidebar'
import Header from '../../layouts/header'
import Layout from '../../layouts/layout'
import PageHeading from '../../layouts/pageHeading'
import { Box, FormControl, FormControlLabel, Grid, Link as MuiLink, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { cssProperties } from '../../utils/commonCssProperties'
import { MdKeyboardArrowDown } from 'react-icons/md'
import Cookies from "js-cookie";
import DangerButton from '../../common/button/DangerButton'

const ViewObjection = () => {
    const [status, setStatus] = useState("")
    const navigate = useNavigate()
    const data = useLocation().state
    // console.log(data)
    const breadcrumbs = [
        <MuiLink underline="hover" key="1" component={NavLink} to={`/${Cookies.get('mhet_mst_project')}/home`} sx={{
            color: cssProperties?.fontcolor?.primary,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.1px'
        }}>
            Dashboard
        </MuiLink>,
        <Typography key="3" color="text.primary"
            sx={{
                display: 'flex', alignItems: 'center', gap: '12px', color: cssProperties?.fontcolor?.primary,
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                letterSpacing: '0.1px'
            }}
        >
            View Objection <MdKeyboardArrowDown size={16} />
        </Typography>,
    ];
    const handleSubmit = () => {
      
            navigate(`/${Cookies.get('mhet_mst_project')}/home`);
    
    }
    return (
        <>
            <Sidebar />
            <Header breadcrumbs={breadcrumbs} />
            <Layout>
                <PageHeading title={'View Objection'} />
                <Grid>
                    <Grid
                        p={2}
                        style={{
                            minHeight: "74vh",
                            backgroundColor: "#fff",
                            borderRadius: "8px",
                        }}
                    >
                        {/* <Typography variant="h4" sx={{ fontSize: "24px" }}>
            Ticket Raiser details
          </Typography> */}
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Question No
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    value={data?.question_no}
                                    placeholder="Enter the name"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>





                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Question Details
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    value={data?.question_details}
                                    placeholder="Enter the name"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>





                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Answer Key
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    value={data?.answer_key}
                                    placeholder="Enter the name"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>





                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Reference Details
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    value={data?.reference_details}
                                    placeholder="Enter the name"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>





                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Your Response
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <TextField
                                    disabled
                                    fullWidth
                                    size="small"
                                    value={"Y0ur Response"}
                                    placeholder="Enter the name"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>





                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Objection Details
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <Box

                                    sx={{
                                        width: "100%", padding: 1, borderRadius: '4px', border: `1px solid rgba(118, 118, 118, 0.4)`
                                    }}
                                >

                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.38)', fontSize: "1rem" }} variant='body2'>Selected Option : A</Typography>
                                    <Typography sx={{ pt: 2, color: 'rgba(0, 0, 0, 0.38)', fontSize: "1rem" }} variant='body2'>Reason : Some Reason</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Action
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                        <FormControlLabel value="accepted" control={<Radio />} label="Accept" />
                                        <FormControlLabel value="rejected" control={<Radio />} label="Reject" />


                                    </RadioGroup>
                                </FormControl>
                            </Grid>









                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item md={3} lg={3} xs={12}>
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                   Reason
                                </Typography>
                            </Grid>
                            <Grid item md={9} lg={9} xs={12}>
                                <TextField
                                    
                                    multiline
                                    minRows={3}
                                    fullWidth
                                    size="small"
                                    value={"some reason"}
                                    placeholder="Enter the name"
                                    sx={{ width: "100%" }}
                                />
                            </Grid>





                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <DangerButton handleSubmit={handleSubmit} title="Submit" />
                        </Box>
                    </Grid>
                </Grid>

            </Layout>
        </>
    )
}

export default ViewObjection
