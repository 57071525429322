import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import masterActions from "../../redux/master/actions";
import ticketsActions from "../../redux/tickets/actions";
import commonActions from "../../redux/common/actions";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import DetailsModel from "./detailsModel";
import candidateActions from "../../redux/candidate/actions";
import PreviousTicketModel from "./previousTicketModel";
import { EncryptFunction } from "../../utils/cryptoFunction";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { handleEmailValidation, InputTrimAndLowercaseFunction, MobileNumberFirstNumberValidation, MobileNumberValidation } from "../../utils/validation";
import ButtonLoader from "../../common/buttonLoader";

export default function Index() {
  // const [emailId, setEmailId] = useState("");
  // const [mobileNo, setMobileNo] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [candidateInfo, setCandidateInfo] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
    setValue,
    trigger,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 } ,
    clearErrors: clearErrors2,
    watch: watch2
  } = useForm({ mode: 'onChange' });
  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);
  const id = backofficeTokenDetails?.id;
  const { candidateDetails } = useSelector((state) => state.ticketsReducer);

  const { grievance } = useSelector((state) => state.masterReducer);
  const { fullCandidateDetails, candidateTicketList } = useSelector(
    (state) => state.candidateReducer
  );

  const { buttonLoader } = useSelector(state => state.commonReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: masterActions.GET_GRIEVANCE });
  }, []);

  const onSubmit = (data) => {
    dispatch({
      type: ticketsActions.GRIEVANCE_QUERY_CREATION,
      payload: {
        data: {
          ...data,
          candidateid: parseInt(data?.iscandidate) === 1 ? candidateDetails.candidateid : null,
          createdby: id,
          status: 1,
        },
      },
      navigate: navigate,
      handleReset: handleReset
    });
    // reset();
    // console.log(data);
  };

  const handleReset = () => {
    reset();
  };


  const handleInfo = () => {
    if (candidateDetails && candidateDetails) {
      return `Name - ${candidateDetails?.candidatename} \nMobile No -${candidateDetails?.mobileno} \nEmail Id -${candidateDetails?.emailid}`;
    } else {
      return `No candidate Found`;
    }
  };

  const handleSearch = (data) => {
    console.log(data)
    if (data?.emailid || data?.mobileno) {
      const formData = {
        emailid: data?.emailid,
        mobileno: data?.mobileno,
        setCandidateInfo
      };
      const noEmptyData = Object.values(formData).some((value) => value !== "");
      if (noEmptyData) {
        dispatch({
          type: ticketsActions.GET_CANDIDATE_DETAILS,
          payload: formData,
        });
        handleInfo();
        setError("");
      }
    } else {
      if (!data?.emailid || !data?.mobileno) {
        setError("Enter email id  or mobile number ");
      }
    }
  };

  const handleView = () => {
    if (candidateDetails?.candidateid) {
      dispatch({
        type: candidateActions.GET_FULL_CANDIDATE_DETAILS,
        payload: { id: candidateDetails?.candidateid },
      });
      dispatch({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: true, type: null, method: "DM", data: null },
      });
      console.log(candidateDetails?.candidateid);
    }
  };

  const handlePreviousTicket = () => {
    if (candidateDetails?.candidateid && candidateTicketList.length <= 1) {
      navigate(
        `/${Cookies.get(
          "mhet_mst_project"
        )}/ticket-details?ticket=${EncryptFunction(
          candidateTicketList[0]?.ticketid
        )}`
      );
    } else {
      dispatch({
        type: commonActions.SET_SHOW_MODAL,
        payload: { show: true, type: null, method: "PTM", data: null },
      });
    }
  };

  const handleChangeRaisingFor = (e) => {
    reset({
      emailid: "",
      mobileno: "",
      grievanceid: "",
      tickettype: "",
      grievancedetails: ""
    });
    trigger(['grievanceid', 'tickettype']);
    setValue('iscandidate', e.target.value);
    dispatch({
      type: ticketsActions.SET_CANDIDATE_DETAILS,
      payload: [],
    });
    dispatch({
      type: candidateActions.SET_TICKETS_LIST_BY_CANDIDATE,
      payload: null,
    });
  }

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Grid
          container
          // columnSpacing={2}
          sx={{
            backgroundColor: "#fff",
            width: "100vw",
            borderRadius: "8px",
            padding: "20px 20px",
          }}
        >
          <Grid item lg={10} md={12} sm={12} sx={{ marginBottom: '10px' }}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Ticket raising for</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                onChange={handleChangeRaisingFor}
              >
                <FormControlLabel value={1} control={<Radio />} label="Candidate" />
                <FormControlLabel value={0} control={<Radio />} label="Non Candidate" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {(parseInt(watch('iscandidate')) === 1) &&
            <>
              <Grid item lg={10} md={12} sm={12}>
                <Box display="flex" gap={2}>
                  <TextField
                    label={
                      <span>
                        Email Id
                        <span className="error" style={{ color: "red" }}>

                          *
                        </span>
                      </span>
                    }
                    error={!!errors2.emailid}
                    {...register2("emailid", {
                      // required: "Email id is required",
                      validate: (watch2('emailid') ? handleEmailValidation : ''),
                    })}
                    onInput={InputTrimAndLowercaseFunction}
                    onBlur={() => {
                      if (!watch2('emailid')) {
                        clearErrors2('emailid')
                      }
                    }}
                    helperText={errors2.emailid?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                    // value={emailId}
                    // onChange={(e) => setEmailId(e.target.value)}
                    fullWidth
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{ style: { height: '56px', boxSizing: 'border-box' }, maxLength: 10 }}
                    type="tel"

                    label={
                      <span>
                        Mobile No
                        <span className="error" style={{ color: "red" }}>
                          *
                        </span>
                      </span>
                    }
                    placeholder="Enter mobile number"
                    {...register2("mobileno", {
                      // required: "Mobile no is required",
                      minLength: {
                        value: 10,
                        message: "Enter valid mobile number",
                      },
                      validate: MobileNumberFirstNumberValidation
                    })}
                    onInput={MobileNumberValidation}
                    onBlur={() => {
                      if (!watch2('mobileno')) {
                        clearErrors2('mobileno')
                      }
                    }}
                    error={!!errors2.mobileno}
                    helperText={errors2.mobileno?.message}
                    FormHelperTextProps={{
                      style: { margin: 0 },
                    }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                lg={2}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  // mt: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {BluePrimaryButton({
                    title: "Search",
                    type: "text",
                    handleSubmit: handleSubmit2(handleSearch),
                  })}
                </Box>
              </Grid>
              {error && (
                <FormHelperText sx={{ color: "red" }}>{error}</FormHelperText>
              )}
              {candidateInfo && (
                <Grid item lg={10} md={9} sm={12} xs={12} sx={{ mt: 2 }}>
                  <TextField
                    disabled
                    value={handleInfo()}
                    multiline
                    rows={3}
                    fullWidth
                  />
                </Grid>
              )}
              {candidateInfo &&
                <Grid item lg={2} md={3} sm={12} xs={12} mt={2}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "14px",
                    }}
                  >
                    {candidateInfo &&
                      (candidateTicketList &&
                        candidateTicketList.length > 0) && (
                        <Typography
                          variant="caption"
                          onClick={handlePreviousTicket}
                          sx={{
                            cursor: "pointer",
                            color: "#007bff",
                            textDecoration: "underline",
                          }}
                        >
                          Previous Tickets
                        </Typography>
                      )}
                    {candidateInfo && candidateDetails?.candidateid && (
                      <Box>
                        {MauvelousPrimaryButton({
                          title: "View",
                          handleClick: handleView,
                        })}
                      </Box>
                    )}
                  </Box>
                </Grid>
              }
            </>
          }
          {parseInt(watch('iscandidate')) === 0 &&
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                {" "}
                <TextField
                  label={
                    <span>
                      Email Id
                      <span className="error" style={{ color: "red" }}>

                        *
                      </span>
                    </span>
                  }
                  error={!!errors.emailid}
                  {...register("emailid", {
                    required: `${parseInt(watch('iscandidate')) === 0 ? 'Email id is required' : ''}`,
                    validate: handleEmailValidation,
                  })}
                  onInput={InputTrimAndLowercaseFunction}
                  helperText={errors.emailid?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                  // value={emailId}
                  // onChange={(e) => setEmailId(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                {" "}
                <TextField
                  fullWidth
                  variant="outlined"
                  inputProps={{ style: { height: '56px', boxSizing: 'border-box' }, maxLength: 10 }}
                  type="tel"

                  label={
                    <span>
                      Mobile No
                      <span className="error" style={{ color: "red" }}>
                        *
                      </span>
                    </span>
                  }
                  placeholder="Enter mobile number"
                  {...register("mobileno", {
                    required: `${parseInt(watch('iscandidate')) === 0 ? 'Mobile number is required' : ''}`,
                    // required: `${parseInt(watch('iscandidate')) === 0 ? 'Mobile number is required' : ''}`,
                    minLength: {
                      value: 10,
                      message: "Enter valid mobile number",
                    },
                    validate: MobileNumberFirstNumberValidation
                  })}
                  onInput={MobileNumberValidation}
                  error={!!errors.mobileno}
                  helperText={errors.mobileno?.message}
                  FormHelperTextProps={{
                    style: { margin: 0 },
                  }}
                />
              </Grid>
            </Grid>
          }
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            {" "}
            <FormControl fullWidth error={!!errors.grievanceid}>
              <InputLabel shrink={!!watch("grievanceid")}>Select Grievance<span className="error" style={{ color: "red" }}>*</span></InputLabel>
              <Select
                label="Select Grievance"
                // defaultValue=""
                {...register("grievanceid", {
                  required: "Grievance type is required",
                })}
                onChange={(e) => {
                  setValue('grievanceid', e.target.value);
                  trigger(['grievanceid'])
                }}
                value={watch("grievanceid") ?? ""}
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                {grievance?.map((val, i) => (
                  <MenuItem key={i} value={val?.grievanceid}>
                    {val.grievance}
                  </MenuItem>
                ))}
              </Select>
              {errors?.grievanceid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors?.grievanceid?.message}
                </FormHelperText>
              )}
              {/* {errors?.grievanceid && (
                <Typography color="error">
                  {errors?.grievanceid?.message}
                </Typography>
              )} */}
            </FormControl>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <FormControl fullWidth error={!!errors?.tickettype}>
              <InputLabel shrink={!!watch("tickettype")}>Select Mode<span className="error" style={{ color: "red" }}>*</span></InputLabel>
              <Select
                label={"Select Mode"}
                // defaultValue=""
                {...register("tickettype", {
                  required: "Ticket type is required",
                })}
                onChange={(e) => {
                  setValue('tickettype', e.target.value);
                  trigger(['tickettype'])
                }}
                value={watch("tickettype") ?? ""}
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value={2}>By Call</MenuItem>
                <MenuItem value={3}>By Email</MenuItem>
              </Select>
              {errors?.tickettype && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors?.tickettype?.message}
                </FormHelperText>
              )}
              {/* {errors?.tickettype && (
                <Typography color="error">
                  {errors?.tickettype?.message}
                </Typography>
              )} */}
            </FormControl>
          </Grid>
          <Grid items lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <TextField
              label={
                <span>
                  Grievance Details
                  <span className="error" style={{ color: "red" }}>*</span>
                </span>}
              placeholder="Enter grievance details"
              multiline
              rows={4}
              fullWidth
              {...register("grievancedetails", {
                required: "Grievance details is required",
              })}
              error={!!errors.grievancedetails}
              helperText={errors.grievancedetails?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
            />
            {/* {errors?.grievancedetails && (
              <Typography color="error">
                {errors?.grievancedetails?.message}
              </Typography>
            )} */}
            {/* {candidateDetails.candidateid && ( */}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <MauvelousPrimaryButton
                title={buttonLoader ? <ButtonLoader /> : "Submit Grievance"}
                handleClick={handleSubmit(onSubmit)}
                disabled={((parseInt(watch('iscandidate')) === 1 && candidateDetails?.candidateid) || parseInt(watch('iscandidate')) === 0) ? false : true}
              />
            </Box>
            {/* )} */}
          </Grid>
        </Grid>
      </Box>
      <DetailsModel fullCandidateDetails={fullCandidateDetails} />
      <PreviousTicketModel candidateTicketList={candidateTicketList} />
    </>
  );
}
