import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, Radio, Link, FormControlLabel, makeStyles, Checkbox } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import { useNavigate } from "react-router-dom";
import { PiPencilSimpleBold, PiTrashBold } from "react-icons/pi";
import Cookies from "js-cookie";
import { EncryptFunction } from "../utils/cryptoFunction";
import { IoCreateOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import commonActions from "../redux/common/actions";


const tableheadstyle = {
    color: `${cssProperties?.color?.primary}`,
    borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
    background: cssProperties?.backgroundcolor?.primary,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '17.6px',
    letterSpacing: '0.064px',
    textAlign: 'center'
}

const tablebodystyle = {
    paddingTop: '16px',
    paddingBottom: '20px',
    color: `#212121`,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '17.6px',
    letterSpacing: '0.038px',
    borderBottom: `1px solid ${cssProperties?.bordercolor?.primary4blue}`,
    textAlign: 'center'
}

const queryList_Closed = {
    color: 'rgba(34, 160, 107, 1)'
}

const queryList_Assigned = {
    color: 'rgba(32, 50, 114, 1)'
}

const queryList_Open = {
    backgroundColor: 'rgb(241 91 80)',
    padding: '8px 12px',
    borderRadius: '8px',
    color: 'white',

    cursor: 'pointer',
};

const queryListMap = {
    'Closed': queryList_Closed,
    'Open': queryList_Open,
    'Assigned': queryList_Assigned,
};

export default function DataTable(props) {
    const dispatch = useDispatch();


    const { keys, values, page, handleMasteredit, handleUserDelete, handleProjectUpdate, handleCourseUpdate, handlCourseCreate, handleSelectAll, handleSingleSelect } = props;
    
    const { forcePushSelectedValues } = useSelector(state => state.forcePushAllocationReducer);

    const navigate = useNavigate();

    const handleModel = (method,val) => {
        dispatch({
            type: commonActions.SET_SHOW_MODAL,
            payload: { show: true, type: null, method: method, data: val },
        });
    }
    const fixedHeaderStyle = {
        height: 'inherit',
        overflowY: 'auto',

    }
    return (
        <Box sx={{ paddingTop: '12px', height: '100%' }}>
            <TableContainer
                component={Paper}
                sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    border: `1px solid ${cssProperties?.bordercolor?.primary2blue}`,
                    borderRadius: '4px',

                    width: '100%',
                    height: '100%',
                    scrollbarWidth: 'thin',
                    overflow: 'auto'
                }}
            >
                <Table stickyHeader sx={{ minWidth: 650, padding: '24px 12px 16px 12px', }} aria-label="simple table">
                    <TableHead >
                        <TableRow>
                            {keys?.map((val, i) =>
                                val?.name === 'select all' ?
                                    <TableCell sx={{ ...tableheadstyle, textWrap: "nowrap" }} key={i}>
                                        <Checkbox
                                            checked={forcePushSelectedValues?.selectall}
                                            disabled={((forcePushSelectedValues?.buttonType === 'Allocated' || forcePushSelectedValues?.buttonType === 'PH Allocated') || ((forcePushSelectedValues?.buttonType === 'Not Allocated' || forcePushSelectedValues?.buttonType === 'PH Not Allocated') && forcePushSelectedValues?.venueid)) ? false : true}
                                            onChange={handleSelectAll}
                                        />Select All
                                    </TableCell> :
                                    <TableCell sx={{ ...tableheadstyle, textWrap: "nowrap" }} key={i}>{val?.name}</TableCell>

                            )}
                            {(page === 'userList' || page === 'masterList' || page === 'gender' || page === 'mothertongue' ||
                                page === 'religion' || page === 'region' || page === 'annualfamilyincome' || page === 'nationality' ||
                                page === 'language' || page === 'minority' || page === 'state' || page === 'district' || page === 'taluka' ||
                                page === 'village' || page === 'category' || page === 'venuetype' || page === 'buildingtype' || page === 'caste' || page === 'pwdtype' || page === 'equivalentboard' ||
                                page === 'board' || page === 'grievancetype' || page === 'grievancecategory' || page === 'documenttype' || page === 'courseList' || page === "coursetab" || page === 'coursefields' || page === "coursecategory") &&
                                <TableCell sx={{ ...tableheadstyle, textWrap: "nowrap" }}>Action</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    {values?.length === 0 ?
                        <TableBody >
                            <TableRow>
                                <TableCell colSpan={keys?.length} sx={{ textAlign: 'center', fontSize: '16px', fontWeight: '400' }}>No Records Found</TableCell>
                            </TableRow>
                        </TableBody> :
                        <TableBody sx={fixedHeaderStyle}>
                            {values?.map((val, valueInd) =>
                                <TableRow
                                    key={valueInd}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    {keys?.map((k, keyInd) =>
                                        <>

                                            {(k?.accessor === 'isActive') && <TableCell>Hello World</TableCell>}
                                            {k?.accessor === 'select checkbox' ?
                                                <TableCell>
                                                    <Checkbox
                                                    checked={val?.selected}
                                                    disabled={((forcePushSelectedValues?.buttonType === 'Allocated' || forcePushSelectedValues?.buttonType === 'PH Allocated') || ((forcePushSelectedValues?.buttonType === 'Not Allocated' || forcePushSelectedValues?.buttonType === 'PH Not Allocated') && forcePushSelectedValues?.venueid)) ? false : true}
                                                    onChange={(e) => handleSingleSelect(e, val?.candidateid)}
                                                    />
                                                </TableCell> :
                                                k?.accessor === 'rolenames' ?
                                                    <TableCell sx={{ ...tablebodystyle }}>
                                                        {val[k?.accessor]?.split(',').map((line, lineInd, arr) => (
                                                            <Typography key={lineInd}>{line}{lineInd !== arr?.length - 1 && ','}</Typography>
                                                        ))}
                                                    </TableCell> :
                                                    // k?.accessor === 'id' ?
                                                    //     <TableCell sx={{ ...tablebodystyle }}>{valueInd + 1}</TableCell> :
                                                    k?.accessor === 'grievancecategory' ?
                                                        <TableCell sx={{ ...tablebodystyle }}>{val.category}</TableCell> :
                                                        (k?.accessor === 'category' || k?.accessor === 'grievance') ?
                                                            <TableCell
                                                                sx={(k?.accessor === 'category') ? { ...tablebodystyle, color: `${cssProperties?.color?.primary}` } : (k?.accessor === 'grievance') ?
                                                                    { ...tablebodystyle, color: `${cssProperties?.color?.failure}` } : { ...tablebodystyle }}
                                                            >
                                                                {val[k?.accessor] ? val[k?.accessor] : "--"}
                                                            </TableCell>
                                                            : (k.accessor === "viewlogo") ? <TableCell
                                                                sx={(k?.accessor === 'category') ? { ...tablebodystyle, color: `${cssProperties?.color?.primary}` } : (k?.accessor === 'grievance') ?
                                                                    { ...tablebodystyle, color: `${cssProperties?.color?.failure}` } : { ...tablebodystyle }}>

                                                                <Box sx={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: "space-between",
                                                                    borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                                    borderWidth: '1px',

                                                                    borderRadius: '6px',
                                                                    padding: '4px 7px',
                                                                    gap: 2
                                                                }}>

                                                                    <Box color={"#3F41D1"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                        {/* <IoEyeOutline />   */}
                                                                        <Link onClick={() => handleModel("PVM")}
                                                                            sx={{ cursor: "pointer", textDecoration: "none" }}>View</Link >
                                                                    </Box>
                                                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 2 }}>
                                                                        {/* <PiPencilSimpleBold/> */}
                                                                        <Link onClick={() => handleModel("PEM")}
                                                                            sx={{ color: "#E697AB", cursor: "pointer", textDecoration: "none" }}>Edit</Link >
                                                                        {/* <Radio value={"enable"}/> */}
                                                                        <FormControlLabel value="Enable" control={<Radio />} label={`${val['enable'] === true ? "Enable" : "Disable"}`} />
                                                                    </Box>

                                                                </Box>
                                                            </TableCell> :
                                                                <TableCell sx={{
                                                                    ...tablebodystyle,
                                                                    ...((k?.colorChange === 'ticketStatus' && (page === 'ticket')) ? queryListMap[val[k?.accessor]] : (k?.colorChange === 'ticketClosedDate' && page === 'ticket' && val[k?.accessor]) ? queryListMap[val[k?.accessor]] : ''),
                                                                    ...((page === 'grievanceList' && k?.colorChange === 'ticketStatus' && val?.status === 'Open') ? { color: 'rgb(241 91 80)' } : (page === 'grievanceList' && k?.colorChange === 'ticketStatus') ? queryListMap[val[k?.accessor]] : ''),
                                                                    ...((page === 'force push' && k?.accessor === 'status' && val?.status === 'Allocated') ? {color: `${cssProperties?.color?.success}`} : (page === 'force push' && k?.accessor === 'status' && val?.status === 'Not Allocated') ? {color: `${cssProperties?.color?.failure}`} : {})
                                                                }}
                                                                    // className={`${(k?.colorChange === 'ticketStatus' && page === 'ticket') ? `queryList_${val[k?.accessor]}` : (k?.colorChange === 'ticketClosedDate' && page === 'ticket' && val[k?.accessor]) ? 'queryList_Closed' : ''}`}
                                                                    onClick={() => {
                                                                        if (page === 'ticket' && k?.accessor === 'status' && val[k?.accessor] === 'Open') {
                                                                            navigate(`/${Cookies.get('mhet_mst_project')}/ticket-details?ticket=${EncryptFunction(val?.ticketid)}`)
                                                                        }
                                                                    }}
                                                                >
                                                                    {val[k?.accessor] ? val[k?.accessor] : "--"}
                                                                    {/* <Typography>{val[k?.accessor] ? val[k?.accessor] : "--"}</Typography> */}
                                                                </TableCell >
                                            }
                                        </>
                                    )}
                                    {(page === 'masterList') &&
                                        <TableCell sx={{ ...tablebodystyle }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                borderWidth: '1px',
                                                // borderRadius: 2,

                                                // backgroundColor: 'rgba(243, 243, 243, 1)',
                                                borderRadius: '6px 0',
                                                padding: '4px 7px',
                                                '& svg': {
                                                    m: 1,
                                                },
                                            }}>
                                                <PiPencilSimpleBold
                                                    size={24}
                                                    style={{ marginRight: '7px', color: `${cssProperties?.bordercolor?.primary}`, cursor: 'pointer' }}
                                                    onClick={() => handleModel('EMM',val)}
                                                />
                                                <Divider orientation="vertical" variant="middle" flexItem sx={{ mt: '0', mb: '0' }} />
                                                <PiTrashBold
                                                    size={24}
                                                    style={{ marginRight: '7px', color: `${cssProperties?.bordercolor?.primary}`, cursor: 'pointer' }}
                                                    onClick={() => handleModel('DMM')}
                                                />
                                            </Box>
                                            {/* <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "space-between",
                                                borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                borderWidth: '1px',
                                                
                                                borderRadius: '6px',
                                                padding: '4px 7px',
                                                gap: 2
                                            }}>

                                                <Box color={"#3F41D1"} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                    
                                                </Box>
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: 2 }}>
                                                   
                                                    <Link onClick={() => handleModel('EMM')}
                                                        sx={{ color: "#3F41D1", cursor: "pointer", textDecoration: "none" }}>Edit</Link >
                                                   

                                                    <Link onClick={() => handleModel('DMM')}
                                                        sx={{ color: "#E6393E", cursor: "pointer", textDecoration: "none" }}>Delete</Link >
                                                    

                                                </Box>

                                            </Box> */}
                                        </TableCell>
                                    }
                                    {(page === 'courseList') &&
                                        <TableCell sx={{ ...tablebodystyle }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                borderWidth: '1px',
                                                // borderRadius: 2,

                                                // backgroundColor: 'rgba(243, 243, 243, 1)',
                                                borderRadius: '6px',
                                                padding: '4px 7px',
                                                '& svg': {
                                                    m: 1,
                                                },
                                            }}>
                                                <PiPencilSimpleBold
                                                    size={24}
                                                    style={{ marginRight: '7px', color: `${cssProperties?.color?.primary}`, cursor: 'pointer' }}
                                                    onClick={() => handleCourseUpdate(val)}
                                                />
                                                <IoCreateOutline
                                                    size={24}
                                                    style={{ marginRight: '7px', color: `${val?.courseformcreated === '1' ? cssProperties?.bordercolor?.primary : cssProperties?.color?.success}`, cursor: `${val?.courseformcreated === '1' ? 'not-allowed' : 'pointer'}` }}
                                                    onClick={() => {
                                                        if (val?.courseformcreated === '1') {
                                                            return;
                                                        } else {
                                                            handlCourseCreate(val)
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </TableCell>
                                    }
                                    {(page === 'userList') &&
                                        <TableCell sx={{ ...tablebodystyle, }}>
                                            {val['id'] > 1 ?
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                    borderWidth: '1px',
                                                    // borderRadius: 2,

                                                    backgroundColor: 'transparent',
                                                    borderRadius: '6px 0',
                                                    padding: '4px 7px',
                                                    '& svg': {
                                                        m: 1,
                                                    },
                                                }}>
                                                    <PiPencilSimpleBold
                                                        size={24}
                                                        style={{ marginRight: '7px', color: `${cssProperties?.bordercolor?.primary}`, cursor: 'pointer' }}
                                                        onClick={() => navigate(`/${Cookies.get('mhet_mst_project')}/update-user?user=${EncryptFunction(val?.userid)}`)}
                                                    />
                                                    <Divider orientation="vertical" variant="middle" flexItem sx={{ mt: '0', mb: '0' }} />
                                                    <PiTrashBold
                                                        size={24}
                                                        style={{ marginRight: '7px', color: `${cssProperties?.bordercolor?.primary}`, cursor: 'pointer' }}
                                                        onClick={() => handleUserDelete(val?.userid)}
                                                    />
                                                </Box> :
                                                <></>
                                            }
                                        </TableCell>
                                    }

                                    {(page === 'gender' || page === 'mothertongue' || page === 'religion' || page === 'region' ||
                                        page === 'annualfamilyincome' || page === 'nationality' || page === 'language' || page === 'minority' ||
                                        page === 'state' || page === 'district' || page === 'taluka' || page === 'village' || page === 'category' || page === 'venuetype' || page === 'buildingtype' ||
                                        page === 'caste' || page === 'pwdtype' || page === 'equivalentboard' || page === 'board' || page === 'documenttype' || page === 'coursetab' || page === "coursefields" || page === "coursecategory") &&
                                        <TableCell sx={{ ...tablebodystyle }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                borderWidth: '1px',

                                                // backgroundColor: 'rgba(243, 243, 243, 1)',
                                                borderRadius: '6px',
                                                padding: '4px 7px',
                                                '& svg': {
                                                    m: 1,
                                                },
                                            }}>
                                                <PiPencilSimpleBold
                                                    size={24}
                                                    style={{ marginRight: '7px', color: `${cssProperties?.bordercolor?.primary}`, cursor: 'pointer' }}
                                                    onClick={() => handleMasteredit(page, val)}
                                                />
                                            </Box>
                                            {/* <Typography
                                                sx={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                                                onClick={() => handleMasteredit(page, val)}
                                            >
                                                <PiPencilSimpleBold size={22} style={{ marginRight: '5px' }} /> Edit
                                            </Typography> */}
                                        </TableCell>
                                    }
                                    {(page === 'grievancetype' || page === 'grievancecategory') &&
                                        <TableCell sx={{ ...tablebodystyle }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                borderWidth: '1px',

                                                // backgroundColor: 'rgba(243, 243, 243, 1)',
                                                borderRadius: '6px',
                                                padding: '4px 7px',
                                                '& svg': {
                                                    m: 1,
                                                },
                                            }}>
                                                <PiPencilSimpleBold
                                                    size={24}
                                                    style={{ marginRight: '7px', color: `${cssProperties?.bordercolor?.primary}`, cursor: 'pointer' }}
                                                    onClick={() => handleMasteredit(page, val)}
                                                />
                                            </Box>
                                        </TableCell>
                                    }
                                    {/* {(page === 'Project List' ) &&
                                    
                                        <TableCell sx={{ ...tablebodystyle,textWrap:"nowrap" }}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent:"center",    
                                                borderColor: `${cssProperties?.bordercolor?.primary}`,
                                                borderWidth: '1px',
                                                
                                                backgroundColor: 'rgba(243, 243, 243, 1)',
                                                borderRadius: '6px',
                                                padding: '4px 7px',
                                                '& svg': {
                                                    m: 1,
                                                },
                                            }}>
                                                <Box color={"#E6393E"}>
                                                <IoEyeOutline />   <Box>View</Box>
                                                </Box>
                                                <Box>
                                                    <PiPencilSimpleBold/>
                                                    <Box>Edit</Box>
                                                </Box>
                                                <Box>
                                                    <Radio />
                                                    <Box>Enable</Box>
                                                </Box>
                                            </Box>
                                        </TableCell>
                                    } */}
                                </TableRow>
                            )}
                        </TableBody>
                    }
                </Table>
            </TableContainer >
        </Box >
    )
}