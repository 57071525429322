import React from "react";
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  createTheme,
  Box,
} from "@mui/material";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import { IoMdDownload } from "react-icons/io";
import PrintDownloadButton from "../../common/button/printDownloadButton";
import { LuUploadCloud } from "react-icons/lu";
import { CiPlay1 } from "react-icons/ci";

export default function Index() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
      },
    },
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileButtons = useMediaQuery(theme.breakpoints.down("buttonSm"));
  return (
    <Grid
      p={3}
      sx={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid container spacing={3}>
        <Grid item md={2.5} xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="coursename">Course Name <span style={{color:"#E6393E", fontWeight:"500"}}>*</span></InputLabel>
            <Select id="coursename" label="Course Name" size="small">
              <MenuItem value="Course Name 1">Course Name 1</MenuItem>
              <MenuItem value="Course Name 2">Course Name 2</MenuItem>
              <MenuItem value="Course Name 3">Course Name 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
       
        <Grid item md={2.5} xs={12}>
          <TextField
          label="Exam date"
          InputLabelProps={{shrink:true}}
          size="small" fullWidth type="date" />
        </Grid>
        <Grid item md={2.5} xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="batchname">Select Batch</InputLabel>
            <Select id="batchname" label="Batch Name" size="small">
              <MenuItem value="Batch Name 1">Batch Name 1</MenuItem>
              <MenuItem value="Batch Name 2">Batch Name 2</MenuItem>
              <MenuItem value="Batch Name 3">Batch Name 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          md={2}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <MauvelousPrimaryButton title={"Save"} />
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            flexDirection: isMobile ? "column" : "",
          }}
        >
          <PrintDownloadButton
            name={"Upload candidate data"}
            startIcon={<LuUploadCloud />}
          />
          <PrintDownloadButton
            name={"Download candidate data"}
            startIcon={<IoMdDownload />}
          />
        </Grid>
      </Grid>
      <Typography mt={2} sx={{ fontSize: 25 }}>
      Select Category
      </Typography>
      <Grid container spacing={1} >
        <Grid item xs={12} md={4} >
          <FormControl fullWidth size="small">
            <InputLabel id="categoryname">Select Category</InputLabel>
            <Select id="categoryname" label="Category Name" size="small">
              <MenuItem value="category Name 1">category Name 1</MenuItem>
              <MenuItem value="category Name 2">category Name 2</MenuItem>
              <MenuItem value="category Name 3">category Name 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid  item xs={12} md={8} sx={{ display: "grid",gridTemplateColumns:isMobile ?"repeat(2,1fr)" : "repeat(4,1fr)"}}>
          <Grid item xs={12} md={3} sx={{ display: "flex" }}>
            <Box>
              <Checkbox />
            </Box>
            <Box>
              <MauvelousPrimaryButton title={"Send SMS"} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton
              name={"Upload"}
              startIcon={<LuUploadCloud />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton
              name={"Download"}
              startIcon={<IoMdDownload />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton name={"Preview"} startIcon={<CiPlay1 />} />
          </Grid>
        </Grid>
        <Grid item md={4}  xs={12}></Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "grid",gridTemplateColumns:isMobile ?"repeat(2,1fr)" : "repeat(4,1fr)"}}
        >
         <Grid item xs={12} md={3} sx={{ display: "flex" }}>
            <Box>
              <Checkbox />
            </Box>
            <Box>
              <MauvelousPrimaryButton title={"Send Email"} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton
              name={"Upload"}
              startIcon={<LuUploadCloud />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton
              name={"Download"}
              startIcon={<IoMdDownload />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton name={"Preview"} startIcon={<CiPlay1 />} />
          </Grid>
        </Grid>
        <Grid item  xs={12} md={4}></Grid>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ display: "grid",gridTemplateColumns:isMobile ?"repeat(2,1fr)" : "repeat(4,1fr)"}}
        >
          <Grid item xs={12} md={3} sx={{ display: "flex" }}>
            <Box>
              <Checkbox />
            </Box>
            <Box sx={{marginRight:1}}>
              <MauvelousPrimaryButton title={"Send Whatsapp"} />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton
              name={"Upload"}
              startIcon={<LuUploadCloud />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton
              name={"Download"}
              startIcon={<IoMdDownload />}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PrintDownloadButton name={"Preview"} startIcon={<CiPlay1 />} />
          </Grid>
        </Grid>
      </Grid>
      <Grid mt={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MauvelousPrimaryButton title={"Send"} />
      </Grid>
    </Grid>
  );
}
