import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import MauvelousPrimaryButton from "../common/button/mauvelousPrimaryButton";

export default function PageHeading({ title,align }) {
  const navigate = useNavigate();
  return (
    <Grid pb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box sx={{width:'100%'}}>
        <Typography
          variant="h5"
          sx={{
            color: `${cssProperties?.fontcolor?.primary}`,
            fontWeight: "500",
            textAlign:align ? align : 'start'
          }}
        >
          {title}
        </Typography>
      </Box>
      {title === "Help desk" &&(
        <MauvelousPrimaryButton
          handleClick={() =>
            navigate(`/${Cookies.get("mhet_mst_project")}/ticket-list`)
          }
          title="View Tickets"
        />
      )}        
    </Grid>
  );
}
