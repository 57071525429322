import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import usersActions from "../../redux/users/actions";
import { AlphabetsValidation, InputTrimAndLowercaseFunction, InputTrimFunction, MobileNumberFirstNumberValidation, MobileNumberValidation, handleEmailValidation, passwordValidation } from "../../utils/validation";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Autocomplete, FormControl, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import MauvelousPrimaryButton from '../../common/button/mauvelousPrimaryButton';
import BackButton from "../../common/button/backButton";
import CancelButton from "../../common/button/cancelButton";

export default function Index({handleClose}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { roleList } = useSelector(state => state.usersReducer);

    const { backofficeTokenDetails } = useSelector(state => state.authReducer);

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const { register, handleSubmit,reset, formState: { errors }, control } = useForm();

    const [passState, setPassState] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch({ type: usersActions.GET_ROLE_LIST });
        //eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        let role = data['role']?.map((val) => {
            return roleList?.filtered?.find((r) => r?.rolename === val)?.roleid
        });
        data['role'] = role?.join(",");
        data['deletedrole'] = "";
        data['createdby'] = backofficeTokenDetails?.id;
        dispatch({ type: usersActions.CREATE_ADMINUSER, payload: { data: data, navigate: navigate } });
    };

    const handleBack = () => {
        handleClose()
        reset()
        // navigate(`/${Cookies.get('mhet_mst_project')}/users`);
    };

    return (
        <Grid
            style={{
                // padding: "24px",
                // minHeight: "60vh",
                // backgroundColor: "#fff",
                // borderRadius: "8px",
                // marginTop: '12px'
            }}
        >
            <Typography variant="h5" sx={{fontSize:'20px',fontWeight:500,marginBottom: '12px'}} >Add User</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} xl={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                        type="text"
                        label={
                            <span>
                                Name
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    *
                                </span>
                            </span>
                        }
                        placeholder="Enter name"
                        {...register("fullname", {
                            required: "Name is required",
                            pattern: {
                                value: /^[a-zA-Z ]*$/,
                                message: "Name should only contain alphabets",
                            }
                        })}
                        onInput={AlphabetsValidation}
                        error={!!errors.fullname}
                        helperText={errors.fullname?.message}
                        FormHelperTextProps={{
                            style: { margin: 0 },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        inputProps={{style:{height:'56px',boxSizing:'border-box'}, maxLength: 10}}
                        type="tel"
                       
                        label={
                            <span>
                                Mobile No
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    *
                                </span>
                            </span>
                        }
                        placeholder="Enter mobile number"
                        {...register("mobileno", {
                            required: "Mobile no is required",
                            minLength: {
                                value: 10,
                                message: "Enter valid mobile number",
                            },
                            validate: MobileNumberFirstNumberValidation
                        })}
                        onInput={MobileNumberValidation}
                        error={!!errors.mobileno}
                        helperText={errors.mobileno?.message}
                        FormHelperTextProps={{
                            style: { margin: 0 },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                        type="text"
                        label={
                            <span>
                                Email ID
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    *
                                </span>
                            </span>
                        }
                        placeholder="Enter email id"
                        {...register("emailid", {
                            required: "Email id is required",
                            validate: handleEmailValidation
                        })}
                        onInput={InputTrimAndLowercaseFunction}
                        error={!!errors.emailid}
                        helperText={errors.emailid?.message}
                        FormHelperTextProps={{
                            style: { margin: 0 },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                        type={passState ? "text" : "password"}
                        label={
                            <span>
                                Password
                                <span className="error" style={{ color: "red" }}>
                                    {" "}
                                    *
                                </span>
                            </span>
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setPassState(!passState)} sx={{ paddingRight: '0' }}>
                                        {passState ? <FiEyeOff size={18} /> : <FiEye size={18} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={!!errors.password}
                        {...register("password", {
                            required: "Password is required",
                            minLength: {
                                value: 8,
                                message: "Password must have at least 8 characters",
                            },
                            validate: passwordValidation
                        })}
                        onInput={InputTrimFunction}
                        helperText={errors.password?.message}
                        FormHelperTextProps={{
                            style: { margin: 0 },
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <FormControl fullWidth>
                        <Controller
                            name="role"
                            control={control}
                            defaultValue={[]}
                            rules={{ required: 'Module is required' }}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    multiple
                                    inputProps={{style:{height:'56px',boxSizing:'border-box'}}}
                                    options={roleList?.filtered?.map(suggestion => suggestion.rolename)}
                                    onChange={(_, newValue) => {
                                        field.onChange(newValue);
                                    }}
                                    open={open}
                                    onOpen={() => setOpen(true)}
                                    onClose={() => setOpen(false)}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            multiline
                                            label={
                                                <span>
                                                    Module
                                                    <span className="error" style={{ color: "red" }}>
                                                        {" "}
                                                        *
                                                    </span>
                                                </span>
                                            }
                                            sx={{ width: '100%', marginTop: '0.5rem' }}
                                            error={!!errors.role}
                                            helperText={errors.role?.message}
                                            FormHelperTextProps={{
                                                style: { margin: 0 },
                                            }}
                                        />
                                    )}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '16px', marginTop: '24px' }}>
                    {/* <BackButton handleBack={handleBack} /> */}
                    <CancelButton variant="outlined" title="Cancel" handleBack={handleBack} />
                    <MauvelousPrimaryButton title={buttonLoader ? "loader" : "Submit"} handleClick={handleSubmit(onSubmit)} />
                </Grid>
            </Grid>
        </Grid>
    )
};