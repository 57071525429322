import React from "react";
import { cssProperties } from "../utils/commonCssProperties";
import { Box } from "@mui/material";

export default function Layout({ children }) {
    return (
        <Box
            sx={{
                width: `calc(100% - ${cssProperties?.drawerWidth}px)`, ml: `${cssProperties?.drawerWidth}px`,
                height: `calc(100% - ${cssProperties?.headerHight}px)`, 
                mt: `${cssProperties?.headerHight}px`,
                mb: '24px',
                paddingLeft: '24px',
                paddingRight: '24px'
            }}>
            {children}
        </Box>
    )
}