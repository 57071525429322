import commonActions from "./actions";

const initialState = {
  alert: {
    show: false,
    message: null,
    status: null,
  },
  buttonLoader: false,
  showModal: {
    show: false,
    type: null,
    method: null,
    data: null,
  },
  showUploadModal: {
    show: false,
    type: null,
    method: null,
    data: null,
  },
  pageLoader: false,
  paginationValue: {
    currentPage: 1,
    itemOffset: 0,
    path: null
  },
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonActions.SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case commonActions.SET_BUTTON_LOADER:
      return {
        ...state,
        buttonLoader: action.payload,
      };
    case commonActions.SET_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case commonActions.SET_SHOW_BULK_UPLOAD_MODAL:
      return {
        ...state,
        showUploadModal: action.payload,
      };
    case commonActions.SET_PAGE_LOADER:
      return {
        ...state,
        pageLoader: action.payload,
      };
    case commonActions.SET_PAGINATION_VALUE:
      return {
        ...state,
        paginationValue: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
