import React from "react";
import GenderMaster from "./genderMaster";
import MothertongueMaster from "./mothertongueMaster";
import ReligionMaster from "./religionMaster";
import RegionMaster from "./regionMaster";
import FamilyincomeMaster from "./familyincomeMaster";
import NationalityMaster from "./nationalityMaster";
import LanguageMaster from "./languageMaster";
import MinorityMaster from "./minorityMaster";
import StateMaster from "./stateMaster";
import DistrictMaster from "./districtMaster";
import TalukaMaster from "./talukaMaster";
import VillageMaster from "./villageMaster";
import CategoryMaster from "./categoryMaster";
import CasteMaster from "./casteMaster";
import PwdtypeMaster from "./pwdtypeMaster";
import EquivalentboardMaster from "./equivalentboardMaster";
import BoardMaster from "./boardMaster";
import GrievancetypeMaster from "./grievancetypeMaster";
import GrievanceCategoryMaster from "./grievanceCategegoryMaster";
import DocumentTypeMaster from "./documenttypeMaster";
import CourseCategoryMaster from "./courseCategoryMaster";
import CourseTabMaster from "./courseTabMaster";
import CoursefieldMaster from "./coursefieldMaster";
import BuildingTypeMaster from "./buildingTypeMaster";
import VenueTypeMaster from "./venueTypeMaster";
import Admitcard from "./admitCard";
import ScoreCard from "./scoreCard";

export default function Index({ page }) {

    return (
        <>
            {page === 'gender' ?
                <GenderMaster /> :
                page === 'mothertongue' ?
                    <MothertongueMaster /> :
                    page === 'religion' ?
                        <ReligionMaster /> :
                        page === 'region' ?
                            <RegionMaster /> :
                            page === 'annualfamilyincome' ?
                                <FamilyincomeMaster /> :
                                page === 'nationality' ?
                                    <NationalityMaster /> :
                                    page === 'language' ?
                                        <LanguageMaster /> :
                                        page === 'minority' ?
                                            <MinorityMaster /> :
                                            page === 'state' ?
                                                <StateMaster /> :
                                                page === 'district' ?
                                                    <DistrictMaster /> :
                                                    page === 'taluka' ?
                                                        <TalukaMaster /> :
                                                        page === 'village' ?
                                                            <VillageMaster /> :
                                                            page === 'category' ?
                                                                <CategoryMaster /> :
                                                                page === 'caste' ?
                                                                    <CasteMaster /> :
                                                                    page === 'pwdtype' ?
                                                                        <PwdtypeMaster /> :
                                                                        page === 'equivalentboard' ?
                                                                            <EquivalentboardMaster /> :
                                                                            page === 'board' ?
                                                                                <BoardMaster /> :
                                                                                page === 'grievancetype' ?
                                                                                    <GrievancetypeMaster /> :
                                                                                    page === 'grievancecategory' ?
                                                                                        <GrievanceCategoryMaster /> :
                                                                                        page === 'documenttype' ?
                                                                                            <DocumentTypeMaster /> :
                                                                                            page === 'coursecategory' ?
                                                                                                <CourseCategoryMaster /> :
                                                                                                page === 'coursetab' ?
                                                                                                    <CourseTabMaster /> :
                                                                                                    page === "coursefields" ?
                                                                                                        <CoursefieldMaster /> :
                                                                                                        page === "buildingtype" ?
                                                                                                            <BuildingTypeMaster /> : page === "venuetype" ?
                                                                                                                <VenueTypeMaster /> :
                                                                                                                page === "scorecard" ?
                                                                                                                    <ScoreCard /> :
                                                                                                                    page === "admitcard" ?
                                                                                                                        <Admitcard /> :
                                                                                                                        <></>
            }
        </>
    )
}