import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Container, CssBaseline, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import { Editor, EditorState } from "draft-js";
import QuillAdmitCard from "../../utils/QuillAdmitcard";

export default function Admitcard() {

// const [content,setContent] = useState('')

//     const editorRef = useRef(null);

// const preRequisiteContent = `<div style={{width:"100%",textAlign:"center"}}>hello</div>`




//     const handleSubmit = () => {
//         if (editorRef.current) {
//             const editor = editorRef.current.editor;
//             const htmlData = editor.getData();
//             console.log(htmlData);
//             // setEditorContent(htmlData);
//             setContent(htmlData)
//         }
//     };
    



return (
        <>
    <Container>
      <Typography variant="h4" textAlign={"center"} component="h1" gutterBottom>
        Admit card editor
      </Typography>
      <QuillAdmitCard />
    </Container>
        </>
    )
}