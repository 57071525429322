import authReducer from "./auth/reducer";
import masterFormsReducer from "./masterForms/reducer";
import commonReducer from "./common/reducer";
import usersReducer from "./users/reducer";
import masterReducer from "./master/reducer";
import ticketsReducer from "./tickets/reducer";
import candidateReducer from "./candidate/reducer";
import courseReducer from "./course/reducer";
import venueReducer from "./venue/reducers";
import rollNumberReducer from "./rollNumber/reducers";
import forcePushAllocationReducer from "./forcePushAllocation/reducer";
import courseExamAndBatchReducer from "./courseExamAndBatch/reducer";
import customerReducer from "./customer/reducer";

const reducers = {
    authReducer,
    masterFormsReducer,
    commonReducer,
    usersReducer,
    masterReducer,
    ticketsReducer,
    candidateReducer,
    courseReducer,
    venueReducer,
    rollNumberReducer,
    forcePushAllocationReducer,
    courseExamAndBatchReducer,
    customerReducer,
    
};

export default reducers;